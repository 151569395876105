<template>
  <div class="news">
    <div class="news-title" v-if="newsList.columnVO && newsList.columnVO.categoryTitle">
      <div class="title">{{newsList.columnVO.categoryTitle}}</div>
      <div class="more">
        <a :href="newsList.moreLink">
          List All
        </a>
      </div>
    </div>
    <ul class="contain">
      <li class="contain-item" v-for="(item,index) in newsList.list" :key="index">
        <div class="contain-title">
          <a :href="item.link">{{item.name}}</a>
        </div>        
        <div class="time">
          {{item.en_time}}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
  export default {
    computed:{
      ...mapState("en_news",["newsList"])
    },
    serverPrefetch(){
      return this.getNewList()
    },
    mounted(){
      console.log('@@@@',this.newsList);
      if(process.env.NODE_ENV === "development"){
        this.getNewList()
      }
    },
    methods:{
      ...mapActions("en_news",["getNewList"])
    }
  }
</script>

<style lang="less" scoped>
.news {
  width: 264px;
  height: 410px;
  padding: 50px 30px;
  background: url("../../../../assets/index2_08.png");
  top: -510px;
  right: 9px;
  position: absolute;
  z-index: 1;
  color: #FFf;

  .news-title {
    display: flex;
    border-bottom: 1px solid #3232d1;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 24px;
      display: inline-block;
    }
    .more{
      a {
        font-size: 12px;
        color: #FFf;
        text-decoration: revert !important;
        &:hover {
          color: #FFF !important;
        }
      }
    }
  }
  .contain-item {
    margin-top: 20px;
    .contain-title {
      a {
        color: #FFF;
        font-size: 12px;
        &:hover {
          color: aqua !important;
        }
      }
    }
    .time {
      font-size: 12px;
      color: #C8C5C5CC
    }
  }
}
</style>