<template>
  <div class="international_box">
    <input type="hidden" name="pageRouter" value="detail/internationalcooperation" />
    <p>
      国际合作和学术交流为中信所的建立和发展提供了外在的资源，为中国科技事业带来了更多的国外信息资源，为中国信息服务业水平的提升及与世界水平差距的缩小提供了渠道，为人才队伍培养提供了平台，为中国情报事业融入世界科技信息行业提供了保障。
    </p>
    <img src="../../../../assets/cooper.png" alt="">
    <div class="conten yg" @click="btn('yg')"></div>
    <div class="conten hl" @click="btn('hl')"></div>
    <div class="conten bels" @click="btn('bels')"></div>
    <div class="conten xl" @click="btn('xl')"></div>
    <div class="conten hg" @click="btn('hg')"></div>
    <div class="conten rb" @click="btn('rb')"></div>
    <div class="conten jnd" @click="btn('jnd')"></div>
    <div class="conten mg" @click="btn('mg')"></div>
    <div v-if="shouhidd" class="shouhidd">
      <div class="shouhidd_box">
        <div class="shouhidd_heard">
          <div>
            <img :src="value.imgURl" alt="" />
          </div>
          <div>
            {{value.title}}
          </div>
        </div>
        <div class="shouhidd_text" v-html="value.text"></div>
      </div>
      <div class="close" @click="close"></div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      shouhidd: false,
      value: {}
    }
  },
  methods: {
    btn(val) {
      this.shouhidd = true
      switch(val){
        case 'yg' :
          this.value = {title: '英国',imgURl: require('../../../../assets/england.png'),text: '2018年  与Taylor & Francis Group签订“学术前沿观察联合实验室合作协议”，合作建立“ISTIC-Taylor & Francis Group 学术前沿观察联合实验室”'}
        break
        case 'hl' :
          this.value = {title:'荷兰',imgURl: require('../../../../assets/hl.png'),text:`2012年  与爱思唯尔合作建立“ISTIC-ELSEVIER期刊评价研究中心”联合实验室 <br/> 2015年  与爱思唯尔续签“ISTIC-ELSEVIER期刊评价研究中心”协议`}
        break
        case 'bels' :
          this.value = {title:'白俄罗斯',imgURl: require('../../../../assets/bels.png'),text:`2017年  与白俄罗斯科学院信息学联合研究所建立合作关系`}
        break
        case 'xl' :
          this.value = {title:'希腊',imgURl: require('../../../../assets/xl.png'),text:`2012年  与希腊国家文献中心建立合作关系`}
        break
        case 'hg' :
          this.value = {title:'韩国',imgURl: require('../../../../assets/hg.png'),text:`2007年  与韩国科技信息研究院（KISTI）建立长期合作伙伴关系`}
        break
        case 'rb' :
          this.value = {title:'日本',imgURl: require('../../../../assets/rb.png'),text:`<div>2012年  政策与战略研究中心与日本国科学技术振兴机构研究开发战略中心、日本国科学技术振兴机构中国综合研究交流中心签订研究合作备忘录 </div><div>2013年  与科学技术振兴机构（JST）签订“汉日.日汉机器翻译合作研究协议”</div> <div>2014年  与科学技术振兴机构（JST）签订“Draft Arrangement for Implementing Chinese-to-Japanese and Japanese-to-Chinese Machine Translation Project”</div> <div>2015年  与科学技术振兴机构（JST）签订“2015-2017年'中日与日中机器翻译项目'实施备忘录”</div><div>2016年  与科学技术振兴机构（JST）签订“合作备忘录”</div><div>2018年  政策与战略研究中心与日本国科学技术振兴机构研究开发战略中心、日本国科学技术振兴机构中国综合研究交流中心签订研究合作的备忘录ⅱ</div>`}
        break
        case 'jnd' :
          this.value = {title:'加拿大',imgURl: require('../../../../assets/jnd.png'),text:`2017年  万方公司与加拿大针灸中医学院签订“战略合作框架协议”`}
        break
        case 'mg' :
          this.value = {title:'美国',imgURl: require('../../../../assets/01.png'),text:`<div>2008年  与汤森路透集团公司合建“ISTIC-THOMSON REUTERS科学计量学联合实验室”</div><div>2012年  与汤森路透（科技）公司签订“合作协议”</div><div>2016年  与汤森路透（科技）公司签订“合作续订协议”</div><div>2016年  与美国艾博思科国际有限公司签订“关于建立文献大数据发现服务联合实验室合作协议”；合作建立“文献大数据发现服务”联合实验室</div><div>2017年  与美国约翰威立国际出版公司签订“元数据许可协议”</div><div>2019年  与美国约翰威立国际出版公司签订“合作谅解备忘录”</div><div>2019年  与科睿唯安（Clarivate Analytics）签订“合作续订协议”</div>`}
        break
      }
    },
    close() {
      this.shouhidd = false
    }
  }
}
</script>

<style lang="less" scoped>
.international_box {
  position: relative;
  padding: 0 20px;
  p {
    text-indent: 30px;
    font-size: 14px;
    line-height: 24px;
    color: #666;
    padding: 5px 0;
  }
}
.conten {
  width: 10px;
  height: 10px;
  position: absolute;
  
}
.hl {
  top: 183px;
  left: 106px;
}
.bels {
  top:176px;
  left: 162px;
}
.xl {
  left: 143px;
  top: 209px;
}
.hg {
  right: 584px;
  top: 217px;
}
.rb {
  right: 557px;
  top: 217px;
}
.jnd {
  right: 341px;
  top: 200px;
}
.mg {
  right: 311px;
  top: 216px;
}
.yg {
  top: 168px;
  left: 89px;
}
.shouhidd {
  width: 598px;
  min-height: 162px;
  border: solid 10px rgba(0,0,0,.3);
  border-radius: 5px;
  position: absolute;
  top: 140px;
  left: 110px;
  font-size: 12px;
  // line-height: 24px;
  color: #464646;
  // background: #fff;
  // padding: 24px;
  .shouhidd_box {
    width: 100%;
    min-height: 162px;
    background: #fff;
  }
  .shouhidd_heard {
    padding: 12px;
    display: flex;
    // justify-content: center;
    align-items: center;
    div:nth-child(2) {
      font-size: 18px;
      color: #202020;
      margin-left: 8px;
      margin-top: -4px;
    }
  }
}
.close {
  width: 28px;
  height: 28px;
  background: url(../../../../assets/ico-del.png) no-repeat center;
  display: block;
  position: absolute;
  right: -18px;
  top: -18px;
  cursor: pointer;
}
.shouhidd_text {
  padding:0 12px;
  font-size: 14px;
  line-height: 24px;
  /deep/div {
    margin-bottom: 10px;
  }
  /deep/div:last-child {
    margin-bottom: 20px;
  }
}
</style>