<template>
  <div class="w100">
    <card-2 :cardData="cardData" :moreLink="learnData.moreLink"/>
    <list-public :dataList="learnData.list" :backImg="backImg"/>
  </div>
</template>

<script>
import card2 from '../../../../components/card2/index.vue'
import listPublic from '../../../../components/listpublic/index.vue'
import { mapActions , mapState } from 'vuex';
export default {
  data() {
    return {
      backImg:{backImg: 'listData_bakimg1'},
      cardData:{
        title:'学习教育',
        value:'learningEducation'
      }
    };
  },
  components: {
    card2,
    listPublic
  },
  computed: {
    ...mapState('dq_learn',['learnData'])
  },
  serverPrefetch() {
    return this.getlearnData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.getlearnData()
    }
    // this.getlearnData()
  },

  methods: {
    ...mapActions('dq_learn',['getlearnData'])
  },
};
</script>

<style lang="less" scoped>
</style>