<template>
  <div class="w100 flexCenter links-Box">
    <div class="dqMainBox">
      <a v-for="item in list" :key="item.id" :href="item.link">
        <img :src="item.img" alt="">
      </a>
    </div>
  </div>
</template>

<script>
// import fl1 from "../../../assets/fl1s.jpg"
import fl1 from "../../../assets/fl1s.png"
import fl2 from "../../../assets/fl2.jpg"
import fl3 from "../../../assets/fl3s.jpg"
import fl4 from "../../../assets/fl4.jpg"

export default {
  data() {
    return {
      list: [
        {
          id: "1",
          img: fl1,
          link: "http://cpc.people.com.cn/"
        },
        {
          id: "2",
          img: fl2,
          link: "http://www.12371.cn/"
        },
        {
          id: "3",
          img: fl3,
          link: "https://www.most.gov.cn/zxgz/jgdj/"
        },
        {
          id: "4",
          img: fl4,
          link: "http://www.gongwei.org.cn/"
        }
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.links-Box{
  height: 78px;
  padding: 9px 0;
  background: #f7f7f7;
  .dqMainBox{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      display: block;
      width: 266px;
      height: 68px;
    }
  }
}
</style>