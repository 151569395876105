<template>
  <div class="footer-box w100 flexCenter">
    <div class="enMainBox">
      <b></b>
      <p>Address: No.15 Fuxing Road, Beijing, 100038 CHINA</p>
      <p>Tel: +86-10-58882033 Fax: +86-10-58882590</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CmsNodeTemplateIndex",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.footer-box {
  height: 68px;
  padding: 15px 0;
  background: #353538;
}
.enMainBox {
  display: flex;
  width: 1200px !important;
  align-items: center;
  justify-content: space-between;
  b {
    width: 56px;
    height: 68px;
    background: url("../../../assets/ico-footer.png") no-repeat;
    background-size: cover;
  }
  p {
    color: #fff;
    font-size: 16px;
  }
}
</style>