<template>
  <div class="w100 flexCenter">
    <div class="dqMainBox">
      <div class="menu-box" :style="{ top: pop_top + 'px' }">
        <MenuList />
      </div>

      <div class="dq-detail-box">
        <div class="w100 detail-top">
          <Crumbs />
        </div>

        <div class="w100">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MenuList from "./components/menuList/index.vue";
import Crumbs from "../../components/crumbs/index.vue"

export default {
  data() {
    return {
      pop_top: 0,
    };
  },
  components: {
    MenuList,
    Crumbs
  },
  // Vue SSR 特殊为服务端渲染提供的一个生命周期钩子函数,返回promise
  serverPrefetch() {
    return this.initData();
  },
  async mounted() {
    if (process.env.NODE_ENV == "development") {
      await this.initData();
    }

    this.$nextTick(() => {
      this.init();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.calculate);
    window.removeEventListener("resize", this.calculate);
  },
  methods: {
    ...mapActions("dq_detail", ["initData"]),
    init() {
      window.addEventListener("scroll", this.calculate);
      window.addEventListener("resize", this.calculate);

      setTimeout(() => {
        this.calculate();
      }, 500);
    },
    calculate() {
      this.$nextTick(() => {
        let headerDom = document.getElementById("dq_layout_header");
        let headerH = headerDom.offsetHeight;

        let homeST = window.pageYOffset;

        let h = homeST - headerH;
        h = h > 0 ? h : 0;

        this.pop_top = h;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dqMainBox {
  margin: 40px 0;
  position: relative;

  .menu-box {
    position: absolute;
    width: 186px;
    min-height: 108px;
    left: 0;
  }

  .dq-detail-box {
    width: calc(100% - 226px);
    min-height: 200px;
    margin-left: 226px;
  }
}

.detail-top {
  height: 34px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>