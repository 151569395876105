<template>
  <div class="content1">
    <div class="title">Joint-Laboratory</div>
    <div class="content">
      <ul>
        <li v-for="(item,index) in list" :key="index" class="list-item">
          <a :href="link">
            {{item}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      link:{
        type: String,
        default: ""
      }
    },
    data() {
      return {
        list:[
          "ISTIC-CA",
          "ISTIC-SN",
          "ISTIC-Taylor&Francis Group",
          "ISTIC-ELSEVIER",
          "ISTIC-MI",
          "ISTIC-EBSCO"
        ]
      }
    },  
  }
</script>

<style lang="less" scoped>
.content1 {
  width: 264px;
  background: url('../../../../../assets/4.jpg') no-repeat;
  padding: 70px 30px 50px 30px;
  height: 254px;
  vertical-align: top;
  .title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 66px;
  }
  .content {
    .list-item {
      list-style: none;
      line-height: 30px;
      a {
        color: white;
        font-size: 16px;
        &:hover {
          color:aqua !important;
        }
      }
    }
  }
}
</style>