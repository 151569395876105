<template>
  <div class="w100 menu-box flexCenter">
    <div class="dqMainBox">
        <div v-for="(item,index) in data.columnList || []" :key="index" @click="btn(index)" :class="isSel(item, index) ? 'active' : '' ">
          <a :href="item.linkUrl">{{item.title}}</a>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'CmsNodeTemplateIndex',

  data() {
    return {
      curindex: null
    };
  },
  computed: {
    ...mapState('dq_menu' , ['data']),
    ...mapGetters("dq_detail", ["subColumnVOList"]),
    isDqHome(){
      if (typeof window === 'object') {
        let url = window.location.href 
        
        if(url.indexOf("/402/403/419") !== -1 || this.$route.path === "/dangqun"){
          return true
        }else  {
          return false
        }
      }
    }

  },
  serverPrefetch() {
    return this.initData()
  },
  mounted() {
    if(process.env.NODE_ENV == "development"){
      this.initData()
    }
  },
  methods: {
    ...mapActions('dq_menu',['initData']),
    btn(index) {
      this.curindex = index
    },
    isSel(item, index){
      let sel = false;

      if(item.title === (this.subColumnVOList[0]&&this.subColumnVOList[0].categoryTitle)){
        sel = true;
      }else if(this.isDqHome && index === 0){
        sel = true
      }

      return sel;
    }
  },
};
</script>

<style lang="less" scoped>
.menu-box{
  height: 50px;
  background: rgb(178, 11, 12);
  .dqMainBox{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    letter-spacing: 2px;
    div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: #fff;
        &:hover{
          color: #fff !important;
        }
      }
    }
    div:hover {
      background-color: #820200;
    }
    .active {
      background-color: #820200;
    }
  }
}
</style>