<template>
<div class="w100">
    <input type="hidden" name="pageRouter" value="detail/sandtreport"/>
    <scientiFicresearch class="mb30" :papersection="papersection" :articleVOList="articleVOList" :baseUrl="baseUrl"/>
    <div class="w100 flexCenter mb50">
      <Page v-if="pageInfo" :pageInfo="pageInfo"/>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import scientiFicresearch from '../../components/scientificresearch/index.vue'
import Page from "../../../../components/Page"

export default {
    components: {
        scientiFicresearch,
        Page
    },
    data() {
        return {
            papersection: {
                publicatio: '部门',
                pub_year: '完成/出版时间',
                sandt:true
            }
        }
    },
    computed: {
        ...mapGetters('detail',['articleVOList','pageInfo','baseUrl'])
    }
}
</script>

<style>

</style>