<template>
  <div class="top" v-if="title">
    <div class="title">{{title}}</div>
    <div class="line"></div>
  </div>
</template>

<script>
  export default {
    props:{
      title:{
        type: String,
        default:''
      }
    }
  }
</script>

<style lang="less" scoped>

.title {
  font-size: 24px;
  color: #0d7cd1;
  text-align: center;
  margin: 10px 0;
}
.line {
  width: 1200px;
  height: 22px;
  margin-bottom: 10px;
  background: url("../../../../assets/line.png");
}
</style>