<template>
  <div class="fullBox carouselBox">
    <div ref="swiperDom">
      <div class="swiper-wrapper fullBox">
        <div class="swiper-slide" v-for="item in list.list" :key="item.id">
          <img class="" :src="item.image" alt="" />
          <div class="des-pop">
          </div>
          <a class="des-pop-a" :href="item.link">{{ item.name }}</a>
        </div>
      </div>

      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next-home",
        //   prevEl: ".swiper-button-prev-home",
        // },
      },
    };
  },
  computed:{
    ...mapState("dq_carousel", ["list"])
  },

  serverPrefetch() {
    return this.initList();
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      await this.initList();
    }
    this.init();
  },

  methods: {
    ...mapActions("dq_carousel", ["initList"]),
    init() {
      let swiper = window.Swiper;
      new swiper(this.$refs.swiperDom, this.swiperOption);
    },
  },
};
</script>

<style lang="less" scoped>
.carouselBox{
  overflow: hidden;
  position: relative;
}
.swiper-slide {
  position: relative;
  width: 590px;
  height: 391px;
  img{
    // max-width: 100%;
    // display: block;
    height: 100%;
  }
  .des-pop {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px !important;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.5;
  }
  .des-pop-a{
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    width: 368px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    opacity: 1 !important;
    margin-left: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .des-pop-a:hover{
    color: #fff !important;
  }
}
.swiper-pagination{
  left: auto !important;
  display: inline-block;
  width: auto !important;
  
  right: 30px !important;
  bottom: 15px !important;
  /deep/.swiper-pagination-bullet{
    background: #fff;
    opacity: 1;
  }
  /deep/.swiper-pagination-bullet-active{
    background: red;
  }
}
</style>