<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="detail/papersection" />
    <scientiFicresearch
      :papersection="papersection"
      :articleVOList="articleVOList"
      class="mb30"
    />

    <div class="w100 flexCenter mb50">
      <Page v-if="pageInfo" :pageInfo="pageInfo"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import scientiFicresearch from "../../components/scientificresearch/index.vue";
import Page from "../../../../components/Page"

export default {
  data() {
    return {
      papersection: {
        publicatio: "刊物",
        pub_year: "出版年",
      },
    };
  },
  components: {
    scientiFicresearch,
    Page
  },
  computed: {
    ...mapGetters("detail", ["articleVOList", "pageInfo"]),
  },
};
</script>

<style lang="less" scoped>
.paperBox {
  padding: 0 20px;
  .paperContent {
    height: 20px;
    border-bottom: dotted 1px #8a8a8a;
    padding: 12px 0;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .paperContentLeft {
      // width: 710px;
      border-left: 2px solid rgba(50, 124, 192, 0.9);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        margin-left: 5px;
        color: #327cc0 !important;
      }
      a:hover {
        text-decoration: underline;
        color: #327cc0;
      }
    }
    .paperContentRight {
      width: 225px;
      color: #666;
      display: flex;
      justify-content: space-between;
      span {
        width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
  }
}
</style>