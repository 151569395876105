<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/information" />
    <div class="content" v-html="detailData.articleContent"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("detail",['detailData'])
    },
    mounted(){
      if(process.env.NODE_ENV === "development") {
        setTimeout(() => {
          this.foldContent()
        }, 1000)
      }else{
        this.foldContent()
      }
    },
    methods:{
      foldContent() {
        const cont = document.querySelectorAll('.cont')
        for (let i=0 ; i < cont.length ; i++) {
          cont[i].onclick = () => {
            if(!cont[i].classList.contains('on')){
              cont[i].classList.add('on')
            }else {
              cont[i].classList.remove('on')
            }
            // console.log(cont[i].classList.contains('on'));
            // cont[i].classList.add('on')
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
/deep/.content {
  padding: 0 20px;
  .tc {
    text-align: center;
  }
  .rule-box dt{
    &::after {
      content: "";
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url("~@/assets/information-icoarrow.png") no-repeat center !important;
      margin-left: 5px;
      vertical-align: sub;
      margin-bottom: 2px;
    }
  }
}
</style>