<template>
  <div class="research_box">
      <div v-for="(item,index) in articleVOList" :key="index" class="research_content">
          <div class="research_img">
            <a :href="item.articleLinkURL" target="_blank">
              <img :src="item.basicThumbnails || '/resource/404.jpeg'" alt=""/>
            </a>
          </div>
          <div class="research_text">
            <div>
              <span>
                <a :href="item.articleLinkURL" target="_blank">{{item.basicTitle}}</a>
              </span>
            </div>
            <div class="research_textbot">
              <span>{{item.modelData ? item.modelData.activ_prov.fieldData:''}}</span>
              <span>{{item.modelData ? item.modelData.issue_time.fieldData: '' }}</span>
            </div>
            <div class="research_textbot">{{item.basicDescription || ''}}</div>
          </div>
      </div>
    <div>
        
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articleVOList: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.research_box {
  padding:0 50px;
}
.research_content {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  .research_img {
    width: 128px;
    height: 171px;
    border: 1px solid #E7E7E7;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .research_text {
    width: 697px;
    div:nth-child(1) {
      font-size: 20px;
      font-weight: 600;
      a {
        color: #327CC0;
      }
    }
    div:nth-child(2) {
      padding: 30px 0;
      span:nth-child(2) {
        margin-left: 25px;
      }
    }
    div:nth-child(3) {
      height: 56px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .research_textbot {
      font-size: 14px;
      color: #666;
    }
  }
}

</style>