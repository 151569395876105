<template>
  <div class="fullBox banner">
    <div ref="swiperDom">
      <div class="swiper-wrapper fullBox">
        <div class="swiper-slide"  v-for="(item,index) in list.articleVOS" :key="index">
          <div class="obj">
            <div class="l" v-if="background.articleVOS && background.articleVOS[1]">
              <img :src="background.articleVOS[1].basicThumbnails" alt="">
            </div>
            <div class="c">
              <a :href="item.articleLinkURL">
                <img :src="item.basicThumbnails" alt="">
              </a>
            </div>
            <div class="r" v-if="background.articleVOS && background.articleVOS[0]">
              <img :src="background.articleVOS[0].basicThumbnails" alt="">
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="button">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        speed:2000,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed:{
    ...mapState("en_banner",["background","list"])
  },

  serverPrefetch() {
    return Promise.all([this.initList(),this.backgroundPicture()])
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      await this.backgroundPicture()
      await this.initList()
    }
    this.init();
  },

  methods: {
    ...mapActions("en_banner",["backgroundPicture","initList"]),
    init() {
      let swiper = window.Swiper;
      new swiper(this.$refs.swiperDom, this.swiperOption);
    },
  },
};
</script>

<style lang="less" scoped>
.banner{
  width: 100%;
  overflow: hidden;
  height: 510px;
  &:hover {
    .button{
      display: flex;
    }
  }
}
.swiper-wrapper {
  width: 100%;
  height: 510px;
}
.button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  position: absolute;
  left: 0;
  top: 350px;
  display: none;
  .swiper-button-prev{
    width: 50px;
    height: 50px;
    position: static;
    margin-right: 800px;
    background-image: url("../../../../assets/l.png");
    background-size: 50px 50px;
  }
  .swiper-button-next {
    width: 50px;
    height: 50px;
    position: static;
    margin-right: 350px;
    background-image: url("../../../../assets/r.png");
    background-size: 50px 50px;
  }
}
.swiper-slide {
  overflow: hidden;
  max-width: none !important;
  .obj {
    width: 1296px !important;
    height: 510px;
    margin: 0 auto;
    position: relative;
    .l {
      position: absolute;
      left: -202px;
    }
    .c {
      a {
        display: inline-block;
        width: 100%;
        height: 510px;
        img {
          width: 1126px;
          max-width: none !important;
          height: 510px;
        }
      }
    }
    .r {
      position: absolute;
      top: 0;
      left: 1286px;
    }
  }
}
</style>