<template>
  <div class="fullBox bgcard">
    <card :cardData="cardData" :moreLink="scienceWorkData3.moreLink"/>
    <listpublic :dataList="scienceWorkData3.list" :backImg="backImg"/>
  </div>
</template>

<script>
import { mapActions, mapState,mapMutations } from 'vuex';
import Card from "../../../../components/card2/index.vue"
import listpublic from '../../../../components/listpublic'
export default {
  data() {
    return {
      cardData:{
        title:'科技部工作',
        value:'dangweiWork'
      },
      backImg:{ backImg:'listData_bakimg1'},
    };
  },
  components:{
    Card,
    listpublic
  },
  serverPrefetch(){
    this.initId()
    return this.getScienceWorkData3()
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.initId()
      this.getScienceWorkData3()
    }
  },
  computed:{
    ...mapState("da_spiritWork",["centralSpiritData","scienceWorkData3"])
  },
  methods: {
    ...mapActions("da_spiritWork",["getScienceWorkData3"]),
    ...mapMutations("da_spiritWork",['setId','setPage']),
    initId(){
      this.setId('1158898879362982385')
      this.setPage('6')
    }
  },
};
</script>

<style lang="less" scoped>
</style>