<template>
  <div class="w100">
    <h3 class="dq-home-title">
      <span>精彩瞬间</span>
      <span class="dq_home-more">
        <a :href="moreUrl" target="_blank">
          <span>更多</span>
          <img src="../../../../assets/arrow-dj-more-red.svg" alt=""/>
        </a>
      </span>
    </h3>
    <ul class="dq-home-wonderful-list">
      <li class="dq-home-wonderful-list-item" v-for="item in wonderfulData.list" :key="item.id">
        <a :href="item.link">
          <img :src="item.image" :alt="item.name" class="image">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed:{
    ...mapState("dq_wonderful",["wonderfulData",'moreUrl'])
  },
  serverPrefetch() {
    return this.getWonderfulData()
  },
  serverPrefetch() {
    return this.getWonderfulData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.getWonderfulData()
    }
    setTimeout(()=>{
       console.log(this.wonderfulData);
    },1000)
  },

  methods: {
    ...mapActions("dq_wonderful",["getWonderfulData"])
  },
};
</script>

<style lang="less" scoped>
.dq_home-more {
  position: absolute;
  right: 5px;
  a {
    color: rgb(178, 11, 12);
    font-size: 14px; 
    font-weight: 400 !important;
    &:hover {
      color: rgb(178, 11, 12) !important;
    }
    span {
      margin-right: 5px;
    }
    img {
      position: absolute;
      top: 13px;
    }
  }
}
.dq-home-wonderful-list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .dq-home-wonderful-list-item {
   .image {
     width: 224px;
     height: 126px;
   }
  }
}
</style>