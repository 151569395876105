<template>
  <div class="w100">
      <input type="hidden" name="pageRouter" value="detail/contact" />
      <div class="contact_box">
        <img :src="detailData.basicThumbnails" alt="">
      </div>
      <div class="content" v-html="detailData.articleContent"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("detail",["detailData"])
    }
  }
</script>

<style lang="less" scoped>
.contact_box {
  padding: 0 20px;
}
.content{
  margin-left: 20px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 22px;
  margin-top: 30px;
  position: relative;
  color: #181818;
  &::before {
  content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    border-left: solid 12px #7AC6FD;
    top: 8px;
    left: 0;
  }
}
</style>