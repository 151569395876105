<template>
  <div class="big-events-box w100 flexXCenter">
    <input type="hidden" name="pageRouter" value="detail/bigEvents" />
    <div class="big-events-bg">
      <div
        class="events-item"
        v-for="item in list"
        :key="item.id"
        :style="item.style"
      >
        <div class="events-item-box">
          <img
            :src="item.img"
            alt=""
            :class="item.id === custId ? 'big' : ''"
            @mouseleave="showPop=null" @mousemove="showPop=item.id"
            @click="onClick(item)"
          />
          <div :class="`events-item-pop flexYCenter ${bigIcon.indexOf(item.id)!==-1?'big-pop':''}`" v-show="item.id===showPop && item.time">
            <h3 :style="{width:item.id == 9?'222px':''}">{{ item.time }}</h3>
            <span>{{ item.des }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RedButton from "../../../../assets/RedButton.png";
import GreenButton from "../../../../assets/GreenButton.png";
import BlueButton from "../../../../assets/BlueButton.png";
import YellowButton from "../../../../assets/YellowButton.png";
import greed from "../../../../assets/greed.png";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      custId: null,
      showPop: null,
      bigIcon: ["1", "5", "8"],
      list: [
        {
          id: "1",
          style: {
            left: "680px",
            top: "-18px",
          },
          img: RedButton,
          time: "1956年10月",
          des: "中国科学院科学情报研究所在北京朝阳内大街137号成立。",
        },
        {
          id: "2",
          style: {
            left: "720px",
            top: "76px",
          },
          img: GreenButton,
          time: "1975年10月",
          des: "首次代表国家加入国际组织（UNISIST）。",
        },
        {
          id: "3",
          style: {
            left: "608px",
            top: "87px",
          },
          img: GreenButton,
          time: "1978年12月",
          des: "开始情报学硕士研究生培养工作。",
        },
        {
          id: "4",
          style: {
            left: "506px",
            top: "96px",
          },
          img: GreenButton,
          time: "1983年10月",
          des: "我国大陆第一个国际联机情报检索服务站开通。",
        },
        {
          id: "5",
          style: {
            left: "373px",
            top: "86px",
          },
          img: YellowButton,
          time: "1992年9月",
          des: "更名为中国科学技术信息研究所。",
        },
        {
          id: "6",
          style: {
            left: "488px",
            top: "172px",
          },
          img: GreenButton,
          time: "1998年6月",
          des: "开始与北京大学联合培养情报学博士研究生。",
        },
        {
          id: "7",
          style: {
            left: "607px",
            top: "175px",
          },
          img: GreenButton,
          time: "2000年7月",
          des: "国家科技图书文献中心工程技术图书馆成立。",
        },
        {
          id: "8",
          style: {
            left: "730px",
            top: "177px",
          },
          img: greed,
          time: "2002年10月",
          des: "科技部正式批复中国科学技术信息研究所改革为公益性科研机构。",
        },
        {
          id: "9",
          style: {
            left: "683px",
            top: "265px",
          },
          img: GreenButton,
          time: "2002年10月",
          des: "中国科学技术信息研究所被批准设立“图书馆、情报和档案管理”博士后科研工作站。",
        },
        {
          id: "10",
          style: {
            left: "590px",
            top: "282px",
          },
          img: GreenButton,
          time: "2004年6月",
          des: "国家工程技术图书馆院士著作馆开馆。",
        },
        {
          id: "11",
          style: {
            left: "475px",
            top: "294px",
          },
          img: GreenButton,
          time: "2011年1月",
          des: "情报行业第一家国家工程技术研究中心获批。",
        },
        {
          id: "12",
          style: {
            left: "354px",
            top: "306px",
          },
          img: GreenButton,
          time: "2014年",
          des: "“国家科技报告服务系统”正式上线。",
        },
        {
          id: "13",
          style: {
            left: "218px",
            top: "228px",
          },
          type: "more",
          img: BlueButton
        },
      ],
      st: null,
    };
  },
  computed:{
    ...mapGetters("detail", ["detailData"])
  },
  mounted() {
    this.run();
  },

  methods: {
    run() {
      clearInterval(this.st);
      let num = 0;
      this.st = setInterval(() => {
        let item = this.list[num];

        if (!!item) {
          this.custId = item.id;
        } else {
          num = 0;
          this.custId = "1";
        }

        num++;
      }, 1000);
    },
    onClick(item){
      if(item.type !== "more") return

      window.location.href = this.detailData.articleLinkURL
    }
  },
  beforeDestroy() {
    clearInterval(this.st);
  },
};
</script>

<style lang="less" scoped>
.big-events-box {
  padding-top: 30px;
  .big-events-bg {
    width: 784px;
    height: 395px;
    background: url("../../../../assets/about-event.png") no-repeat;
    background-size: cover;
    -webkit-print-color-adjust: exact;
    position: relative;
    .events-item {
      position: absolute;
      cursor: pointer;
      .events-item-box {
        position: relative;
        .events-item-pop {
          position: absolute;
          padding: 15px 30px;
          top: -70px;
          left: -470px;
          background: #c4c4c4;
          border-radius: 5px;
          color: #fff;
          display: flex;
          width: 651px;
          height: 25px;
          z-index: 2;
          h3{
            font-size: 32px;
            margin-right: 30px
          };
          span{
            display: inline-block;
            max-width: 421px;
            font-size: 14px;
          }
        }
        .events-item-pop::before {
          content: "";
          width: 0;
          height: 0;
          border-top: 12px solid #c4c4c4;
          border-left: 12px solid transparent;
          position: absolute;
          top: 55px;
          right: 230px;
        }
        .big-pop::before{
          right: 215px;
        }
      }
    }
  }

  .big {
    transform: scale(1.2);
  }
}
</style>