<template>
  <div class="w100 flexXCenter detailBox mb20">
    <div class="mainBoxDetail">
      <DetailBanner class="mb20" id="detailBanner"/>

      <div class="w100 detailBoxContentBox mb50">
        <div class="detailL" :style="{ top: pop_top + 'px' }">
          <MenuList id="menulist"/>
        </div>

        <div class="w100 detailR" :style="{minHeight: minH+'px'}" id="detailContentBox">
          <div class="notice_box" v-if="data.articleVO">
            <span>通知公告</span>
            <div class="notice_title">
              <a :href="data.articleVO.articleLinkURL">{{data.articleVO ? data.articleVO.basicTitle:''}}</a>
            </div>
          </div>
          <div class="w100 detailRHeader">
            <Crumbs/>
          </div>
          
          <div class="w100 detailRContent">
            <router-view></router-view>
          </div>
        </div>
      </div>

      <Links />
    </div>
  </div>
</template>

<script>
import DetailBanner from "./components/detailBanner/index.vue";
import MenuList from "./components/menuList/index.vue";
import Crumbs from "./components/crumbs/index.vue";
import Links from "../../components/links/index.vue";
import { mapActions, mapState, mapMutations } from 'vuex';
import { title } from "process";

export default {
  data() {
    return {
      pop_top: 0,
      minH: 600
    };
  },
  computed:{
    ...mapState("detail", ["data"])
  },
  components:{
    DetailBanner,
    MenuList,
    Crumbs,
    Links,
  },
  // Vue SSR 特殊为服务端渲染提供的一个生命周期钩子函数,返回promise
  serverPrefetch(){
    this.initSource()
    return this.initData()
  },
  async mounted() {
    if (process.env.NODE_ENV == "development") {
      this.initSource()
      await this.initData();
    }

    this.$nextTick(() => {
      this.init();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.calculate);
    window.removeEventListener("resize", this.calculate);
  },
  methods: {
    ...mapActions("detail", ["initData"]),
    ...mapMutations('detail',['setSoure']),
    initSource(){
      const router = this.$route;
      const roujoin = router.path.split('/')
      // console.log(rou.slice(rou.length-1).join(''))
      // if(router.path === "/detail/postgraduatenews"){
      //   this.setSoure('postgraduatenews')
      // }else if(router.path === "/detail/strategic"){
      //   this.setSoure('strategic')
      // } else{
        this.setSoure(roujoin.slice(roujoin.length-1).join(''))
      // }
    },
    init() {
      window.addEventListener("scroll", this.calculate);
      window.addEventListener("resize", this.calculate);

      setTimeout(() => {
        this.calculate();
      }, 500);
    },
    calculate() {
      this.$nextTick(() => {
        let headerDom = document.getElementById("home_header");
        let banner = document.getElementById("detailBanner");
        let detailContenDom = document.getElementById("detailContentBox");
        let menulistDom = document.getElementById("menulist");
        let detailContenH = detailContenDom.offsetHeight;
        let minH = menulistH + 20;
        if(minH > detailContenH){
          this.minH = minH;
          detailContenH = minH;
        }

        let menulistH = menulistDom.offsetHeight;
        let headerH = headerDom.offsetHeight + banner.offsetHeight;

        let homeST = window.pageYOffset;

        let h = homeST - headerH;
        // console.log("@@@@@@@", h, menulistH, detailContenH, detailContenH - h)

        if(h > 0){
          // if(h > menulistH){
          //   h = menulistH - 20;
          // }else 
          if((detailContenH - menulistH) < h){
            h = detailContenH - menulistH
          }
        }else{
          h = 0
        }
        

        this.pop_top = h;

        this.minH = menulistH + 20;
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../styles/detail.less");
</style>

<style lang="less" scoped>
.detailBoxContentBox{
  display: flex;
  justify-content: space-between;
  min-height: 600px;
  position: relative;
  .detailL{
    width: 200px;
    position: absolute;
    left: 0;
    background: #fff;
    z-index: 1;
  }

  .detailR{
    width: calc(100% - 225px);
    margin-left: 225px;
    .detailRHeader{
      border-bottom: solid 2px rgba(0,0,0,0.1);
    }

    .detailRContent{
      padding-top: 20px;
      min-height: 600px;
    }
  }
}
// /deep/ .links {
//   width: 984px !important;
//   height: 48px !important;
//   margin: 0 auto;
// }

.notice_box {
  background: #F7F7F7;
  height: 37px;
  line-height: 37px;
  border-radius: 5px;
  padding: 0 10px;
  overflow: hidden;
  span {
    color: #008cdd;
    background: url(../../assets/ico-notice.png) no-repeat left center;
    padding: 0 12px 0 23px;
    height: 14px;
    line-height: 14px;
    border-right: solid 1px #008cdd;
    float: left;
    margin-top: 12px;
    font-size: 14px;
  }
  .notice_title {
    width: 674px;
    // overflow: hidden;
    a {
      padding: 0 16px;    
      outline: none;
      text-decoration: none;
      color: #2e2e2e;
      font-size: 12px;
      line-height: 37px;
      position: relative;
      bottom: 1px;
    }
  }
}
</style>