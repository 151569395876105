<template>
  <div>
     <input type="hidden" name="pageRouter" value="detail/causeLongresignation" />
    <div v-html="detailData.articleContent"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {

        }
    },
    computed:{
    ...mapGetters('detail', ['detailData']),
  },
}
</script>

<style>

</style>