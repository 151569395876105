<template>
  <div class="w100 homeMainBox dsBody">
    <header class="w100">
      <Header> </Header>
      <Menu></Menu>
    </header>

    <section class="w100">
      <router-view></router-view>
    </section>

    <footer class="w100">
      <Footer />
    </footer>
  </div>
</template>

<script>
import Header from "./components/header/index.vue"
import Menu from "./components/menu/index.vue"
import Footer from "./components/footer/index.vue"

export default {
  data() {
    return {};
  },
  components:{
    Header,
    Menu,
    Footer,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.homeMainBox{
  min-width: 1200px !important;
}

.dsBody{
  background: url("../assets/100bg.jpg") no-repeat;
  background-size: 100% auto;
  // min-height: 1000px;
}
</style>