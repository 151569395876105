import req from "../../req/index"
import utils from "../../utils/index"

export default {
  namespaced: true,
  state: {
    academicData: {},
    academicLink: {}
  },
  actions: {
    async getAcademicData({
      commit
    }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/245/8/normal",
        method: "get"
      })

      console.log("@@res", res.articleVOS)
      commit("setAcademicData", {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      console.log("@@res2", utils.initHomeList(res.articleVOS))
      return res
    },
    async getAcademicLink({
      commit
    }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/243/0/normal",
        method: "get"
      })
      commit("setAcademicLink", {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })
      return res
    },
  },
  mutations: {
    setAcademicData(state, data) {
      state.academicData = data
    },
    setAcademicLink(state, data) {
      state.academicLink = data
      // console.log("moreLink",state.academicLink.moreLink);
    }
  }
}