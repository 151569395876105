<template>
  <div class="testList">
    <h3>官网</h3>
    <div v-for="item in list" :key="item.url" class="testItem" @click="go(item)">
      {{item.name}}
    </div>

    <h3>党群园地</h3>

    <div v-for="item in list2" :key="item.url" class="testItem" @click="go(item)">
      {{item.name}}
    </div>

    <h3>党史专题</h3>

    <div v-for="item in list3" :key="item.url" class="testItem" @click="go(item)">
      {{item.name}}
    </div>

    <h3>英文</h3>

    <div v-for="item in list4" :key="item.url" class="testItem" @click="go(item)">
      {{item.name}}
    </div>

    <h3>二十大</h3>
    <div v-for="item in list5" :key="item.url" class="testItem" @click="go(item)">
      {{item.name}}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "首页",
          url: "/"
        },
        {
          name: '详情页-富文本',
          url: '/detail/editor?columnId=154&articleId='
        },
        {
          name: '详情页-富文本-标题',
          url: '/detail/editortitle?columnId=160&articleId='
        },
        {
          name: '详情页-富文本-标题-时间',
          url: '/detail/editortitletime?columnId=203&articleId=7095'
        },
        {
          name: "详情页-中信所大事记",
          url: "/detail/bigEvents?columnId=157&articleId="
        },
        {
          name: '科技参考',
          url: '/detail/thinktankvision?columnId=540&articleId=1071150962317616005'
        },
        {
          name: '研究报告',
          url: '/detail/researchreport?columnId=541&articleId=1071150962317616134'
        },
        {
          name: '联盟视角',
          url: '/detail/perspective?columnId=542&articleId=1071150962317616333'
        },
        {
          name: '科技热点-详情',
          url: '/detail/sectionhotspot?columnId=538&articleId=1094598769342251253'
        },
        {
          name:'研究团队',
          url:'/detail/researchTeam?columnId=399&articleId='
        },
        {
          name:'现任领导',
          url:'/detail/leaderList?columnId=153&articleId='
        },
        {
          name:'学科建设',
          url:'/detail/disciplines?columnId=156&articleId='
        },
        {
          name:'中信所简介',
          url:'/detail/profileList?columnId=152&articleId='
        },
        {
          name:'中信所大事记详情',
          url:'/detail/bigEventsDetail?columnId=157&articleId='
        },
        // {
        //   name: '所致长辞',
        //   url: '/detail/editor?columnId=154&articleId='
        // },
        {
          name: '机构概括',
          url: '/detail/mechanism?columnId=155&articleId='
        },
        {
          name:'著作',
          url:'/detail/writings?columnId=169&articleId='
        },
        {
          name:'任课教师',
          url:'/detail/teachingStaff?columnId=197&articleId='
        },
        {
          name: '联系方式',
          url:'detail/contact?columnId=158&articleId='
        },
        {
          name:'交流合作',
          url:'detail/cooperation?columnId=220&articleId='
        },
        {
          name: '研究生快讯',
          url: '/detail/postgraduatenews?columnId=210&articleId='
        },
        {
          name: '依申请公开',
          url: '/detail/openuponapplication?columnId=233&articleId='
        },
        {
          name: '通知公告',
          url: '/detail/notice?columnId=286&articleId='
        },
        {
          name: 'ISTIC-CA科学计量学联合实验室',
          url: '/detail/istlcca?columnId=392&articleId='
        },
        {
          name: '国际合作',
          url: '/detail/internationalcooperation?columnId=221&articleId='
        },
        {
          name: '知识产权',
          url: '/detail/intellectualpr?columnId=170&articleId='
        },
        {
          name: '论文',
          url: '/detail/papersection?columnId=167&articleId='
        },
        {
          name: '论文详情',
          url: '/detail/pagesdetail?columnId=167&articleId=1079887509915446735'
        },
        {
          name: '资源建设',
          url: '/detail/resourceconstruction?columnId=171&articleId='
        },
        {
          name: '科技报告',
          url: '/detail/sandtreport?columnId=168&articleId='
        },
        {
          name: '招生就业',
          url: '/detail/enrollment?columnId=335&articleId='
        },
        {
          name:"信息公开-规章制度",
          url:'/detail/information?columnId=229&articleId='
        },
        {
          name: '战略智库',
          url: '/detail/strategic?columnId=278&articleId='
        },
        {
          name: '中信所简介详情',
          url: '/detail/zxsintroduction?columnId=152&articleId='
        }
      ],
      list2: [
        {
          name: '党群园地首页',
          url: '/dangqun'
        },
        {
          /*富文本-详情*/
          name: '富文本-详情',
          url: '/dangqun/detail/editor?columnId=428&articleId='
        },
        {
          name: '富文本-详情-时间',
          url: '/dangqun/detail/editortime?columnId=550&articleId=1097786683049238638'
        },
        {
          name: '列表',
          url: '/dangqun/detail/learneducation?columnId=433&articleId='
        },
        {
          name: '更多详情',
          url: '/dangqun/detail/detailmore?columnId=448&articleId='
        }
      ],
      list3: [
        {
          name: '党史专题首页',
          url: '/dangshi'
        },
        {
          name: '列表',
          url: '/dangshi/detail/centralspirit?columnId=475&articleId='
        },
        {
          name: '富文本-详情',
          url: '/dangshi/detail/editor?columnId=480&articleId=1097786683049238945'
        },
      ],
      list4: [
        {
          name: "英文官网",
          url: "/en"
        },
        {
          name:' 英文文本(没有标题和时间)',
          url:'/en/detail/editor?columnId=562&articleId='
        },
        {
          name:'英文网站ISTIC News页面',
          url:'/en/detail/news?columnId=575&articleId='
        },
        {
          name:"英文文本(有标题和时间)",
          url:"/en/detail/editorTitleTime?columnId=575&articleId=1096650337781851051"
        },
        {
          name: 'topicsTwo',
          url: '/en/detail/topicsTwo?columnId=574&articleId='
        },
        {
          name: 'topicsTwo详情',
          url: '/en/detail/topicsConstruction?columnId=580&articleId='
        },
        {
          name:'联系方式',
          url:'/en/detail/contact?columnId=577&articleId='
        },
        {
          name:'globalMou',
          url:'/en/detail/globalmou?columnId=578&articleId='
        },
        {
          name:'Joint Research Laboratory',
          url:'/en/detail/laboratory?columnId=579&articleId='
        }
      ],
      list5: [
        {
          name:'二十大',
          url: '/meeting20'
        },
        {
          name:'列表',
          url:'/meeting20/detail/centralSpirit?columnId=1158898879362982365&articleId='
        },
        {
          name: "富文本详情",
          url:'/meeting20/detail/editor?columnId=1158898879362982411&articleId=1158898879362982523'
        }
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    go(item){
      window.location.href = item.url;
    },
  },
};
</script>

<style lang="less" scoped>
.testList{
  padding: 50px;
  .testItem{
    border-bottom: 1px solid #ddd;
    padding: 12px;
    font-size: 14px;
  }
  .testItem:hover{
    background: #327cc0;
    color: #fff;
    cursor: pointer;
  } 
}

h3{
  margin: 30px 0 10px 0;
  padding-bottom: 10px;
  border-bottom: 3px solid red;
}
</style>