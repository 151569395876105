<template>
  <div class="w100 crumbs">
    <div class="crumbs-left">
      <i class="ico-local"></i>
      <span>&nbsp;当前位置：</span>

      <div v-for="(item, index) in crumbsList" :key="index" class="crumbs-item">
        <a :href="item.link">{{ item.title }}</a>
        <i class="arrow-right" v-if="index !== crumbsList.length - 1"></i>
      </div>
    </div>

    <div class="crumbs-right">
      <img src="../../../../assets/icon-share.png" alt="" @click="toggle" />
      <img src="../../../../assets/icon-print.png" alt="" @click="print" />
      <div class="shareLink" v-show="isToggle" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isToggle: false,
      config: {
        // url: "", // 网址，默认使用 window.location.href
        // source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        // title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        // origin: "", // 分享 @ 相关 twitter 账号
        // description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        // image: "", // 图片, 默认取网页中第一个img标签
        sites: ["weibo", "qq", "wechat"], // 启用的站点
        // disabled: ["google", "facebook", "twitter"], // 禁用的站点
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper:
          "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  computed: {
    ...mapGetters("detail", ["crumbsList"]),
  },
  components: {},
  mounted() {
    window.socialShare(".shareLink", this.config);
  },

  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
    hide(e) {
      this.isToggle = false;
    },
    print() {
      window.print()
    },
  },
};
</script>

<style lang="less" scoped>
.crumbs {
  height: 43px;
  display: flex;
  font-size: 14px;
  color: #666666;
  align-items: center;
  justify-content: space-between;
  .crumbs-left {
    display: flex;
    align-items: center;
    .crumbs-item {
      display: flex;
      align-items: center;
    }
    .ico-local {
      width: 12px;
      height: 16px;
      background: url("../../../../assets/local.svg") no-repeat center;
      -webkit-print-color-adjust: exact;
      display: inline-block;
    }
    .arrow-right {
      width: 7px;
      height: 10px;
      margin: 2px 5px 0;
      display: inline-block;
      background: url("../../../../assets/local-right.svg") no-repeat center;
      -webkit-print-color-adjust: exact;
    }
    a {
      color: #666666;
      &:last-child {
        color: #327cc0;
      }
    }
  }
  .crumbs-right {
    position: relative;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 14px;
    }

    .shareLink {
      z-index: 999;
      width: 130px;
      height: 42px;
      display: flex;
      position: absolute;
      justify-content: space-around;
      align-items: center;
      // overflow: hidden;
      top: 25px;
      left: -80px;
      /deep/.icon-weibo {
        color: #fff;
        background: #ed7415;
        -webkit-print-color-adjust: exact;
        border-color: #ff763b;
        display: inline-table;
        width: 33px;
        height: 33px;
        &:hover {
          color: #fff !important;
        }
      }
      /deep/.icon-qq {
        color: #fff;
        background: #1b8eee;
        -webkit-print-color-adjust: exact;
        display: inline-table;
        width: 33px;
        height: 33px;
        border-color: #1b8eee;
        &:hover{
          color: #fff !important;
        }
      }
      /deep/.icon-wechat {
        position: relative;
        color: #fff;
        background: #00c800;
        -webkit-print-color-adjust: exact;
        border-color: #00c800;
        display: inline-table;
        width: 33px;
        height: 33px;
        &:hover {
          color: #fff !important;
        }
      }
    }
  }
}
</style>
