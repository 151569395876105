<template>
  <div class="fullBox">
    <card :value="value" :dataShow="dangWeiData"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Card from "../../../../components/card/index.vue"
export default {
  data() {
    return {
      value:{
        name:'所党委工作',
        dynamicClass:'list-item-2',
        value:'dangweiWork'
      }
    };
  },
  components:{
    Card
  },
  serverPrefetch(){
    return this.getDangWeiData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.getDangWeiData()
    }
  },
  computed:{
    ...mapState("ds_dangweiwork",["dangWeiData"])
  },
  methods: {
    ...mapActions("ds_dangweiwork",["getDangWeiData"])

  },
};
</script>

<style lang="less" scoped>
</style>