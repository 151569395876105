<template>
  <div class="w100 menu-box flexCenter">
    <div class="enMainBox">
      <div class="logo">
        <a :href="data.baseUrl">
          <img src="../../../assets/en_logo.png" alt="">
        </a>
      </div>
      <div class="head-nav">
        <ul class="head-nav-list">
          <li v-for="(item,index) in data.subCategoryListVOList" :key="index" class="head-nav-list-item" :class="current(item)?'current':''">
            <a :href="item.linkUrl" class="first-title">
              {{item.categoryTitle}}
            </a>
            <div class="subNav" v-if="item.subCategoryListVOList.length != 0" >
              <div class="subNav-content">
                <span class="subNav-content-title">
                  {{item.categoryTitle}}
                </span>
                <ul>
                  <li class="children-list-item" v-for="childrenItem in item.subCategoryListVOList || []" :key="childrenItem.id">
                    <img src="../../../assets/dot.png" alt="">
                    <a :href="childrenItem.linkUrl">{{childrenItem.categoryTitle}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="head-lang">
        <a :href="data.homeUrl">CHI</a>
        <a :href="data.baseUrl">ENG</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  data() {
    return {
    }
  },
  computed:{
    ...mapState("en_menu",["data"]),
    ...mapGetters("en_detail",["crumbsList"])
  },
  
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.initData()
    }
  },
  serverPrefetch(){
    return this.initData()
  },
  methods:{
    ...mapActions("en_menu",["initData"]),
    current(item){
      
      if( (this.crumbsList[0]&&this.crumbsList[0].title)=== item.categoryTitle){
        return true
      }else{
        return false
      }
    }
  }
};
</script>

<style lang="less" scoped>
.menu-box {
  position: relative;
  .enMainBox{
    width: 1296px;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-nav {
      width: calc(100% - 520px);
      .head-nav-list {
        display: flex;
        .head-nav-list-item {
          font-size: 20px;
          padding: 28px 20px;
          cursor: pointer;
          .first-title{
            color: #000;
          }
          &:hover{
            background-color: #2F2D2D;
            .first-title {
              color: #FFF !important;
            }
            .subNav {
              display: block;
            }
          }
          .subNav {
            height: 125px;
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            background: url("../../../assets/nav2.jpg") no-repeat 9.9% 0px #fff;
            border-top: 1px solid #ABABAB;
            z-index: 10;
            top: 83px;
            .subNav-content {
              width: 1100px;
              margin: 20px auto;
              display: flex;
              align-items: center;
              .subNav-content-title {
                font-size: 30px;
                font-weight: bold;
                text-align: right;
                width: 30%;
                border-right: 1px solid #ABABAB;
                line-height: 80px;
                padding-right: 20px;
                color: #248ad7;
              }
              .children-list-item {
                margin-left: 40px;
                display: inline-block;
                img {
                  margin-right: 10px;
                }
                a {
                  font-size: 20px;
                  color: #004187;
                }
              }
            }
          }
        }
      }
    }
    .head-lang {
      a {
        // text-decoration: revert !important;
        text-decoration:underline !important;
        color: #551a8b !important;
        padding: 0 10px;
        &:hover {
          color: #551a8b !important;
        }
      }
    }
  }
}
.current {
  background-color: #2F2D2D;
  .first-title{
    color: white !important;
  }
}
</style>