import req from '../../req/index';
import utils from '../../utils/index';

export default {
  namespaced: true,
  state: {
    /* 
    {
      columnId 栏目id
      articleId 详情页id
    }
     */
    query: {},
    source:{},
    /* 
      {
        private Integer contentType; 1 只显示内容 * 2 显示标题+内容 * 3 显示标题+时间+内容
        private Integer isPaging; 是否分页  0 否 1 是
        topColumnTitle:string 栏目标题
        topRemark:string 英文描述
        subColumnVOList:[ 左侧菜单数据
          {
            subCategoryListVOList 子菜单
          }
        ]
        crumbsList: 面包屑
        columnAdImg 上边banner背景图片
        articleVOList 内容
      }
    */
    data: {},
  },
  getters:{
    baseUrl(state){
      return state.data.baseUrl || ''
    },
    topColumnTitle(state){
      return state.data.topColumnTitle || ""
    },
    topId(state){
      return state.data.topId || ""
    },
    topRemark(state){
      return state.data.topRemark || ""
    },
    subColumnVOList(state){
      let list = state.data.subColumnVOList || []
      return list
    },
    crumbsList(state){
      return state.data.crumbsList || []
    },
    columnAdImg(state){
      return state.data.columnAdImg || "/resource/404.jpeg"
    },
    articleVOList(state){
      return state.data.articleVOList || []
    },
    detailData(state){
      let data = (state.data.articleVOList || [])[0] || {}
      try {
        if(data.articleContent){
          let reg = new RegExp('src=\"/ueditor', "g")
          data.articleContent = data.articleContent.replace(reg, 'src=\"/html/1/529/ueditor')
          // data.articleContent.replace(reg, '/html/1/529/ueditor/jsp/upload')
        }
      } catch (error) {
      }
      
      return data
    },
    pageInfo(state){
      return state.data.pageInfo
    },
    dqList(state) {
      let dqList = utils.initHomeList(state.data.articleVOList||[])
      return dqList
    },
    encolumns(state) {
      // console.log(state.data.columns)
      // console.log(utils.encolumnsList(state.data.columns?state.data.columns:[]))
      // return utils.encolumnsList(state.data.columns) || []
      return utils.encolumnsList(state.data.columns?state.data.columns:[])
    },
    curColumnVO(state) {
      return state.data.curColumnVO || {}
    }
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initData({ commit, state }) {
      let res = await req.ajax({
        url: "/static/regenerate/generateEnColumnAndArticle",
        method: "post",
        data: {
          ...state.query||{},
          source: state.source
        }
      })
      
      commit('setData', {
        ...res,
      })

      console.log("en_detail_res:", res)
      return res;
    },
  },
  mutations: {
    setQuery(state, data){
      state.query = data || {};
    },
    setData(state, data) {
      state.data = data;
    },
    setSource(state,data){
      state.source =  data||''
    },
  },
}