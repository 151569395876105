export function JumpUrl(baseUrl,url){
  if(url){
    if(url.indexOf('http'||'https')!== -1){
      return url
    }else{
      return baseUrl + url
    }
  }else {
    return false
  }
}