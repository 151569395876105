<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showChildrenBox: false,
      canHide: false,
      top: 50,
      st: null,
      custP: null,
      index: null
    };
  },
  computed: {
    ...mapState("menu", ["menuList"]),
    // ...mapState("detail", ["query"]),
    ...mapGetters("detail", ["topId"]),
    // ...mapState("footer",['list']),
    ...mapState('cmsStatus',['dataList'])
  },
  serverPrefetch() {
    return Promise.all([this.initMenu(),this.cmsStatus()])
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.initMenu();
      // this.initList();
      this.cmsStatus();
    }
    this.initEvent();
  },
  watch:{
    index(val){
      console.log("@@@@@@@@", val, this.dataList, ( this.dataList && this.index!==0))
    }
  },
  methods: {
    ...mapActions("menu", ["initMenu"]),
    // ...mapActions("footer",['initList']),
    ...mapActions("cmsStatus",['cmsStatus']),
    initEvent() {
      let topDom = document.getElementsByClassName("header-content")[0];

      topDom &&
        (topDom.onmousemove = (e) => {
          this.showChildrenBox = false;
        });
    },
    show(item, index) {
      this.index = index
      this.showChildrenBox = true;
      this.custP = item;
      // console.log("@@@v", this.custP)
      this.top = 50;
      let isAdd = true;
      this.canHide = false;

      clearInterval(this.st);
      this.st = setInterval(() => {
        if (this.top < 55 && isAdd) {
          this.top += 1;
          return;
        } else {
          isAdd = false;
          this.top -= 1;
        }

        if (this.top <= 50) {
          clearInterval(this.st);
          return;
        }
      }, 40);
    },
    hide() {
      if (!this.canHide) return;

      this.showChildrenBox = false;
      this.custP = null;
    },
    go(item) {
      if(this.dataList && this.index!==0){
        return
      }
      item.linkUrl && window.open(item.linkUrl,"_self")
    },
    // isSel(items) {
    //   let isSel = false;

    //   for (let index = 0; index < items.length; index++) {
    //     const item = items[index];
    //     if(item.id === this.query.columnId){
    //       isSel = true;
    //       return isSel;
    //     }

    //     if(item.subCategoryListVOList){
    //       isSel = this.isSel(item.subCategoryListVOList)
    //     }
    //   }

    //   return isSel;
    // },
    // enda(val) {
    //   val.forEach(item=>{
    //     item.enable = this.list.enableSafePage
    //   })
    //   val[0] = {...val[0],enable:false}

    //   return val
    // }
  },
  render() {
    return (
      <div class="menu flexCenter">
        <ul class="menu-list">
          {this.menuList.map((item, index) => {
            return (
              <li
                class={`menu-item ${this.topId === item.id ? "selItem" : ""}`}
                onmouseover={() => this.show(item, index)}
                // onclick={() => this.go(item)}
              >
                <a class={ `${(this.dataList && this.index!==0) ? 'sellista' : ''}` } href={ this.dataList && this.index!==0 ? 'javascript:void(0);' : item.linkUrl }>{item.categoryTitle}</a>
              </li>
            );
          })}
        </ul>
        {this.showChildrenBox && (
          <div
            class="children-menu flexCenter"
            style={{ top: this.top + "px" }}
            onmouseleave={() => this.hide()}
            onmouseenter={() => {
              this.canHide = true;
            }}
          >
            <div class="children-menu-box">
              <div class="c-m-l">
                {this.custP && <h3>{this.custP.categoryTitle}</h3>}
              </div>

              <div class="c-m-r">
                {this.custP &&
                  this.custP.subCategoryListVOList &&
                  this.custP.subCategoryListVOList.map((item) => {
                    return (
                      <div class="c-m-r-1">
                        <h3 onclick={() => this.go(item)} class={`${(this.dataList && this.index!==0) ? 'sellista' : ''}`}>
                          {item.categoryTitle}
                        </h3>

                        {item &&
                          item.subCategoryListVOList &&
                          item.subCategoryListVOList.length > 0 && (
                            <div class="c-m-r-2">
                              {item.subCategoryListVOList.map((item_c) => {
                                return (
                                  <p onclick={() => this.go(item_c)} class={`${(this.dataList && this.index!==0) ? 'sellista' : ''}`}>
                                    {item_c.categoryTitle}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  beforeDestroy() {
    clearInterval(this.st);
  },
};
</script>

<style lang="less" scoped>
.menu {
  width: 100%;
  height: 50px;
  line-height: 50px;
  position: relative;
  border-top: solid 1px #efefef;
  .menu-list {
    display: flex;
    height: 50px;
    width: 1200px;
    .menu-item {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      width: 120px;
      text-align: center;
      border-bottom: solid 3px #fff;
      cursor: pointer;
      a{
        color: #333333;
        width: 100%;
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }
      a:hover{
        color: #327cc0 !important;
        font-weight: bold;
        border-bottom: solid 3px #327cc0;
      }
    }

    .selItem {
      a{
        color: #327cc0;
        font-weight: bold;
        border-bottom: solid 3px #327cc0;
      }
    }
  }
}
.children-menu {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 50px;
  background: #fff;
  z-index: 10;
  .children-menu-box {
    padding: 5px 0 20px 40px;
    min-height: 200px;
    z-index: 1;
    display: flex;
    width: 1200px;
    .c-m-l {
      width: 200px;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #327cc0;
        // line-height: 28px;
        position: relative;
      }
      h3:before {
        content: "";
        display: inline-block;
        position: absolute;
        border-radius: 5px;
        left: -15px;
        top: 23px;
        width: 8px;
        height: 8px;
        background: #327cc0;
        -webkit-print-color-adjust: exact;
      }
    }
    .c-m-r {
      width: calc(100% - 200px);
      display: flex;
      flex-wrap: wrap;
      padding-top: 12px;
      align-content: flex-start;
      .c-m-r-1 {
        width: 25%;
        margin-bottom: 30px;

        position: relative;
        h3 {
          font-size: 16px;
          font-weight: 400;
          color: #0a4e94;
          width: 180px;
          line-height: 1.5em !important;
          cursor: pointer;
        }
        
        p {
          cursor: pointer;
          font-size: 14px;
          color: #333;
          line-height: 30px;
        }
      }
      .c-m-r-1:before {
        content: "";
        display: inline-block;
        width: 3px;
        height: 17px;
        background: #327cc0;
        -webkit-print-color-adjust: exact;
        position: absolute;
        top: 4px;
        left: -10px;
      }
    }
  }
}
.sellista {
  cursor: default !important;
}
</style>