<template>
  <div class="w100 flexCenter links-Box">
    <div class="dsMainBox">
      <a v-for="item in list" :key="item.id" :href="item.link">
        <img :src="item.img" alt="">
      </a>
    </div>
  </div>
</template>

<script>
import fl1 from "../../../../assets/link1.png"
import fl2 from "../../../../assets/link2.png"
import fl3 from "../../../../assets/link3.png"

export default {
  data() {
    return {
      list: [
        {
          id: "1",
          img: fl1,
          link: "http://dangshi.people.cn/"
        },
        {
          id: "2",
          img: fl2,
          link: "http://www.12371.cn/dsxx/"
        },
        {
          id: "3",
          img: fl3,
          link: "http://www.dswxyjy.org.cn/GB/436883/index.html"
        }
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.links-Box{
  height: 76.5px;
  padding: 9px 0;
  .dsMainBox{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      display: block;
      width: 322px;
      height: 51px;
    }
  }
}
</style>