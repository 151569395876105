<template>
  <div class="w100 detailBanner">
    <img :src="columnAdImg"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      
    };
  },
  computed:{
    ...mapGetters("detail", ["columnAdImg"])
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.detailBanner{
  img{
    width: 100%;
    height: 210px;
  }
}
</style>