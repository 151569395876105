import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    wonderfulData:{},
    moreUrl: ''
  },
  actions:{
    async getWonderfulData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/448/5/normal',
        method: "get"
      });
      commit("setWonderfulData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS),
      });
      return res;
    },
  },  
  mutations:{
    setWonderfulData(state,data) {
      state.wonderfulData = data
      state.moreUrl = (data.list || [])[0].articleLinkURL
    },
  }
}
