import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/dangshi/layout/index.vue'
import Home from '../views/dangshi/views/home/index.vue'
import Detail from "../views/dangshi/views/detail/index.vue"
import Editor from "../views/dangshi/views/detail/views/editor/index.vue"
import CentralSpirit from '../views/dangshi/views/detail/views/centralSpirit/index.vue'

Vue.use(VueRouter)

export let comMapDS = {
  /* 官网 */
  "dangshi": {
    "layout": Layout,
    "/": Home,
    "detail": Detail,
    "editor": Editor,
    "centralspirit": CentralSpirit
  }
}

const routes = [
  {
    path: '/dangshi',
    name: "dangshi",
    component: Layout,
    children: [{
      path: '/',
      /* 首页 */
      name: 'Home',
      component: Home
    },{
      path: "detail",
      name: "detail",
      component: Detail,
      children: [
        {
          path: 'editor',
          /*富文本*/
          name: 'editor',
          component: Editor,
        },
        {
          path: 'centralspirit', //列表
          name: 'centralspirit',
          component: CentralSpirit
        }
      ]
    }]
  },
]

export default routes;