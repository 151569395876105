<template>
  <div v-if="crumbsList && crumbsList[1]">
    <input type="hidden" name="pageRouter" value="en/detail/globalmou"/>
    <top-title :title="crumbsList[1].title"></top-title>
    <div class="content">
      <div class="list-img-global"><img src="../../../../assets/1.png" alt=""/></div>
      <div class="list-desp-global" style="margin-bottom: 40px;">
        <table>
          <tbody>
            <tr>
                <td class="title">Country and Region</td>
                <td class="desp">Collaboration Projects</td>
            </tr>
            <tr>
                <td class="title">Belarus</td>
                <td class="desp">Establishing a partnership with the Joint Institute of Informatics of the Belarusian Academy of Sciences</td>
            </tr>
            <tr>
                <td class="title">Canada</td>
                <td class="desp">Wanfang Company signed a Strategic Cooperation Framework Agreement with Canadian College of Acupuncture & Traditional Chinese Medicine</td>
            </tr>
            <tr>
                <td class="title">Greek</td>
                <td class="desp">Establishing a partnership with the Greek National Literature Center</td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">The Center for Policy and Strategic Studies signed a research cooperation memorandum with the Research and Development Strategy Center of Japan Science and
                    Technology Agency and the China Comprehensive Research and Exchange Center of Japan Science and Technology Agency.
                </td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">Signing the Chinese-to-Japanese and Japanese-to-Chinese Machine Translation Cooperation Research Agreement with JST</td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">Signing “Draft Arrangement for Implementing Chinese-to-Japanese and Japanese-to-Chinese Machine Translation Project” with JST</td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">Signing Draft Arrangement for Implementing Chinese-to-Japanese and Japanese-to-Chinese Machine Translation Project with JST;<br/>
                    Signing a Memorandum of Implementation for the 2015-2017 Chinese-to-Japanese and Japanese-to-Chinese Machine Translation Project with JST
                </td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">Signing a Memorandum of Cooperation with JST</td>
            </tr>
            <tr>
                <td class="title">Japan</td>
                <td class="desp">The Center for Policy and Strategic Studies signed a research cooperation memorandum ii with the Research and Development Strategy Center of Japan Science and
                    Technology Agency and the China Comprehensive Research and Exchange Center of Japan Science and Technology Agency.
                </td>
            </tr>
            <tr>
                <td class="title">Korea</td>
                <td class="desp">Establishing long-term partnership with Korea Institute of Science and Technology Information (KISTI)</td>
            </tr>
            <tr>
                <td class="title">Netherlands</td>
                <td class="desp">Cooperating with Elsevier to establish a joint laboratory of “ISTIC-ELSEVIER Journal Evaluation Research Center”</td>
            </tr>
            <tr>
                <td class="title">Netherlands</td>
                <td class="desp">Renewing the Agreement on ISTIC-ELSEVIER Journal Evaluation Research Center with Elsevier</td>
            </tr>
            <tr>
                <td class="title">UK</td>
                <td class="desp">Signing the Agreement on the Cooperation of Joint Lab of Academic Frontier Observation with Taylor & Francis Group to establish the “ISTIC - Taylor & Francis
                    Group Joint Lab of Academic Frontier Observation”
                </td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a cooperation agreement with Thomson Reuters (Technology); establishing a joint laboratory of “ISTIC-Thomson Reuters Joint Laboratory of
                    Scientometrics”
                </td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a cooperation agreement with Thomson Reuters (Technology)</td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a Cooperative Renewal Agreement with Thomson Reuters (Technology)</td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a Joint Laboratory Cooperation Agreement on Establishing a Big Data Discovery Service for Literature with EBSCO; and co-founding the Joint Laboratory
                    of Literature Big Data Discovery Services
                </td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a Metadata License Agreement with John Wiley & Sons Inc.</td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a Memorandum of Understanding with John Wiley & Sons Inc.</td>
            </tr>
            <tr>
                <td class="title">US</td>
                <td class="desp">Signing a Cooperative Renewal Agreement with Clarivate Analytics</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopTitle from "../../components/topTitle/index.vue"
  export default {
    components:{
      TopTitle
    },
    computed:{
      ...mapGetters("en_detail",["crumbsList"])
    }
  }
</script>

<style lang="less" scoped>
.list-img-global {
  text-align: center;
}
.list-desp-global {
	text-align: center;
  table {
    text-align: center;
    border-collapse: collapse;
    font-size: 18px;
    color: #363434;
    width: 100%;
    td {
      border: 1px solid #bdbbbb;
	    height: 35px;
    }
    .title {
      width: 300px;
      font-size: 16px;
    }
    .desp{
      width: 800px;
      text-align: left;
      padding:13px 10px;
      font-size: 16px;
    }
  }
 }
</style>