import req from "../../req/index"
import utils from '../../utils/index'

export default {
  namespaced: true,
  state:{
    imgList:{}
  },
  actions:{
    async getImgList({commit}) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/253/8/normal",
        method: "get",
      });
      commit("setImgList",{
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS),
      })
      return res;
    },
  },
  mutations:{
    setImgList(state,data) {
      state.imgList = data
    }
  }
}