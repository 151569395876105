<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/resourceconstruction"/>
    <div class="resourcesBox" v-for="(item,index) in articleVOList" :key="index">
      <div class="resourcesImg">
        <a :href="urlpath(item.articleLinkURL)" :class="urlpath(item.articleLinkURL)?'':'setcursor'" :target="urlpath(item.articleLinkURL)?'_blank':''">
          <img :src="item.basicThumbnails || '/resource/404.jpeg'" alt="">
        </a>
      </div>
      <div class="resourcesRight">
        <div class="resourcesTitle">
          <a :href="urlpath(item.articleUrl)" :class="urlpath(item.articleUrl)?'':'setcursor'">{{item.basicTitle}}</a>
        </div>
        <div class="resourcesText resourcesIndent" v-html="item.articleContent"></div>
        <div class="resourcesText">
          <span>{{ item.modelData ? item.modelData.conDept.fieldTipsName : ''  }}：</span>  
          <a :href="JumpUrl(baseUrl ,item.modelData?item.modelData.conURL.fieldData:'')" :class="JumpUrl(baseUrl ,item.modelData?item.modelData.conURL.fieldData:'')?'':'setcursor'" :target="JumpUrl(baseUrl ,item.modelData?item.modelData.conURL.fieldData:'')?'_blank':''">{{item.modelData ? item.modelData.conDept.fieldData:''}}</a>
        </div>
        <!-- <span>{{item.box}}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {JumpUrl} from '../../../../utils/urlpath'
export default {
  data() {
    return {
      // detailData: [
        // {articleVOList:{ articleUrl: 'https://service.most.gov.cn/', basicTitle: '国家科技管理信息系统建设', text: '国家科技管理信息系统建设是利用现代信息技术，建设公开统一、功能完整、互联互通、安全高效的“一站式”信息系统，支撑中央财政五类科技计划（专项、基金）全过程的信息管理，辅助开展各项科技管理业务，实现科技计划项目全过程留痕管理，有效支撑国家科技计划管理改革。并利用在此基础上产生、汇聚、关联形成的科技活动主体、资源、条件、要素等在内的科技信息数据，面向科技管理者、专业机构、科研机构与科研人员、社会公众等提供以数据为基础的服务，形成“以数据为中心”的科技信息一体化服务体系，不断促进科技决策科学化、治理现代化、监管社会化、服务网络化、全面保障科技创新改革和发展。目前，国科管系统建设已开展并完成国家科技管理信息系统数据服务平台软件开发一期建设、支撑系统运行的基础设施环境建设、持续推进全国互联互通数据汇交及数据清洗工作', box: '支撑中心' }}
      // ]
      blank:''
    }
  },
  computed: {
    ...mapGetters('detail', ['articleVOList','baseUrl']),
  },
  methods: {
    urlpath(val){
      // console.log(val)
      if(!val || val =='#'){
        return false
      }else{
        this.blank = '_blank'
        return val
      }
    },
    JumpUrl
  }
}
</script>

<style lang="less" scoped>



.resourcesBox {
  padding: 34px 20px 34px 50px;
  display: flex;
  .resourcesImg {
    width: 198px;
    height: 265px;
    img {
      width: 198px;
      height: 265px;
    }
  }
  
  .resourcesRight {
    padding: 0px 0px 0px 30px;
    width: calc(100% - 230px);    
    .resourcesTitle {
      font-size: 18px;
      font-weight: 600;
      color: #327CC0 !important;
    }

    .resourcesText {
      font-size: 14px !important;
      line-height: 28px !important;
      color: #666 !important;
      margin-top: 20px;
      text-align: justify;
      }
    // .resourcesIndent {
    //   text-indent: 2em;
    // }
  }
    a:hover,
    a:visited,
    a:link,
    a:active {
      color: #327CC0;
      text-decoration: none;
    }
}
.setcursor {
  cursor: default;
}
</style>