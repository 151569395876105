<template>
  <div class="fullBox">
    <!-- 中央精神 -->
    <card :value="value[0]" :dataShow="centralSpiritData" class="centralSpiritCard"/>
    <div class="line"></div>
    <!-- 科技部工作 -->
    <card :value="value[1]" :dataShow="scienceWorkData"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import card from "../../../../components/card/index.vue"
export default {
  data() {
    return {
      value:[
        {
          name:'中央精神',
          dynamicClass: 'list-item-1',
          value:"centralSpirit"
        },
        {
          name:'科技部工作',
          dynamicClass: 'list-item-1',
          value:"scienceWork"
        }
      ],
    };
  },
  components:{
    card
  },
  serverPrefetch(){
    return Promise.all([this.getCentralSpiritData(),this.getScienceWorkData()])
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.getCentralSpiritData()
      this.getScienceWorkData()
    }
  },
  computed:{
    ...mapState("ds_spiritWork",["centralSpiritData","scienceWorkData"])
  },
  methods: {
    ...mapActions("ds_spiritWork",["getCentralSpiritData","getScienceWorkData"])
  },
};
</script>

<style lang="less" scoped>
.line {
  width: 100%;
  height: 1px;
  border-bottom:1px dashed #C2C2C2;
  margin: 15px 0 10px 0;
}

</style>