import req from '../../req/index';
import utils from "../../utils/index"

const getOne = (columnId)=>{
  return req.ajax({
    url: `/static/generate/getArticles/${columnId}/1/normal`,
    method: "get"
  })
}

export default {
  namespaced: true,
  state: {
    list: [],
    list2:[]
  },
  actions: {
    async initList({ commit }) {

      let arrRes = await Promise.all([getOne("580"), getOne("581"), getOne("582")]);

      let list = [];
      arrRes.map((item)=>{
        list = [...list,{
          columnVO:item.columnVO,
          urL: item.moreLink
        }]
      })
      commit('setList', {
        list
      })
      return arrRes;
    },
    async initList2({commit}){
      let res =await req.ajax({
        url:"/static/generate/getArticles/579/6/normal",
        method: "get"
      })
      commit("setList2",{
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })
    }
  },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
    setList2(state, data) {
      state.list2 = data;
    },
  },
}