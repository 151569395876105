<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/writings"/>
    <writings-list :listData="articleVOList" :pageInfo="pageInfo" :baseUrl="baseUrl"/>
  </div>
</template>

<script>
import WritingsList from './component/writingsList.vue'
import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
      }
    },
    components:{
      WritingsList
    },
    computed:{
      ...mapGetters("detail",["articleVOList","pageInfo",'baseUrl'])
    }
  }
</script>

<style lang="less" scoped>

</style>