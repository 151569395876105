<template>
  <div class="w100">
    <h3 class="dq-home-title">专题专栏</h3>
    <div class="buwangchuxin w100">
      <div class="buwangchuxin_top">
        <a :href="setSpecialData.baseUrl + '/472/474/index.html'"><img :src="jumpinUrl.basicThumbnails" alt=""/></a>
      </div>
      <div class="fullBox">
        <div ref="swiper_box" class="fullBox swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in swiperUrl" :key="index">
              <img :src="item.basicThumbnails" alt="">
            </div>
          </div>
        </div>
        <div class="swiper-pagination-3"></div> 
        <div class="swiper-btn prev_special"></div>
        <div class="swiper-btn next_special"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination-3",
          clickable: true, //允许分页点击跳转
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 23,
        // 设置点击箭头
        navigation: {
          nextEl: ".next_special",
          prevEl: ".prev_special",
        },
      },
    };
  },
  serverPrefetch () {
    return this.getSpecialcolumn()
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      await this.getSpecialcolumn();
    }
    this.init()
  },
  computed:{
    ...mapState('dq_special',['setSpecialData','jumpinUrl','swiperUrl'])    
  },
  methods: {
    ...mapActions('dq_special',['getSpecialcolumn']),
    async init() {
      let swiper = window.Swiper;
      new swiper(this.$refs.swiper_box, this.swiperOption);
    },
  },
};
</script>

<style lang="less" scoped>
.buwangchuxin {
  position: relative;
  .buwangchuxin_top {
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
    margin: 30px 0;
    // margin-top: 30px;
  }
  .swiper-container {
    position: relative;
  }
  .swiper-slide {
    width: 386px;
    height: auto;
    display: flex;
    // margin-right:24px;
    margin-left: 1px;
    img {
      width: 100%;
      height: 220px;
      // cursor: pointer; 
      // transform: scale(0.9);
    }
  }
  .swiper-btn {
    display: inline-block;
    position: absolute;
    width: 33px;
    height: 61px;
    top: 57%;
    z-index: 1;
    cursor: pointer;
  }
  .prev_special {
    background: url("../../../../assets/left.svg") no-repeat;
    background-size: cover;
    left: 0px;
    // top: 0px;
  }
  .next_special {
    background: url("../../../../assets/right.svg") no-repeat;
    background-size: cover;
    right: 0px;
  }
  /deep/ .swiper-pagination-bullet {
    width: 60px;
    height: 6px;
    border-radius: 0;
    margin: 0 10px;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: #DB3737 !important;
  }
  /deep/ .swiper-pagination-bullets {
    text-align: center;
  }
}
</style>