<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="detail/thinktankVision" />
    <div class="thinktank_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
      <div class="title_time">{{tiem(detailData.contentShowTime?detailData.contentShowTime:detailData.basicDateTime)}}</div>
    </div>
    <div v-html="detailData.articleContent" class="cont-txt"></div>
    <div class="title_bot">
      <div class="title_bottom">
        <span>来源:</span>
        <!-- <span>{{detailData.modelData?detailData.modelData.articleSource:''}}</span> -->
        <span>{{detailData.articleSource}}</span>
      </div>
      <div class="title_bottom">
        <span v-if="detailData.modelData" style="margin-right:10px;">{{detailData.modelData ? detailData.modelData.compile.fieldTipsName : ''}}:</span>
        <span>{{detailData.modelData ? detailData.modelData.compile.fieldData : ''}}</span>
      </div>
      <div class="title_bottom">
        <span v-if="detailData.modelData" style="margin-right:10px;">{{detailData.modelData ? detailData.modelData.Proofread.fieldTipsName : ''}}:</span>
        <span>{{detailData.modelData ? detailData.modelData.Proofread.fieldData : ''}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("detail", ['detailData'])
  },
  mounted() {},

  methods: {
    tiem(val) {
      if(val){
        return dayjs(val).format('YYYY-MM-DD')
      }
      return
    }
  },
}
</script>

<style lang="less" scoped>
.thinktank_title {
  margin: 20px 0 0 0;
  text-align: center;
  .title_title {
    font-size: 18px;
    font-weight: bold;
  }
  .title_time {
    font-size: 14px;
    color: #525252;
    // letter-spacing: 2px;
    height: 20px;
    line-height: 20px;
    // margin: 8px 0;
    padding: 15px 6px;
  }
}
.cont-txt {
  /deep/table{
    // margin-left: 20px !important;
    /deep/p {
      line-height: 0 !important;
    }
    tr {
      border: 1px #525252 solid;
    }
    td {
      border: 1px #525252 solid;
    }
  }
}
.title_bot {
  // padding: 16px 20px;
  margin-top: 42px;
}
.title_bottom {
    font-size: 14px;
    color: #666666;
    line-height: 32px;
    // padding: 16px 0;
    position: relative;
    font-family: 微软雅黑,Microsoft Yahei;
    padding: 0 40px;
}
</style>