<template>
    <div>
      <input type="hidden" name="pageRouter" value="detail/mechanism"/>
        <div class="mechanismImg">
            <img src="@/assets/InstitutionalSetting.png" alt="">
            <div class="mechanismList">
                <ul style="height:161px;padding: 5px 0;">
                    <li v-for="(item,index) in dataList" :key="index">{{ item }}</li>
                </ul>
                <ul style="height:276px;padding: 5px 0;margin-top:30px;">
                    <li v-for="(item, index) in articleVOList" :key="index">
                        <a :href="item.articleLinkURL" class="basicTitle">{{ item.basicTitle }}</a>
                    </li>
                </ul>
                <ul style="height:70px;padding: 5px 0;margin-top: 20px;">
                    <li v-for="(item, index) in dataListThree" :key="index">
                        <a :href="baseUrl + item.url">{{ item.title }}</a>
                    </li>
                </ul>
                <ul style="height:70px;padding: 5px 0;margin-top: 40px;">
                    <li v-for="(item, index) in dataListFour" :key="index">{{ item }}</li>
                </ul>
                <ul style="height:70px;padding: 5px 0;margin-top: 40px;">
                    <li v-for="(item, index) in dataListFive" :key="index">{{ item }}</li>
                </ul>
                <ul style="height:70px;padding: 5px 0;margin-top: 20px;">
                    <li v-for="(item, index) in dataListSix" :key="index">{{ item }}</li>
                </ul>
            </div>
        </div>
        <!-- <Page/> -->
    </div>
</template>

<script>
// import Page from '../../../../components/Page/index.vue'
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            // 2023-09-04：党委办公司 改成 纪委办公室、科研处 改成 成果管理办公司
            // dataList:['所办公室','党委办公司','人事处（国际合作处）','科研处','计划财务处','离退休干部服务处','保卫处'],
            dataList:['所办公室','党委办公室（纪委办公室）','人事处（国际合作处）','科研处（成果管理办公室）','计划财务处','离退休干部服务处','保卫处'],
            // dataListTwo:['1','2','1','2','1','2','1','2','1','2','1','2'],
            dataListThree:[{title:'北京科信苑物业管理公司',url: '/151/155/354.html'},{title:'北京万博科文化传媒有限责任公司',url: '/151/155/355.html'},{title:'北京万方数据股份有限公司',url: '/151/155/356.html'}],
            dataListFour:['科学技术委员会','学术委员会'],
            dataListFive: ['全国信息与文献标准化技术委员会'],
            dataListSix: ['中国科学技术情报学会','中国软科学研究会']
        }
    },
    computed: {
        ...mapGetters('detail',['articleVOList','baseUrl'])
    }
    // components: {
    //     Page
    // }
}
</script>

<style lang="less" scoped>

.mechanismImg {
    padding: 0px 16px;
    position: relative;
    .mechanismList {
        position: absolute;
        left: 325px;
        top: 10px;
        ul {
            overflow: hidden;
            li {
                color: #fff !important;
                line-height: 23px;
                font-size: 14px;
                -webkit-print-color-adjust: exact;
                a {
                    color: #fff !important;
                    -webkit-print-color-adjust: exact;
                    &:hover{
                        color: #fff !important;
                        text-decoration: underline;
                    }
                }
                
            }
            
        }
    }
}
</style>