<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/zxsintroduction" />
    <div v-html="detailData.articleContent" class="cont-html"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters("detail", ['detailData'])
  },
}
</script>

<style lang="less" scoped>
.cont-html {
  /deep/ p{
    span {
      font-family: Microsoft Yahei !important;
      color: #666
    };
  }
}
</style>