import req from '../../req/index';
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    /* 
    {
      columnId 栏目id
      articleId 详情页id
    }
     */
    query: {},
    source: '',
    /* 
      {
        private Integer contentType; 1 只显示内容 * 2 显示标题+内容 * 3 显示标题+时间+内容
        private Integer isPaging; 是否分页  0 否 1 是
        topId:当前选中顶级menu
        topColumnTitle:string 栏目标题
        topRemark:string 英文描述
        subColumnVOList:[ 左侧菜单数据
          {
            subCategoryListVOList 子菜单
          }
        ]
        crumbsList: 面包屑
        columnAdImg 上边banner背景图片
        articleVOList 内容
      }
    */
    data: {},
  },
  getters:{
    baseUrl(state){
      return state.data.baseUrl || ''
    },
    topColumnTitle(state){
      return state.data.topColumnTitle || ""
    },
    topRemark(state){
      return state.data.topRemark || ""
    },
    subColumnVOList(state){
      let list = state.data.subColumnVOList || []
      /* 默认第一个的子集 */
      list = (list[0] || {})
      list = list.subCategoryListVOList || [];
      return list
    },
    topId(state){
      return state.data.topId || ""
    },
    crumbsList(state){
      return state.data.crumbsList || []
    },
    columnAdImg(state){
      return state.data.columnAdImg || "/resource/404.jpeg"
    },
    articleVOList(state){
      return state.data.articleVOList || []
    },
    detailData(state){
      return (state.data.articleVOList || [])[0] || {}
    },
    pageInfo(state){
      return state.data.pageInfo
    }
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initData({ commit, state }) {
      let res = await req.ajax({
        url: "/static/regenerate/generateColumnAndArticle",
        method: "post",
        // data: state.query
        data: {
          ...state.query||{},
          // source: "postgraduatenews"
          source: state.source,
        }
      })
      
      commit('setData', {
        ...res,
      })
      return res;
    },
  },
  mutations: {
    setQuery(state, data){
      state.query = data || {};
    },
    setData(state, data) {
      state.data = data;
    },
    setSoure(state,data){
      state.source =  data||''
    },
    setPageno(state,data) {
      state.pageNo = data
    }
  },
}