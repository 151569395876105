<template>
  <div class="footer-box w100">
    <p>地址：北京市海淀区复兴路15号&nbsp;&nbsp;&nbsp;&nbsp;邮编：100038</p>
    <p> 中国科学技术信息研究所&nbsp;&nbsp;&nbsp;&nbsp;版权所有&nbsp;&nbsp;&nbsp;&nbsp;京ICP备10027328号</p>
  </div>
</template>

<script>
export default {
  name: 'CmsNodeTemplateIndex',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.footer-box{
  height: 60px;
  padding: 20px 0;
  background: #dcdcdc;
  text-align: center;
  p{
    font-size: 12px;
    color: #989898;
    line-height: 30px;
  }
}
</style>