<template>
  <div class="w100 menuList" v-if="subColumnVOList[0]">
    <a class="w100 menuListTitle" :href="subColumnVOList[0].linkUrl">
      <img v-if="icon[subColumnVOList[0].categoryTitle]" :src="icon[subColumnVOList[0].categoryTitle]"/>
      <span>{{subColumnVOList[0].categoryTitle}}</span>
    </a>

    <div class="menuCList" v-if="subColumnVOList[0].subCategoryListVOList">
      <a v-for="item in subColumnVOList[0].subCategoryListVOList" :key="item.id" :class="`menuItem ${isSel(item)?'menuItemSel':''}`" :href="item.linkUrl">
        <div class="menuCListText">{{item.categoryTitle}}</div>
      </a>
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import zzjg from "../../../../assets/zzjg.svg";
import xxjy from "../../../../assets/xxjy.svg";
import dwgz from "../../../../assets/dwgz.svg";
import jwgz from "../../../../assets/jwgz.svg";
import ghgz from "../../../../assets/ghgz.svg";
import tqgz from "../../../../assets/tqgz.svg";
import jcdt from "../../../../assets/jcdt.svg";
import jswm from "../../../../assets/jswm.svg";
import jcsj from '../../../../assets/jcsj.svg';
import zyjs from '../../../../assets/zyjs.svg';
import zygz from '../../../../assets/zygz.svg';
export default {
  data() {
    return {
      icon: {
        "组织机构": zzjg,
        "学习教育": xxjy,
        "党委工作": dwgz,
        "纪委工作": jwgz,
        "工会工作": ghgz,
        "团青工作": tqgz,
        "基层动态": jcdt,
        "精神文明": jswm,
        "精彩瞬间": jcsj,
        "中央精神": zyjs,
        "科技部工作": zygz,
      }
    };
  },
  computed:{
    ...mapState("dq_detail", ["query"]),
    ...mapGetters("dq_detail", ["subColumnVOList"])
  },
  mounted() {},

  methods: {
    isSel(item) {
      return (
        item.id === this.query.columnId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.menuList {
  a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #666;
  }
  a:hover {
    color: #666 !important;
  }
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.menuListTitle {
  background-color: #800013;
  width: 100%;
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  text-align: center;
  
  span{
    color: #fff;
  }
}
.menuCList{
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  .menuCListText {
    width: 120px;
    text-align: center;
    line-height: 32px;
  }
  .menuItem{
    display: inline-block;
    // width: 112px;
    border-bottom: 1px solid #E6E6E6;
    min-height: 30px;
    padding: 9px 0;
    font-size: 16px;
  }

  .menuItemSel{
    background: #FCEAE8;
    font-weight: 500;
    position: relative;
  }

  .menuItemSel::before{
    content: "";
    display: inline-block;
    width: 4px;
    height: 30px;
    background: #800013;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    word-break: break-all;
  }
}
</style>