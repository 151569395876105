<template>
  <div class="w100 flexCenter">
    <input type="hidden" name="pageRouter" value="dangqun/">
    <div class="dqMainBox">
      <div class="home-lr home-lr-01 mb20 flexYCenterXSB">
        <div class="home-lr-item">
          <Carousel />
        </div>

        <div class="home-lr-item">
          <MOST />
        </div>
      </div>

      <div class="home-lr mb20 flexYCenterXSB">
        <div class="home-lr-item">
          <Work />
        </div>

        <div class="home-lr-item">
          <Dynamic />
        </div>
      </div>

      <div class="home-lr mb40 flexYCenterXSB">
        <div class="home-lr-item">
          <Youth />
        </div>

        <div class="home-lr-item">
          <Learn />
        </div>
      </div>

      <div class="w100 content-box mb40">
        <Special />
      </div>

      <div class="w100 content-box mb30">
        <Wonderful />
      </div>
    </div>
  </div>
</template>

<script>
import Special from "./components/special/index.vue"; /* 专题专栏 */
import Wonderful from "./components/wonderful/index.vue"; /* 精彩瞬间 */
import Carousel from "./components/carousel/index.vue"; /* 顶部轮播 */
import MOST from "./components/MOST/index.vue"; /* 中央精神-科技部工作 */
import Work from "./components/work/index.vue"; /* 所党委工作动态 */
import Dynamic from "./components/dynamic/index.vue"; /* 基层党组织动态 */
import Youth from "./components/youth/index.vue"; /* 青年之家 */
import Learn from "./components/learn/index.vue"; /* 学习教育 */

export default {
  name: "CmsNodeTemplateIndex",

  data() {
    return {};
  },
  components: {
    Special,
    Wonderful,
    Carousel,
    MOST,
    Work,
    Dynamic,
    Youth,
    Learn,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less">
@import url("../../styles/home.less");
</style>

<style lang="less" scoped>
.dqMainBox {
  padding: 20px 0;
}
.home-lr-item {
  width: 590px;
  height: 345px;
  box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.03);
}
.home-lr-01 {
  .home-lr-item {
    height: 391px;
  }
}
</style>