<template>
  <div class="w100 m10">
    <div class="listData_box" :class="backImg.backImg" v-for="(item, index) in dataList" :key="index">
      <div class="listData_left">
        <!-- <div class="listData_bakimg listData_bakimg2" ></div> -->
        <a :href="item.link" class="overhideen" :class="backImg.width_a">{{ item.name }}</a>
      </div>
      <div class="listData_text">{{item.dq_time}}</div>
    </div>
    <div class="dq_page">
      <Page :pageInfo="pageInfo" v-if="pageInfo"/>
    </div>
  </div>
</template>

<script>
import Page from '../Page/index.vue'
export default {
  components: {
    Page
  },
  props: {
    dataList: {
      type: Array,
      default: ()=>[]
    },
    backImg: {
      type: Object,
      default: () => {}
    },
    pageInfo: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    // console.log(this.dataList)
  }
}
</script>

<style lang="less" scoped>

 .listData_bakimg2 {
   background: url(../../assets/radius.png) no-repeat 20px center !important;
    padding: 10px 20px 10px 40px;
 }

 .listData_bakimg1 {
   background: url('../../assets/dist1.svg') 20px center no-repeat;
   padding: 10px 20px 10px 40px;
 }  
 .padding_box {
    padding: 10px 20px 10px 20px;
 }
 .m10 {
  margin-top: 10px;
 }
.listData_box {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  // background: url(../../assets/radius.png) no-repeat 16px 6px !important;
  .listData_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    a {
      width: 405px;
      color: #333333;
      &:hover {
        color: #333333 !important;
      }
    }
    .dq_a {
      width: 850px !important;
    }
    .listData_bakimg {
      width: 5px;
      height: 5px;
      margin-right: 10px;
    }
  }
  .listData_text {
    color: #999999;
    font-size: 12px;
  }
}
.dq_page {
    margin: 20px 0;
  }
</style>