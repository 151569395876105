<template>
  <div class="banner swiper-container" ref="banner" id="banner">
    <div ref="swiperDom">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="item.leftImg ? item.leftImg.articleID:index">
          <!-- /resource/404.jpeg -->
          <img
            class="swiper-img-com"
            :class="item.leftImg.extArticleUrl ? 'swiper-img-comcur' : ''"
            :src="item.leftImg ? item.leftImg.basicThumbnails: ''"
            alt=""
            @click="go(item.leftImg)"
          />

          <img
            v-if="item && item.rightImg && item.rightImg.basicThumbnails && item.rightImg.articleKeyword==='跟随'"
            @click="go(item.rightImg)"
            class="imgDesBox"
            :src="item.rightImg.basicThumbnails"
          />
        </div>
      </div>
      
      <img
        v-if="list[custIndex] && list[custIndex].rightImg && list[custIndex].rightImg.basicThumbnails"
        :style="{right: zhezhaoW+'px'}"
        @click="go(list[custIndex].rightImg)"
        class="imgDesBox"
        :src="list[custIndex].rightImg.basicThumbnails"
      />
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </div>

    <div
      class="zhezhao zhezhaol"
      :style="{ width: zhezhaoW ? zhezhaoW + 'px' : '15%' }"
    ></div>
    <div
      class="zhezhao zhezhaor"
      :style="{ width: zhezhaoW ? zhezhaoW + 'px' : '15%' }"
    ></div>
    <div
      class="popBox"
      :style="{ right: zhezhaoW ? zhezhaoW + 200 + 'px' : '0px' }"
    >
      <div class="swiper-button-prev-home"></div>
      <div
        class="swiper-button-action"
        v-if="showBtn"
        @click="autoPlayControl"
      ></div>
      <div
        class="swiper-button-stop"
        v-if="!showBtn"
        @click="autoPlayControl"
      ></div>
      <div class="swiper-button-next-home"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  data() {
    this.initZhezhaoW = _.debounce(this.initZhezhaoW, 300);
    let that = this;
    return {
      zhezhaoW: 0,
      custIndex: 0,
      //swiper 初始化 https://www.swiper.com.cn/api/event/91.html
      swiperOption: {
        loop: true,
        speed: 3000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
          uniqueNavElements: false,
        },
        slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next-home",
          prevEl: ".swiper-button-prev-home",
        },
        on: {
          slideChangeTransitionStart(){
            let custIndex = this.activeIndex - that.list.length;

            if(custIndex === that.list.length){
              custIndex = 0;
            }else if(custIndex === -1){
              custIndex = that.list.length-1;
            }
            that.custIndex = custIndex;

            // console.log("当前轮播:", custIndex, that.list[custIndex]);
          },
        },
      },
      showBtn: true,
      mySwiper: {},
    };
  },
  computed: {
    ...mapState("banner", ["list"]),
  },
  serverPrefetch() {
    return this.initList();
  },
  async mounted() {
    if (process.env.NODE_ENV == "development") {
      await this.initList();
    }

    this.$nextTick(() => {
      this.init();
      this.mySwiper = this.$refs.swiperDom.swiper;
    });

    window.addEventListener("resize", this.initZhezhaoW);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initZhezhaoW);
  },
  methods: {
    ...mapActions("banner", ["initList"]),
    async init() {
      this.initZhezhaoW();
      let swiper = window.Swiper;
      new swiper(this.$refs.swiperDom, this.swiperOption);
    },
    go(item) {
      if(item.extArticleUrl !== ""){
        if(["1876511532810170594","1876511532810170736","1876511601529646992","2149891870557993904"].includes(item.articleID)){
          window.open(item.articleUrl);
        }else{
          window.open(item.extArticleUrl);
        }
      }
      // console.log(item.extArticleUrl);
    },
    initZhezhaoW() {
      this.$nextTick(() => {
        let domW = document.body.clientWidth;
        let img = document.getElementsByClassName("swiper-img-com")[0];

        if (domW <= 1200) {
          domW = 1200;
        }
        this.zhezhaoW = (domW - img.offsetWidth) / 2;
      });
    },
    async autoPlayControl() {
      if (this.showBtn) {
        this.mySwiper.autoplay.stop();
        this.showBtn = !this.showBtn;
      } else {
        this.mySwiper.autoplay.start();
        this.showBtn = !this.showBtn;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
  .popBox {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27px;
    bottom: 10px;
    width: 93px;
    z-index: 11;
  }
  .zhezhao {
    position: absolute;
    bottom: 0px;
    // width: 15%;
    height: 100%;
    z-index: 1;
    opacity: 0.8;
  }

  .zhezhaol {
    left: 0;
    // background: url("../../../../assets/lbl.png") no-repeat;
    background: linear-gradient(
      270deg,
     rgba(255,255,255,0.19) 0%, 
     rgba(255,255,255,0.59) 35%,
     rgba(255,255,255,0.76) 57%, 
     rgba(255,255,255,0.95) 100%, 
     #FFFFFF 100%
     );
    background-size: cover;
  }

  .zhezhaor {
    right: 0;
    // background: url("../../../../assets/lbr.png") no-repeat;
    background: linear-gradient(270deg, 
    rgba(255,255,255,0.19) 0%, 
    rgba(255,255,255,0.59) 35%, 
    rgba(255,255,255,0.76) 57%,
    rgba(255,255,255,0.95) 100%, 
    #FFFFFF 100%
    );
    background-size: cover;
  }
}
.fullbox {
  width: 93px;
  height: 27px;
}
.swiper-button-prev-home {
  background-image: url("../../../../assets/swiper-button-prev.png");
  background-size: 26px 27px;
}
.swiper-button-action {
  background-image: url("../../../../assets/swiper-button-action.png");
  background-size: 26px 27px;
}
.swiper-button-stop {
  background-image: url("../../../../assets/swiper-button-stop.png");
  background-size: 26px 27px;
}
.swiper-button-next-home {
  background-image: url("../../../../assets/swiper-button-next.png");
  background-size: 26px 27px;
}
.swiper-button-prev-home,
.swiper-button-next-home,
.swiper-button-action,
.swiper-button-stop {
  width: 26px;
  height: 27px;
  cursor: pointer;
}
.swiper-container {
  width: 100%;
  min-width: 1200px;
  min-height: 348px !important;
  position: relative;
  .swiper-img-com {
    width: 100%;
    max-width: 1200px;
    height: auto;
    min-width: 840px;
    min-height: 348px !important;
  }
}
.swiper-img-comcur {
    cursor: pointer;
}
/deep/ .swiper-pagination {
  // bottom: 30px !important;
  // left:  350px !important;
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    background: none;
    opacity: 0.8;
    border-radius: inherit;
  }
  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  max-width: 1200px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  // position: ;
}

// .swiper-wrapper{
//   position: static;
// }

.imgDesBox {
  z-index: 1;
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0.9;
}
</style>