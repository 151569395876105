<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/notice"/>
    <scientificresearch :articleVOList="articleVOList" :papersection="papersection"/>
    <div class="w100 flexCenter mb50">
      <Page v-if="pageInfo" :pageInfo="pageInfo"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Page from "../../../../components/Page"
import scientificresearch from '../../components/scientificresearch/index.vue'
export default {
  data() {
    return {
      papersection: {
        enrollment: true,
      }
    }
  },
  components: {
    scientificresearch,
    Page
  },
  computed: {
        ...mapGetters('detail',['articleVOList','pageInfo']),
  },
}
</script>

<style>

</style>