import req from '../../req/index';


export default {
  namespaced: true,
  state: {
    data: {}
  },
  actions: {
    async initData({commit}) {
      let res = await req.ajax({
        url: '/static/generate/getDjHeader',
        method: 'post',
        data: {
          ids: [419,421,407,406,408,409,555,556,410],
          type: "self"
        }
      })
    
      commit('setData', res)
      return res
    }
  },
  mutations: {
    setData(state,data) {
      state.data = data
    }
  }
}