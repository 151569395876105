<template>
  <div class="stabBox periodical">
    <STab v-model="list" @more="onMore" :moreLink="moreLink" @custMore="thisCust">
      <template slot="icon">
        <span class="icon"></span>
      </template>
      <template slot="periodical">
        <div class="fullBox">
          <div ref="swiper_box" class="fullBox swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in imgList.list"
                :key="item.id"
              >
                <img :src="item.image" alt="" @click="go(item)" />
              </div>
            </div>

            <!-- <div
              class="swiper-pagination swiper-pagination-2"
              slot="pagination"
            ></div> -->
          </div>
          <div class="swiper-btn prev2" slot="button-prev"></div>
          <div class="swiper-btn next2" slot="button-next"></div>
        </div>
      </template>
    </STab>
    
    <!-- <div class="periodical-pop" v-if="show">
      <div class="periodical-pop-header">
        <div class="periodical-pop-header-l">
          <b></b>
          <h3>期刊出版</h3>
        </div>

        <div class="periodical-pop-header-r" @click="show = false">
          <span>关闭</span>
          <b></b>
        </div>
      </div>

      <div class="periodical-pop-content">
        <div
          v-for="item in imgList.list"
          :key="item.id"
          class="pop-item"
          @click="go(item)"
        >
          <img :src="item.image" alt="" />
          <p v-html="item.name"></p>
        </div>
        <div class="pop-item qk-more">
          <a :href="imgList.moreLink" >
            <span>期刊出版</span>
          </a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import STab from "../../../../components/STab/index.vue";

export default {
  data() {
    return {
      show: false,
      list: [
        {
          name: "期刊出版",
          value: "periodical",
          paddingSize:'12px'
        },
      ],
      moreLink:'https://journals.istic.ac.cn/cn/default.aspx',
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination-2",
          clickable: true, //允许分页点击跳转
        },
        slidesPerView: 'auto',
        // centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 3,
        // 设置点击箭头
        navigation: {
          nextEl: ".next2",
          prevEl: ".prev2",
        },
      },
    };
  },
  components: {
    STab,
  },
  computed: {
    ...mapState("periodical", ["imgList"]),
  },
  serverPrefetch() {
    return this.getImgList();
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      await this.getImgList();
    }
    this.init();
  },

  methods: {
    ...mapActions("periodical", ["getImgList"]),
    init() {
      let swiper = window.Swiper;
      new swiper(this.$refs.swiper_box, this.swiperOption);
    },
    onMore() {
      this.show = true;
    },
    go(item) {
      window.open(item.link);
    },
    thisCust(data){
      if(data === "periodical" && this.show === false){
        this.show = true
      }else{
        this.show = false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.stabBox {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.periodical-pop {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 390px;
  min-height: 342px;
  background: #fff;
  z-index: 1;
  box-shadow: 1px 1px 5px 0 #ccc;
  .periodical-pop-header {
    width: calc(100% - 42px);
    height: 45px;
    padding: 0 21px;
    background: #f2f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .periodical-pop-header-l {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #333;
        font-weight: 400;
      }
      b {
        width: 28px;
        height: 28px;
        background: url("../../../../assets/book_icon.png") no-repeat;
        background-size: cover;
        margin-right: 16px;
        position: relative;
        top: 1px;
      }
    }
    .periodical-pop-header-r {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #333;
        font-weight: 400;
      }
      b {
        width: 7px;
        height: 14px;
        background: url("../../../../assets/arrow-right.svg") no-repeat;
        background-size: cover;
        margin-left: 5px;
      }
    }
  }
  .periodical-pop-content{
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    width: calc(100% - 51px);
    padding: 15px 25px;
    .pop-item {
      // margin-bottom: 15px;
      margin: 5.5px;
      cursor: pointer;
      img {
        width: 102px;
        height: 136px;
      }
      p {
        width: 102px;
        overflow: hidden;
        word-wrap: break-word;
        text-align: center;
        color: #333;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .qk-more {
      width: 102px;
      height: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #327cc0;
      a {
        span {
          
          display: inline-block;
          width: 40px;
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
}
.icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url("../../../../assets/qkcb.svg") no-repeat;
  background-size: cover;
}
.swiper-container {
  width: calc(100% - 40px);
  height: calc(100% - 10px);
  padding: 5px 0;
  position: relative;
}
.swiper-slide {
  // width: 42%;
  width: 170px;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  img {
    width: 100%;
    height: 235px;
    cursor: pointer;
    box-shadow: 0px 0px 8px #07285860;
    transform: scale(0.9);
    &:hover{
      box-shadow: 0px 0px 8px 6px #07285820;
      transition: all 0.5s;
    }
  }
}
.swiper-btn {
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 18px;
  top: 52%;
  z-index: 1;
  cursor: pointer;
}
.prev2 {
  background: url("../../../../assets/swiper-prev.svg") no-repeat;
  background-size: cover;
  left: 15px;
  outline: none;
}
.next2 {
  background: url("../../../../assets/swiper-next.svg") no-repeat;
  background-size: cover;
  right: 15px;
  outline: none;
}
</style>