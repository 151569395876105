<template>
  <div class="fullBox sTab">
    <div class="sTab-header">
      <div class="sTab-header-left">
        <div class="iconBox">
          <slot name="icon" class="iconBox"></slot>
        </div>

        <span class="title">
          {{ value.name }}
        </span>

        <!-- <div class="enNameBox" v-if="value.enName">
          <div class="enNameIcon">
            <b></b>
            <b></b>
            <b></b>
          </div>
          <div class="enName" :style="{left: value.left}">{{ value.enName }}</div>
        </div> -->
      </div>

      <div class="sTab-header-right">
        <a :href="moreLink">More <span class="iconR1"></span></a>
      </div>
    </div>

    <div class="stab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /* 
      {
        name: '新闻动态',
        value: 'news',
      }
    */
    value: {
      type: Object,
      default: () => ({}),
    },
    moreLink:{
      type:String,
      default:''
    }
  },
  data() {
    return {

    };
  },
  mounted() {},
  methods: {
    onMore() {
      this.$emit("more");
    },
  },
};
</script>

<style lang="less" scoped>
.sTab-header {
  width: calc(100% - 24px);
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #327cc0;
  padding: 0 12px;
  .sTab-header-left {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconBox {
      position: relative;
      top: 2px;
    }
    .title {
      margin: 0 10px;
      font-size: 16px;
      font-weight: 500;
      font-weight: bold;
      color: #333;
    }
    .enName {
      font-size: 12px;
      color: #999;
      position: relative;
      
      top: -5px;
      transform: scale(0.6);
    }
    .enNameIcon{
      b{
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: #CB5D62;
      }
    }
  }
  .sTab-header-right a {
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    color: #666666;
  }
  // .sTab-header-right a:hover {
  //   color: #fff !important;
  // }
}
.iconR1 {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 6px;
  height: 8px;
  background: url("../../assets/iconR3.svg") no-repeat;
  background-size: cover;
}
.stab-content {
  width: calc(100% - 40px);
  height: calc(100% - 85px);
  overflow: hidden;
  padding: 20px;
}
</style>