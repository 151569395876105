<template>
  <div class="contact">
    <input type="hidden" name="pageRouter" value="en/detail/contact"/>
    <div class="content" v-if="articleVOList && articleVOList[0]">
      <div class="left" v-html="articleVOList[0].articleContent"></div>
      <div class="right">
        <img :src="articleVOList[0].basicThumbnails" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("en_detail",["articleVOList"])
    }
  }
</script>

<style lang="less" scoped>
.content {
  display: flex;
  justify-content: space-between;
  .left {
    margin-top: 80px;
    width: 300px;
    /deep/ div {
      margin: 16px 0;
      line-height: 30px;
    }
  }
  .right {
    margin-top: 50px;
    margin-bottom: 85px;
    width: 900px;
    img {
      width: 100%;
    }
  }
}
</style>