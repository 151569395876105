<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="dangqun/detail/editor" />
    <div class="editor_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
    </div>
    <div v-html="detailData.articleContent" class="editor_html"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dq_detail", ['detailData'])
  },
  mounted() {},

  methods: {
    getTime(val) {
      return dayjs(val).format('YYYY-MM-DD')
    }
  },
};
</script>
<!-- <style lang="less">
</style> -->
<style lang="less" scoped>
   @import url('../../../../styles/detail.less');
</style>