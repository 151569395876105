<template>
  <div>
    <input type="hidden" name="pageRouter" value="en/detail/topicsConstruction" />
      <listImg :curColumnVO="curColumnVO" :dataList="articleVOList" :dataStyle="dataStyle" :title="curColumnVO.columnKeyword">
        <!-- <div class="slot_box" slot="titleBox">With the focus of the integration of “Big Data of Technical Literature, Big Data of National Science and Technology Management Information System, and Big Data of Scientific Research Facts”, we will comprehensively carry out data knowledge organization and integration, form a number of characteristic databases closely related to the industry, and fully build a National Science and Technology Resource Big Data Center.</div> -->
      </listImg>
  </div>
</template>

<script>
import topTitle from '../../components/topTitle/index.vue'
import listImg from '../../components/listImg/index.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    topTitle,
    listImg
  },
  computed: {
    ...mapGetters('en_detail',['articleVOList','curColumnVO'])
  },
  data() {
    return {
      dataStyle: {
        box_img:'box_img2',
        box_layer: 'box_layer2',
        box_content: 'box_content'
      }
    }
  }

}
</script>

<style lang="less" scoped>
.slot_box {
  text-indent: 35px;
  line-height: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  // text-align: center;
  text-align: justify;
}
/deep/.cont_box{
  padding: 0px !important;
}
/deep/.box_layer {
  padding-bottom: 80px !important;
} 
</style>