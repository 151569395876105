import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    setSpecialData:{},
    jumpinUrl: '',
    swiperUrl: [],
  },
  actions:{
    async getSpecialcolumn({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/549/-1/firstTop',
        method: "get"
      });
      commit("setSpecialData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS),
          baseUrl: res.baseUrl
      });
      return res;
    },
  },  
  mutations:{
    setSpecialData(state,data) {
      state.setSpecialData = data
      state.jumpinUrl = (data.list || [])[0]
      state.swiperUrl = data.list.slice(1) || []
    },
  }
}
