import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    data: {},
  },
  actions: {
    async initData({commit}) {
      let res = await req.ajax({
        url: '/static/generate/getEnIndexHeader/558/2',
        method: 'get',
      })
      
      commit('setData',{
        subCategoryListVOList: res.categories[0].subCategoryListVOList,
        baseUrl: res.baseUrl + "529/558/index.html",
        homeUrl: res.homeUrl
      })

      return res
    }
  },
  mutations: {
    setData(state,data) {
      // console.log(data,'q11121312')
      state.data = data
      // state.baseUrl = data.baseUrl
    },
  }
}