import { createApp } from './src/main'

const { app, router, store } = createApp()

if (window.__INITIAL_STATE__) {
  // console.log("window.__INITIAL_STATE__", window.__INITIAL_STATE__)
  store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
  app.$mount('#app')
})
