import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    centralSpiritData:{},
    scienceWorkData:{},
    scienceWorkData2:{},
    scienceWorkData3:{},
    scienceWorkData4:{},
    scienceWorkDataBanner:{},
    id:'',
    page: '',
  },
  actions:{
    async getCentralSpiritData({ commit,state }){
      let res = await req.ajax({
        url:`/static/generate/getArticles/1158898879362982365/6/normal`,
        // url:`/static/generate/getArticles/${state.id}/${state.page}/normal`,
        method: "get"
      });
      commit("setCentralSpiritData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/1158898879362982405/6/normal',
        method: "get"
      });
      commit("setScienceWorkData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkData2({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/1158898879362982395/6/normal',
        method: "get"
      });
      commit("setScienceWorkData2",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkData3({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/1158898879362982385/6/normal',
        method: "get"
      });
      commit("setScienceWorkData3",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkData4({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/1158898879362982411/6/normal',
        method: "get"
      });
      commit("setScienceWorkData4",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkDataBanner({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/1158898879362982395/6/allTop',
        method: "get"
      });
      commit("setScienceWorkDataBanner",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
  },  
  mutations:{
    setCentralSpiritData(state,data) {
      state.centralSpiritData = data
    },
    setId(state,data){
      state.id =  data||''
    },
    setPage(state,data){
      state.page = data||''
    },
    setScienceWorkData(state,data) {
      state.scienceWorkData = data
    },
    setScienceWorkData2(state,data) {
      state.scienceWorkData2 = data
    },
    setScienceWorkData3(state,data) {
      state.scienceWorkData3 = data
    },
    setScienceWorkData4(state,data) {
      state.scienceWorkData4 = data
    },
    setScienceWorkDataBanner(state,data) {
      // console.log(data,'@123131231')
      state.scienceWorkDataBanner = data
    }
  }
}
