import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    dangWeiData:{},
  },
  actions:{
    async getDangWeiData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/477/6/normal',
        method: "get"
      });
      commit("setDangWeiData",{
          moreLink: res.moreLink || "",
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    }
  },  
  mutations:{
    setDangWeiData(state,data) {
      state.dangWeiData = data;
    }
  }
}
