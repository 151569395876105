<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="en/">
    <div class="enBanner">
      <Banner></Banner>
    </div>
    <div class="w100 flexCenter">
      <div class="enMainBox">
        <Content></Content>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./components/banner/index.vue"
import Content from './components/content/index.vue'
export default {
  data() {
    return {};
  },
  components: {
    Banner,
    Content,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less">
@import url("../../styles/home.less");
</style>

<style lang="less" scoped>
</style>