import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/index.vue'
import Home from '../views/home/index.vue'
import Detail from '../views/detail/index.vue'
import Editor from '../views/detail/views/editor/index.vue'
import editorTitle from '../views/detail/views/editorTitle/index.vue'
import editorTitleTime from '../views/detail/views/editorTitle_time/index.vue'
import NewList from '../views/detail/views/newList/index.vue'
import LeaderList from '../views/detail/views/ImgLeaderList/index.vue'
import ProfileList from '../views/detail/views/profileList/index.vue'
import Mechanism from '../views/detail/views/mechanism/index.vue'
import Disciplines from '../views/detail/views/Disciplines/index.vue'
import Contact from '../views/detail/views/contact/index.vue'
import BigEvents from "../views/detail/views/bigEvents/index.vue"
import BigEventsDetail from '../views/detail/views/bigEventDetail/index.vue'
import ResearchTeam from '../views/detail/views/researchTeam/index.vue'
import CauseLongresignation from '../views/detail/views/causeLongresignation/index.vue'
import resourceConstruction from '../views/detail/views/resourceconstruction/index.vue'
import paperSection from '../views/detail/views/papersection/index.vue'
import pagesDetail from '../views/detail/views/pagesdetail/index.vue'
import sandtReport from '../views/detail/views/sandtreport/index.vue'
import intellectualPr from '../views/detail/views/intellectualpr/index.vue'
import Writings from '../views/detail/views/writings/index.vue'
import Enrollment from '../views/detail/views/enrollment/index.vue'
import postgraduateNews from '../views/detail/views/postgraduateNews/index.vue'
import TeachingStaff from "../views/detail/views/researchTeam/index.vue"
import Cooperation from '../views/detail/views/cooperation/index.vue'
import openUponapplication from '../views/detail/views/openUponapplication/index.vue'
import istLcca from '../views/detail/views/istlcca/index.vue'
import Information from "../views/detail/views/information/index.vue"
import Notice from '../views/detail/views/notice/index.vue'
import internationalCooperation from '../views/detail/views/internationalcooperation/index.vue'
import thinktankVision from '../views/detail/views/thinktankvision/index.vue'
import persPective from '../views/detail/views/perspective/index.vue' 
import researchReport from '../views/detail/views/researchreport/index.vue'
import sectionHotspot from '../views/detail/views/sectionhotspot/index.vue'
import strategic from '../views/detail/views/strategic/index.vue'
import zxsIntroduction from '../views/detail/views/zxsintroduction/index.vue'
import Test from "../views/test/index.vue"
import ErrPage from '../views/404/index.vue'
import dangqunConf,{comMapDQ} from "./dangqun"
import dangshiConf,{comMapDS} from "./dangshi"
import enConf,{comMapEn} from "./en"
import meeting20,{comMapDA}from './meeting20'
import _ from "lodash"

let comMap = {
  /* 官网 */
  "web": {
    "layout": Layout,
    "/": Home,
    "detail": Detail,
    "editor": Editor,
    "editortitle": editorTitle,
    "editortitletime": editorTitleTime,
    "newList": NewList,
    "profileList": ProfileList,
    "leaderList": LeaderList,
    "mechanism": Mechanism,
    "disciplines": Disciplines,
    "contact": Contact,
    "bigEvents": BigEvents,
    "bigEventsDetail": BigEventsDetail,
    "causeLongresignation": CauseLongresignation,
    "resourceconstruction": resourceConstruction,
    "papersection": paperSection,
    "writings": Writings,
    "researchTeam": ResearchTeam,
    "teachingStaff": TeachingStaff,
    "cooperation": Cooperation,
    "pagesdetail": pagesDetail,
    "sandtreport": sandtReport,
    "intellectualpr": intellectualPr,
    "enrollment": Enrollment,
    "postgraduatenews": postgraduateNews,
    "openuponapplication": openUponapplication,
    "information": Information,
    "istlcca": istLcca,
    "notice": Notice,
    "internationalcooperation": internationalCooperation,
    "thinktankVision": thinktankVision,
    "perspective": persPective,
    "researchreport": researchReport,
    "sectionhotspot": sectionHotspot,
    "strategic": strategic,
    "zxsintroduction": zxsIntroduction
  },
  ...comMapDQ,
  ...comMapDS,
  ...comMapEn,
  ...comMapDA,
}

Vue.use(VueRouter)

const routes = [{
    path: "/test",
    name: "test",
    component: Test
  },
  /* 党群园地 */
  ...dangqunConf,
  /* 党史学习教育 */
  ...dangshiConf,
  /* 英文站点 */
  ...enConf,
  /* 官网 */
  /* 二十大 */
  ...meeting20,
  {
    path: '/',
    name: "web",
    component: Layout,
    children: [{
        path: '/',
        /* 首页 */
        name: 'home',
        component: Home
      },
      // {
      //   path: "/html",
      //   component: Detail,
      //   children: [
      //     {
      //       path: "/html",
      //   component: Editor,
      //     }
      //   ]
      // },
      {
        path: "detail",
        /* 详情页layout */
        name: "detail",
        component: Detail,
        children: [{
            path: "editor",
            /* 富文本详情 */
            name: "editor",
            component: Editor,
          }, 
          {
            /* 富文本详情-标题 */
            path: 'editortitle',
            name: 'editortitle',
            component: editorTitle
          },
          {
            /* 富文本详情-标题-时间 */
            path: 'editortitletime',
            name: 'editortitletime',
            component: editorTitleTime
          },
          {
            path: 'zxsintroduction',
            name: 'zxsintroduction',
            component: zxsIntroduction
          },
          {
            path: "newList",
            /* 新闻列表 */
            name: "newList",
            component: NewList,
          },
          {
            path: "profileList",
            name: 'profileList',
            component: ProfileList
          },
          {
            path: 'leaderList', // 现任领导
            name: 'leaderList',
            component: LeaderList
          },
          {
            path: 'mechanism', //机构设置
            name: 'mechanism',
            component: Mechanism
          },
          {
            path: 'disciplines', // 学科建设
            name: 'disciplines',
            component: Disciplines
          },
          {
            path: 'contact', // 联系方式
            name: 'contact',
            component: Contact
          },
          {
            /* 中信所大事记 */
            path: "bigEvents",
            name: "bigEvents",
            component: BigEvents
          },
          {
            // 大事记详情
            path: 'bigEventsDetail',
            name: 'bigEventsDetail',
            component: BigEventsDetail
          },
          {
            path: 'causeLongresignation', //所致长辞 id: 154
            name: 'causeLongresignation',
            component: CauseLongresignation
          },
          {
            path: 'resourceconstruction', //资源建设 id：171
            name: 'resourceconstruction',
            component: resourceConstruction
          },
          {
            path: 'papersection', //论文列表 
            name: 'papersection',
            component: paperSection
          },
          {
            path: 'writings', //著作
            name: 'writings',
            component: Writings
          },
          {
            path: 'researchTeam',
            name: 'researchTeam',
            component: ResearchTeam
          },
          {
            path: 'teachingStaff',
            name: 'teachingStaff',
            component: TeachingStaff
          },
          {
            path: 'cooperation',
            name: 'cooperation',
            component: Cooperation
          },
          {
            path: 'pagesdetail', //论文详情
            name: 'pagesdetail',
            component: pagesDetail
          },
          {
            path: 'sandtreport', //科技报告列表
            name: 'sandtreport',
            component: sandtReport
          },
          {
            path: 'intellectualpr', //知识产权
            name: 'intellectualpr',
            component: intellectualPr
          },
          {
            path: 'enrollment', //招生工作
            name: 'enrollment',
            component: Enrollment
          },
          {
            path: 'postgraduatenews', //研究生快讯
            name: 'postgraduatenews',
            component: postgraduateNews
          },
          {
            path: 'openuponapplication', //申请公开
            name: 'openuponapplication',
            component: openUponapplication
          },
          {
            path: 'information', //信息公开
            name: 'information',
            component: Information
          },
          {
            path: 'istlcca', //ISTIC-CA科学计量学联合实验室
            name: 'istlcca',
            component: istLcca
          },
          {
            path: 'notice', //通知公告
            name: 'notice',
            component: Notice
          },
          {
            path: 'internationalcooperation', //国际合作
            name: 'internationalcooperation',
            component: internationalCooperation
          },
          {
            path: 'thinktankvision', //智库视野特殊详情
            name: 'thinktankvision',
            component: thinktankVision
          },
          {
            path: 'perspective', //研究报告+联盟视角详情
            name: 'perspective',
            component: persPective
          },
          {
            path: 'researchreport',
            name: 'researchreport',
            component: researchReport
          },
          {
            path: 'sectionhotspot',
            name: 'sectionhotspot',
            component: sectionHotspot //科技热点详情
          },
          {
            path: 'strategic',
            name: 'strategic',
            component: strategic //战略规划
          }
          // {
          //   path: '*',
          //   name: '404',
          //   component: ErrPage
          // },

        ]
      },
      // {
      //   path: '*',
      //   name: '404',
      //   component: ErrPage
      // },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: ErrPage
  },
]

/* 兼容ssr静态模资源路径跟router不匹配问题 */
const appendStaticRouter = (router, toPath) => {

  console.warn("开始注入路由->")
  /* 获取隐藏域中页面的router信息 */
  let hideDoms = document.getElementsByName("pageRouter")

  let pathRouter = hideDoms[0] && hideDoms[0].value || ""
  if (!pathRouter) {
    console.warn("没查找路由信息->关闭")
    return false;
  };

  pathRouter = pathRouter.split("/");

  console.warn("查找路由信息->", pathRouter)
  /* 父路由名称 */
  let layoutName = pathRouter[0]
  /* 需要生成的路由信息 */
  let elseRouterName = [];

  if (["dangqun", "dangshi", "en","meeting20"].indexOf(layoutName) === -1) {
    layoutName = "web"
    elseRouterName = pathRouter;
  } else {
    elseRouterName = pathRouter.splice(1);
  }

  elseRouterName = elseRouterName.map((name) => {
    return {
      path: toPath,
      name: name,
      component: comMap[layoutName][name || "/"]
    }
  })

  const initConf = (pRouter) => {
    if (elseRouterName.length === 0) {
      return pRouter
    }

    let custRouter = elseRouterName.shift(1);
    pRouter.children = [custRouter];

    if (elseRouterName.length === 0) {
      return pRouter
    } else {
      pRouter.children = [initConf(custRouter)];
    }

    return pRouter;
  }

  let routerConf = initConf({
    path: layoutName === 'web' ? "/" : `/${layoutName}`,
    name: layoutName,
    component: comMap[layoutName].layout
  })

  console.warn("路由结构->", layoutName, routerConf)
  router.addRoute(routerConf)

  console.warn("注入路由成功->", router.getRoutes(), "->end")
  return true;
}

export default function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    // base: process.env.VUE_APP_PUB_PATH,
    routes
  })

  router.beforeEach((to, from, next) => {
    if(typeof window === "object"){
      let toPath = to.path;
      // console.log("to->", to, toPath)
      let routes = router.getRoutes();
      let toPathHtml = toPath;//+'.html';
      if (!_.find(routes, {path: toPathHtml}) && !_.find(routes, {path: toPath})) {
        /* 返回true说明添加成功 */
        if(appendStaticRouter(router, toPathHtml)){
          return next({path: toPathHtml, replace: true})
        }else{
          next({path: "/404", replace: true})
        }
      }
    }

    return next()
  })

  return router;
}