import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    centralSpiritData:{},
    scienceWorkData:{}
  },
  actions:{
    async getCentralSpiritData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/543/8/normal',
        method: "get"
      });
      commit("setCentralSpiritData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
    async getScienceWorkData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/544/8/normal',
        method: "get"
      });
      commit("setScienceWorkData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
  },  
  mutations:{
    setCentralSpiritData(state,data) {
      state.centralSpiritData = data;
    },
    setScienceWorkData(state,data){
      state.scienceWorkData = data;
    }
  }
}
