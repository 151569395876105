<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="dangqun/detail/editortime"/>
    <div class="editor_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
      <div class="title_time">{{getTime(detailData.contentShowTime?detailData.contentShowTime:detailData.basicDateTime)}}</div>
    </div>
    <div v-html="detailData.articleContent" class="editor_html"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dq_detail", ['detailData'])
  },
  mounted() {},

  methods: {
    getTime(val) {
      if(val){
        return dayjs(val).format('YYYY-MM-DD')
      }
      
    }
  },
};
</script>
<!-- <style lang="less">
</style> -->
<style lang="less" scoped>
  @import url('../../../../styles/detail.less');
  .editor_html {
    /deep/ p {
      margin-bottom: 10px !important;
    }
  }
</style>