<template>
  <div>
    <ul class="cooperation-list">
      <li class="cooperation-list-item" v-for="(item,index) in listData" :key="index">
          <div class="pic">
            <img :src="item.basicThumbnails || '/resource/404.jpeg'" alt="">
          </div>
          <div class="detail-right">
            <div class="detail-top">
              <p class="time">{{time(item.contentShowTime?item.contentShowTime:item.basicDateTime)}}</p>
              <a class="title" :href="item.articleLinkURL">{{item.basicTitle}}</a>
            </div>
            <div class="txt">{{item.basicDescription}}</div>
          </div>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
  export default {
    props:{
      listData:{
        type: Array,
        default: ()=>[]
      }
    },
    methods:{
      time(val){
        return dayjs(val).format('YYYY/MM/DD')
      }
    }
  }
</script>

<style lang="less" scoped>
.cooperation-list {
  .cooperation-list-item {
    position: relative;
    padding: 20px 50px 30px 185px;
    min-height: 75px;
    .pic {
      width: 122px;
      height: 80px;
      position: absolute;
      left: 50px;
      overflow: hidden;
      img {
        width: 122px;;
      }
    }
    .detail-right {
      .detail-top {
        margin-bottom: 5px;
        border-bottom: 1px dashed #ccc;
        .time {
          font-size: 12px;
          color: #A0A0A0;
        }
        .title {
          font-size: 16px;
          color: #2453a4;
        }
      }
      .txt {
        font-size: 12px;
        line-height: 20px;
        color: #575757;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>