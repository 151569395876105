import req from '../../req/index';
import utils from "../../utils/index";

export default {
  namespaced: true,
  state: {
    bookData: {},
    thePaperData:{},
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async getBookData({ commit }) {
      let res = await req.ajax({
        url:'/static/generate/getArticles/169/2/normal',
        method:'get'
      })

      commit('setBookData', {
        moreLink: res.moreLink,
        list: res.articleVOS,
        baseUrl:res.baseUrl
      })

      return res;
    },
    async getThePaperData({ commit }) {
      let res = await req.ajax({
        url:'/static/generate/getArticles/167/7/allTop',
        method: "get"
      })

      commit('setThePaperDataData', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      return res;
    },
  },
  mutations: {
    setBookData(state, data) {
      state.bookData = data;
    },
    setThePaperDataData(state, data) {
      state.thePaperData = data;
    },
  },
}