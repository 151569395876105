<template>
  <div>
    <ul class="en-content-list">
      <li class="en-content-list-item" v-for="item in list.list" :key="item.name">
        <div class="title">
          {{item.columnVO.columnKeyword}}
        </div>
        <div class="content">
          {{item.columnVO.columnDescrip}}
        </div>
        <div class="more">
          <a :href="item.urL">READ MORE&emsp;></a>
        </div>
      </li>
      <News />
      <content-1 :link="list2.moreLink"></content-1>
    </ul>
  </div>
</template>

<script>
import News from "../news/index.vue"
import Content1 from "./components/content1.vue"
import { mapActions, mapState } from 'vuex'
  export default {
    data() {
      return {
      }
    },
    components:{
      Content1,
      News
    },
    computed:{
      ...mapState("en_content",["list","list2"])
    },
    serverPrefetch(){
      return Promise.all([this.initList(),this.initList2()])
    },
    mounted(){
      console.log(this.list)
      if(process.env.NODE_ENV==="development"){
        this.initList()
        this.initList2()
      }
    },
    methods:{
      ...mapActions("en_content",["initList","initList2"])
    }
  }
</script>

<style lang="less" scoped>
.en-content-list {
  height: 374px;
  display: flex;
  position: relative;
  .en-content-list-item {
    width: 260px;
    height: 304px;
    padding: 70px 30px 0;
    vertical-align: top;
    border-left: 1px solid #d2d1d1;
    .title {
      color: #1515bf;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 30px;
      height: 80px
    }
    .content {
      font-size: 12px;
      color: #6ec5e7;
      margin-bottom: 30px;
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .more {
      a {
        display: inline-block;
        width: 115px;
        background-color: #285ef8;
        color: white;
        padding: 5px;
        font-size: 14px;
        text-decoration: none;
        &:hover {
          color: aqua !important;
        }
      }
    }
    &:nth-child(1){
      background: url("../../../../assets/index_1.jpg") center no-repeat;
    }
    &:nth-child(2){
      background: url("../../../../assets/index_2.jpg") center no-repeat;
      
    }
    &:nth-child(3){
      background: url("../../../../assets/index_3.png") center no-repeat;
      
    }
  }
}
</style>