<template>
  <div>
    <ul class="list3" :id="cust">
      <li v-for="(item,index) in lists" :key="index" >
        <a :href="value.name ==='科情学术'? replaceUrl(item.link) : item.link" class="title">{{item.name}}</a>
        <span class="time" v-if="value.name === '科情学术'">{{item.xsdt_time}}</span>
        <span class="time" v-else-if="value.name === '科技热点'">{{item.kjrd_time}}</span>
        <span class="time" v-else>{{item.time}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props:{
      lists:{
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        cust: this.value?.value ?? null,
      }
    },
    computed:{
      replaceUrl:()=>{
        return url => url.replace('/245/','/227/243/245/')
      }
    },
    mounted() {
      // console.log(this.value);
    },
  }
</script>

<style lang="less" scoped>
  .list3 {
    li {
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      margin-bottom: 20px;
      a {
        display: inline-block; 
        // width: 242px;
        width: calc(100% - 70px );
        color: #333;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time {
        color: #999;
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
      }
    }
  }
  #keji {
    display: flex;
    height: 280px;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
    li {
      width: calc(50% - 38px);
    }
  }
</style>