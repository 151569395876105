<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/profileList"/>
    <profile-list :listData="articleVOList"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileList from "./component/profileList.vue"
  export default {
    components:{
      profileList
    },
    data() {
      return {

      }
    },
    computed:{
      ...mapGetters("detail",["articleVOList"])
    }   
  }
</script>

<style lang="less" scoped>

</style>