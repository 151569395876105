import req from '../../req/index';
import utils from '../../utils';

export default {
  namespaced: true,
  state: {
    newsList: {},
  },
  actions: {
    async getNewList({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/575/7/normal",
        method: "get"
      })
      commit('setNewList', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS),
        columnVO: res.columnVO
      })
      
      return res;
    },
  },
  mutations: {
    setNewList(state,data){
      state.newsList = data
    }
  },
}