import req from '../../req/index';
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    dataList:null
  },
  // getters:{
    
  // },
  actions: {
    async cmsStatus({commit , state}){
      let datahref = ''
      if(typeof window === Object){
        datahref = window.location.href
      }
      let res = await req.ajax({
        url: "/cms/status",
        method: "post",
        data: {
          requestUrl: datahref
        }
      })

      commit('setlist',{
        ...res
      })
      return res
    }
  },
  mutations: {
    setlist(state, data){
      state.dataList = JSON.parse(data.safePage)
    }
  },
}