<template>
  <div class="searchBox">
    <div class="left">
      <div class="cb-btn">
        <span>战略规划</span>
      </div>
      <div class="info">
        <dl>
          <dt class="dt1">
            <span class="info-desc">
              <a :href="infoListOne.moreLink">{{
                (infoListOne.list &&
                  infoListOne.list[0] &&
                  infoListOne.list[0].name) ||
                "null"
              }}</a>
            </span>
          </dt>
        </dl>
        <b></b>
        <dl>
          <dt class="dt2">
            <span class="info-desc">
              <a :href="infoListTwo.moreLink">{{
                (infoListTwo.list &&
                  infoListTwo.list[0] &&
                  infoListTwo.list[0].name) ||
                "null"
              }}</a>
            </span>
          </dt>
        </dl>
        <b></b>
        <dl class="dl3">
          <dt class="dt3">
            <span class="info-desc">
              <a :href="infoListThree.moreLink">{{
                (infoListThree.list &&
                  infoListThree.list[0] &&
                  infoListThree.list[0].name) ||
                "null"
              }}</a>
            </span>
          </dt>
        </dl>
      </div>
    </div>
    <form class="search" ref="searchXSDataForm" :action="action" method="post">
      <input
        type="text"
        placeholder="学术资源搜索"
        class="search-txt"
        ref="search-txt"
        name="queryString"
        v-model="queryString"
      />
      <input type="text" value="搜索" class="search-btn" @click="search" />
      <!-- <input type="button" value="搜索" class="search-btn" @click="search" /> -->

      <input type="hidden" id="pageSize" name="pageSize" value="20" />
      <input type="hidden" id="pageNumber" name="pageNumber" value="1" />
      <input type="hidden" id="searchOrg" name="searchOrg" value="" />
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // action: `${process.env.VUE_APP_BASE_API}/cms/literaturesearch/search`,
      queryString: ""
    };
  },
  computed: {
    ...mapState("search", ["infoListOne", "infoListTwo", "infoListThree"]),
    ...mapState("footer", ["list"]),
    action(){
      let url = this.list.appUrl + "/cms/literaturesearch/search";

      return url;
    }
  },
  serverPrefetch() {
    return Promise.all([
      this.getInfoListOne(),
      this.getInfoListTwo(),
      this.getInfoListThree(),
    ]);
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.getInfoListOne();
      this.getInfoListTwo();
      this.getInfoListThree();
    }
  },

  methods: {
    ...mapActions("search", [
      "getInfoListOne",
      "getInfoListTwo",
      "getInfoListThree",
    ]),
    search() {
      if(!this.queryString) return;
      let form = this.$refs.searchXSDataForm;

      form.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 100%;
  height: 91px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    width: calc(100% - 390px);
    .cb-btn {
      width: 120px;
      height: 42px;
      text-align: center;
      background-color: #327cc0;
      span {
        color: #fff;
        line-height: 42px;
        display: inline-block;
        width: 100%;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 6px solid #fff;
          margin: 0px 0px 1px 8px;
        }
      }
    }
    .info {
      display: flex;
      width: calc(100% - 120px);
      margin-left: 10px;
      b {
        display: inline-block;
        width: 0;
        height: 40px;
        border-right: 1px dashed #0565c9;
        margin: 0 23px;
      }
      dl {
        height: 40px;
        max-width: calc(25% - 5px);
        dt {
          width: 100%;
          .info-desc {
            width: calc(100% - 33px);
            display: table-cell;
            vertical-align: middle;
            padding-left: 39px;
            height: 40px;
            a {
              display: inline-block;
              color: #333333;
              font-size: 14px;
            }
          }
        }
        .dt1 {
          .info-desc {
            background: url("../../../../assets/zlgh1.svg") no-repeat;
            background-size: 26px;
            background-position-y: center;
          }
        }
        .dt2 {
          .info-desc {
            background: url("../../../../assets/zlgh2.svg") no-repeat;
            background-size: 26px;
            background-position-y: center;
          }
        }
        .dt3 {
          .info-desc {
            background: url("../../../../assets/zlgh3.svg") no-repeat;
            background-size: 26px;
            background-position-y: center;
          }
        }
      }
      .dl3 {
        max-width: 33% !important;
      }
      @media screen and(-ms-high-contrast: active),(-ms-high-contrast:none){
        .dl3 {
          max-width: 40% !important;
        }
      } 
    }
  }
  .search {
    // width: calc(100% - 800px);
    width: 390px;
    border: 1px solid #327cc0;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-txt {
      border: none;
      height: 37px;
      padding-left: 10px;
      font-size: 14px;
      outline: none;
      width: calc(100% - 110px);
    }
    .search-btn {
      border: none;
      background-color: #327cc0;
      color: #fff;
      width: 90px;
      height: 39px;
      font-size: 14px;
      position: relative;
      left: 1px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>