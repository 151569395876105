<template>
  <div class="fullBox sTab">
    <div :class="`sTab-header ${value.length===1?'sTab-header-one':''}`">
      <div class="sTab-header-left">
        <span class="sTab-header-left-icon flexYCenter">
          <slot name="icon"></slot>
        </span>

        <div class="tabList">
          <div v-for="item in value" 
              :key="item.value" @click="onClick(item)" 
              :style="{paddingLeft: item.paddingSize, paddingRight: item.paddingSize,cursor: value.length > 1? 'pointer':'default',color:item.color}" 
              :class="`tabItem ${cust===item.value && value.length>1?'tabItemSel':''}`" 
            >
            {{item.name}}
          </div>
        </div>
      </div>

      <div class="sTab-header-right">
        <a @click="onMore(cust)" :href="moreLink">More <span class="iconR1"></span></a>
      </div>
    </div>

    <div class="stab-content" :id="cust">
      <slot :name="cust"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /* 
      [
        {
          name: '新闻动态',
          value: 'news',
        }
      ]
    */
    value: {
      type: Array,
      default: () => [],
    },
    moreLink:{
      type: String,
      default: ' '
    }
  },
  data() {
    return {
      cust: this.value[0]?.value ?? null,
    };
  },
  mounted() {},
  methods: {
    onMore(data){
      // 抛出cust
      this.$emit("custMore",data)
    },
    onClick(item){
      this.cust=item.value;
    }
  },
};
</script>

<style lang="less" scoped>
.sTab-header {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sTab-header-left {
    display: flex;
    align-items: center;
    .sTab-header-left-icon {
      width: 54px;
      height: 46px;
      background: url("../../assets/bgIcon1.svg") no-repeat;
      position: relative;
      top: 1px;
      padding-left: 10px;
      position: relative;
    }
  }
  .sTab-header-right a {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    margin-right: 20px;
  }
}
.sTab-header-one{
  height: 44px;
  border-bottom: 2px solid #337cbf;
}
.stab-content {
  width: calc(100% - 40px);
  height: calc(100% - 85px);
  overflow: hidden;
  padding: 20px;
}
.iconR1{
  display: inline-block;
  position: relative;
  top: 0px;
  width: 6px;
  height: 8px;
  background: url("../../assets/iconR3.svg") no-repeat;
  background-size: cover;
}

.tabList{
  display: flex;
  height: 37px;
  line-height: 35px;
  margin-left: 10px;
  .tabItem{
    font-size: 16px;
    color: #333;
    font-weight: bold;
    padding:  0 15px;
    border-top: 2px solid #fff;
  }
  .tabItemSel{
    color: #327cc0 !important;
    border-top: 2px solid #327cc0;
    border-radius: 2px 2px 0 0;
    position: relative;
    padding-bottom: 43px;
    background: #fff;
    &::after {
      content: '';
      width: 9px;
      height: 39px;
      background-color: #fff;
      border-left: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      border-bottom-left-radius: 11px;
      position: absolute;
      right: -9px;
      top: 0px;
      box-shadow: 0px 10px 0 0 #fff;
    }
    &::before {
      content: '';
      width: 9px;
      height: 39px;
      background-color: white;
      border-right: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      border-bottom-right-radius: 11px;
      position: absolute;
      left: -9px;
      top: 0px;
      box-shadow: 0px 10px 0 0 #fff;
    }
  }
}
#paper {
  margin-bottom: 0px;
  padding-bottom: 20px;
}
</style>