import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/en/layout/index.vue'
import Home from '../views/en/views/home/index.vue'
import Detail from "../views/en/views/detail/index.vue"
import EditorTitleTime from "../views/en/views/detail/views/editorTitleTime/index.vue"
import Editor from "../views/en/views/detail/views/editor/index.vue"
import TopicsTwo from '../views/en/views/detail/views/topicsTwo/index.vue'
import News from "../views/en/views/detail/views/news/index.vue"
import Contact from "../views/en/views/detail/views/contact/index.vue"
import GlobalMou from "../views/en/views/detail/views/globalMou/index.vue"
import Laboratory from "../views/en/views/detail/views/laboratory/index.vue"
import topicsConstruction from "../views/en/views/detail/views/topicsConstruction/index.vue" 
Vue.use(VueRouter)

export let comMapEn = {
  /* 官网 */
  "en": {
    "layout": Layout,
    "/": Home,
    "detail":Detail,
    "editor":Editor,
    "news":News,
    'editorTitleTime':EditorTitleTime,
    "topicsTwo": TopicsTwo,
    "globalMou":GlobalMou,
    "laboratory":Laboratory,
    "contact":Contact,
    "topicsConstruction":topicsConstruction
  }
}

const routes = [
  {
    path: '/en',
    name: "en",
    component: Layout,
    children: [{
      path: '/',
      /* 首页 */
      name: 'Home',
      component: Home
    },
    {
      path: "detail",
      name: "detail",
      component: Detail,
      children: [
        {
          path: 'editor',
          /*富文本*/
          name: 'editor',
          component: Editor,
        },
        {
          path: 'editorTitleTime',
          /*富文本*/
          name: 'editorTitleTime',
          component: EditorTitleTime,
        },

        {
          path: "news",
          name: "news",
          component:News
        },
        {
          path:'contact',
          name:'contact',
          component:Contact
        },
        {
          path: 'topicsTwo',
          name: 'topicsTwo',
          component: TopicsTwo
        },
        {
          path:'globalmou',
          name:'globalMou',
          component:GlobalMou
        },
        {
          path:'laboratory',
          name:'laboratory',
          component:Laboratory
        },
        {
          path: 'topicsConstruction',
          name: 'topicsConstruction',
          component: topicsConstruction
        }
      ]
    }
  ]
  },
]

export default routes;