<template>
  <div class="w100 flexCenter links-Box">
    <div class="dsMainBox dsMainBoxda">
      <a v-for="item in list" :key="item.id" :href="item.link">
        <img :src="item.img" alt="">
      </a>
    </div>
  </div>
</template>

<script>
// import fl1 from "../../../../assets/link1.png"
import fl1 from '../../../../assets/xhw.jpg'
import fl2 from '../../../../assets/rmw.jpg'
import fl3 from '../../../../assets/zfw.jpg'
// import fl2 from "../../../../assets/link2.png"
// import fl3 from "../../../../assets/link3.png"

export default {
  data() {
    return {
      list: [
      {
          id: "1",
          img: fl1,
          link: "http://www.xinhuanet.com/politics/cpc20/index.htm"
        },
        {
          id: "2",
          img: fl2,
          link: "http://cpc.people.com.cn/20th/"
        },
        {
          id: "3",
          img: fl3,
          link: "http://www.gov.cn/zhuanti/zggcddescqgdbdh/index.htm"
        },
        
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.links-Box{
  // height: 78px;
  .dsMainBoxda{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
    // height: 78px;
    img{
      display: block;
      width: 387px;
      // height: 120px;
      height: auto;
    }
  }
}
</style>