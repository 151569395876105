import Vue from 'vue'
import Vuex from 'vuex'
import _ from "lodash"

const context = require.context('./modules/', true, /\.js$/);

Vue.use(Vuex)

export function createStore() {
  let stores = {};

  for (const key of context.keys()) {
    const aKeys = key.split('/')
    const sVueFile = aKeys[aKeys.length - 1]
    let sToreName = [sVueFile].join('.').replace(/\.js$/g, '')
    stores[sToreName] = context(key).default
  }

  let store = new Vuex.Store(_.cloneDeep({modules: stores}));
  return store
  // return _.cloneDeep(store)
}
