import req from "../../req/index";
import utils  from "../../utils/index";

export default {
  namespaced: true,
  state:{
    youthData:{},
  },
  actions:{
    async getYouthData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/555/7/normal',
        method: "get"
      });
      commit("setYouthData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
  },  
  mutations:{
    setYouthData(state,data) {
      state.youthData = data;
    },
  }
}
