<template>
  <div class="w100 flexCenter">
    <input type="hidden" name="pageRouter" value="meeting20/">
    <div class="dsMainBoxda">
      <div class="dsHomeContentBox box-top">
        <div class="dsHomeContentItem1 item-l">
          <Carousel />
        </div>

        <div class="dsHomeContentItem1 item-r">
          <SpiritWork />
        </div>
      </div>

      <div class="dsHomeContentBox">
        <div class="dsHomeContentItem item-l">
          <DangweiWork />
        </div>

        <div class="dsHomeContentItem item-r">
          <Dynamic />
        </div>
      </div>

      <div class="dsHomeContentBox">
        <div class="dsHomeContentItem item-l">
          <Learn />
        </div>

        <div class="dsHomeContentItem item-r">
          <DataCenter />
        </div>
      </div>

      <Links/>
    </div>
  </div>
</template>

<script>
import Carousel from "./components/carousel/index.vue" /* 顶部轮播 */
import Links from "./components/links/index.vue"/* 底部超链 */
import SpiritWork from "./components/spiritWork/index.vue" /* 中央精神&&科技部工作 */
import DangweiWork from "./components/dangweiWork/index.vue" /* 所党委工作 */
import Dynamic from "./components/dynamic/index.vue" /* 基层党组织动态 */
import Learn from "./components/learn/index.vue" /* 青年理论学习 */
import DataCenter from "./components/dataCenter/index.vue" /* 资料中心 */

export default {
  data() {
    return {};
  },
  components: {
    Links,
    Carousel,
    SpiritWork,
    DangweiWork,
    Dynamic,
    Learn,
    DataCenter,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less">
@import url("../../styles/home.less");
</style>

<style lang="less" scoped>
// .dsMainBox{
  // background: #fff;
// }
.margintop {
  margin-top: 10px;
}
.dsHomeContentBox{
  // width: calc(100% - 30px);
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dsHomeContentItem{
    width: 590px;
    height: 345px;
  }
  .dsHomeContentItem1{
    width: 590px;
    height: 395px !important;
  }
}
// .box-top{
//   .item-l{
//     width: 590px;
//     height: 395px;
//   }
//   .item-r{
//     width: 590px;
//     height: 345px;
//   }
// }
.margin-top{
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>