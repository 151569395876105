<template>
  <div class="w100 flexCenter">
    <detail-banner />
    <div class="dsMainBox">
      <div class="w100 ds-detail-top">
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import DetailBanner from "./components/detailBanner/index.vue"
import { mapActions, mapMutations } from 'vuex';
export default {
  data() {
    return {};
  },
  components:{
    DetailBanner
  },
  serverPrefetch() {
    this.initSource()
    return this.initData();
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      this.initSource()
      await this.initData();
    }
  },

  methods: {
    ...mapActions("en_detail", ["initData"]),
    ...mapMutations("en_detail",["setSource"]),
    initSource(){
      const router = this.$route;
      const roujoin = router.path.split('/')
      // console.log(rou.slice(rou.length-1).join(''))
      // if(router.path === "/detail/postgraduatenews"){
      //   this.setSoure('postgraduatenews')
      // }else if(router.path === "/detail/strategic"){
      //   this.setSoure('strategic')
      // } else{
        this.setSource(roujoin.slice(roujoin.length-1).join(''))
      // }
      }
  },
};
</script>

<style lang="less" scoped>
.flexCenter {
  display: flex;
  flex-direction: column;
}
.dsMainBox{
  background: #fff;
  min-height: 200px;
  width: calc(1220px - 20px) !important;
}
</style>