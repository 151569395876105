import req from "../../req/index"
import utils  from "../../utils/index"

export default {
	namespaced: true,
	state:{
		thinkData:{},
		thinkLink:{}
	},
	actions:{
		async getThinkData({ commit }){
			let res = await req.ajax({
				url: "/static/generate/getArticles/539/7/normal",
				method: "get"
			})
			commit("setThinkData",{
					moreLink: res.moreLink,
					list: utils.initHomeList(res.articleVOS)
				})
				return res
			},
			async getThinkLink({ commit }){
				let res = await req.ajax({
					url: "/static/generate/getArticles/540/0/normal",
					method: "get"
				})
				commit("setThinkLink",{
						moreLink: res.moreLink,
						list: utils.initHomeList(res.articleVOS)
					})
					return res
				},
	},  
	mutations:{
		setThinkData(state,data) {
			state.thinkData = data
		},
		setThinkLink(state,data) {
			state.thinkLink = data
		}
	}
}
