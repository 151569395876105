<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="en/detail/editor"/>
    <div v-html="detailData.articleContent"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed:{
    ...mapGetters("en_detail",["detailData"])
  },

  methods: {},
};
</script>

<style lang="less" scoped>
/deep/ p {
  padding: 10px 15px;
  margin:  16px 0;
  text-indent: 35px;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  &:hover {
    background-color: #EBEBEB;
  }
}
</style>