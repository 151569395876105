<template>
    <div class="istlcca_box">
       <input type="hidden" name="pageRouter" value="detail/istlcca" />
        <div class="istlcca_top">
            <div>
                <img :src="detailData.basicThumbnails" alt="">
            </div>
            <div class="istlcca_topLeft">
                <div>{{detailData.basicTitle}}</div>
                <div class="istlcca_topLefttwo">{{postDetailData(detailData) ? postDetailData(detailData).introduce.fieldData : ''}}</div>
                <div class="istlcca_topLefttwo">
                    <span>相关链接：</span>
                    <a :href="postDetailData(detailData)?postDetailData(detailData).url.fieldData:''" target="_blank">{{postDetailData(detailData)?postDetailData(detailData).url.fieldData:''}}</a>
                </div>
            </div>
        </div>
        <div class="istlcca_center">
            <div>{{postDetailData(detailData) ? postDetailData(detailData).target.fieldTipsName:''}}</div>
            <div>{{postDetailData(detailData) ? postDetailData(detailData).target.fieldData:''}}</div>
        </div>
        <div class="istlcca_bottom">
            <img src="../../../../assets/sfyy.png" alt="">
            <div class="istlcca_bottomtext">
                <div v-html="postDetailData(detailData) ? postDetailData(detailData).jobContent.fieldData:''"></div>
                <div v-html="postDetailData(detailData) ? postDetailData(detailData).researRst.fieldData:''"></div>
                <div v-html="postDetailData(detailData) ? postDetailData(detailData).pilot.fieldData:''"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters('detail',['detailData'])
  },
  methods: {
    postDetailData(val) {
      return val.modelData ? val.modelData : ''
    }
  }
}
</script>

<style lang="less" scoped>


.istlcca_box {
    padding: 0 20px;
    .istlcca_top {
        height: 180px;
        display: flex;
        img {
            width: 250px;
            height: auto;
        }
        .istlcca_topLeft {
            margin-left: 10px;
            color: #666666;
            div:nth-child(1) {
                font-size: 16px;
                font-weight: bold;
                line-height: 32px;
            }
            .istlcca_topLefttwo {
                font-size: 14px;
                line-height: 32px;
                margin-top: 20px;
                span {
                    color: blue;
                    // margin-right: 0px;
                }
                a {
                    color: #2e2e2e;
                }&hover {
                    color: #327CC0 !important;
                }
            }
        }
    } 
    .istlcca_center {
        width: 935px;
        margin-top: 40px;
        height: 100px;
        background: url('../../../../assets/jsmb.png');
        background-size: 100% 100%;
        display: flex;
        div:nth-child(1) {
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            color: white;
            font-size: 15px;
            font-weight: bold;
            margin-left: 3px;
        }
        div:nth-child(2) {
            width: 795px;
            margin-left: 20px;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
            margin-top: 5px;
        }
    }
    .istlcca_bottom {
        margin-top: 40px;
        img {
            width: 935px;
        }
        .istlcca_bottomtext {
            margin-top: 10px;
            display: flex;
            // justify-content: space-around;
            div {
                height: 100%;
                flex: 1;
                border-right: 1px solid #dbd6d6;
                padding: 0px 10px;
                line-height: 32px;
                color: #666666;
                font-size: 14px;
            }
            div:last-child {
                border-right: none;
            }
        }
    }
}
</style>
