<template>
  <div>
    <div class="pagesdetailBox">
        <input type="hidden" name="pageRouter" value="detail/pagesdetail"/>
        <div class="pagesdetail_header">{{detailData.basicTitle}}</div>
        <div class="pagesdetail_content" v-if="detailData.articleContent">
          <span>摘要：</span>
          <span v-html="detailData.articleContent"></span>
        </div>
        <ul v-if="detailData.modelData">
            <!-- <li v-for="(item,index) in dataList" :key="index">
                <span>{{item.name}}:</span>
                <span>{{item.title}}</span>
            </li> -->
            <li>
                <span>关键词：</span>
                <span>{{detailData.articleKeyword}}</span>
            </li>
            <li>
                <span>作者：</span>
                <span>{{detailData.articleAuthor}}</span>
            </li>
            <li>
                <span>作者单位：</span>
                <span>{{detailData.modelData?detailData.modelData.auth_wunit.fieldData:''}}</span>
            </li>
            <li>
                <span>刊名：</span>
                <span>{{detailData.modelData?detailData.modelData.publicatio.fieldData:''}}</span>
            </li>
            <li>
                <span>出版年：</span>
                <span>{{detailData.modelData?detailData.modelData.pub_year.fieldData:''}}</span>
            </li>
            <li>
                <span>卷：</span>
                <span>{{detailData.modelData?detailData.modelData.roll.fieldData:''}}</span>
            </li>
            <li>
                <span>期：</span>
                <span>{{detailData.modelData?detailData.modelData.periodical.fieldData:''}}</span>
            </li>
            <li>
                <span>页码：</span>
                <span>{{detailData.modelData?detailData.modelData.page_num.fieldData:''}}</span>
            </li>
        </ul>
        
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            // dataList:[{name:'关键词',title:this.detailData.articleKeyword},'作者','作者单位','刊名','出版年','卷','期','页码']
        }
    },
    computed: {
        ...mapGetters('detail',['detailData']),
    },
}
</script>

<style lang="less" scoped>
.pagesdetailBox {
    padding: 0 20px;
    font-size: 18px;
    .pagesdetail_header {
        color: #0067b7;
        font-weight: bold;
        width: 100%;
    }
    .pagesdetail_content {
        font-size: 14px;
        color: #0d0d0d;
        width: 100%;
        margin: 20px 0px;
        line-height: 26px;
        /deep/ p {
            display: inline-block;
        }
    }
    ul {
        li {
            display: flex;
            font-size: 14px;
            margin: 20px 0;
            span:nth-child(1) {
                display: block;
                color: #686868;
                font-weight: bold;
                width: 100px;
            }
            span:last-child {
                color: #0067b7;
            }
        }
    }
}
</style>