import req from '../../req/index';
import utils from "../../utils/index";

export default {
  namespaced: true,
  state: {
    newsData: {},
    informData:{},
    partyNewsData:{}
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async getNewsData({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/338/5/firstTop",
        method: "get"
      })

      commit('setNewsData', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      return res;
    },
    async getInformData({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/339/5/allTop",
        method: "get"
      })

      commit('setInformData', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      return res;
    },
    async getPartyNewsData({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/551/5/firstTop",
        method: "get"
      })

      commit('setPartyNewsData', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      return res;
    },
  },
  mutations: {
    setNewsData(state, data) {
      state.newsData = data;
    },
    setInformData(state, data) {
      state.informData = data;
    },
    setPartyNewsData(state, data) {
      state.partyNewsData = data;
    },
  },
}