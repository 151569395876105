<template>
  <div>
    <ul class="writings-list">
      <li class="writing-list-item" v-for="(item,index) in listData" :key="index">
        <div class="pic">
          <img :src="item.basicThumbnails || '/resource/404.jpeg'">
        </div>
        <div class="detail-right">
          <p class="title">{{item.basicTitle}}</p>
          <div class="article-detail">
            <div class="article-detailBox">
              <div class="author">作者：{{item.articleAuthor}}</div>
              <div class="publishers">出版机构：{{item.modelData.pub_org.fieldData}}</div>
            </div>
            <div class="article-detailBox">
              <div class="department">
                部门：<a :href="JumpUrl(baseUrl,item.modelData ? item.modelData.deptLink.fieldData:'')">{{item.modelData.department.fieldData}}</a>
              </div>
              <div class="time publishers">出版时间：{{item.modelData.fini_time.fieldData}}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <page v-if="pageInfo" :pageInfo="pageInfo"/>
  </div>
</template>

<script>
import Page from "../../../../../components/Page/index.vue"
import {JumpUrl} from '../../../../../utils/urlpath'
  export default {
    props:{
      listData:{
        type: Array,
        default: ()=>[]
      },
      pageInfo:{
        type:Object,
        default:()=>{}
      },
      baseUrl:{
        type:String,
        default: () => ""
      }
    },
    components:{
      Page
    },
    methods:{
      JumpUrl
    },
  }
</script>

<style lang="less" scoped>
.writings-list{
  margin-bottom: 30px;
  .writing-list-item {
    padding: 40px 0 0 185px;
    min-height: 184px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #efefef;
    .pic {
      width: 114px;
      height: 114px;
      position: absolute;
      top: 41%;
      left: 50px;
      margin-top: -34.5px;
      overflow: hidden;
      img {
        display: block;
        margin: 0 auto;
        height: 114px;
        max-width: 114px;
      }
    }
    .detail-right {
      font-size: 14px;
      .title {
        border-left: solid 0px #7AC6FD;
        color: #327CC0;
        padding-left: 0px;
        font-size: 18px;
        font-weight: 600;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .article-detail {
        color: #464646;
        margin-top: 24px;
        .author,
        .department {
          // display: inline-block;
          width: 65%;
        }
        .article-detailBox {
          display: flex;
          // width: 900px;
        }
        .department {
          margin: 20px 0 35px;
          a {
            color: #464646;
          }
        }
        .time {
          margin: 20px 0 35px;
        }
        .publishers {
          margin-left: 80px;
          width: 35%;
        }
      }
    }
  }
}
</style>