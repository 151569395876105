<template>
  <div>
    <ul class="list">
      <li v-for="(item,index) in dataList" :key="index" :class="`list-item ${dynamicClass}`">
        <a :href="item.link" target="_blank">{{item.name}}</a>
        <span class="time">{{item.ds_time}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props:{
      dataList:{
        type: Array,
        default:()=>[]
      },
      dynamicClass:{
        type: String,
        default: ""
      }
    },
  }
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 14px;
      display: inline-block;
      padding-left: 15px;
      width: calc(100% - 107px);
      text-overflow: ellipsis;
      background: url("../../assets/100icon.png") no-repeat left center;
      white-space:nowrap;
      overflow: hidden;
    }
    .time {
      margin-right: 15px;
      font-size: 12px;
    }
  } 
}
  .list-item-1 {
    padding: 5px;
    a {
      color: #454545;
      &:hover {
        color: #454545 !important;
      }
    }
    .time {
      color: #686868;
    }
}

.list-item-2 {
  padding: 5px 0;
  margin: 5px;

    a {
      color: #222;
      &:hover {
        color: #222 !important;
      }
    }
    .time {
      color: #c1c1c1;
    }
  }
</style>