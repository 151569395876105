import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    data: {}
  },
  actions: {
    async initData({commit}) {
      let res = await req.ajax({
        url: '/static/generate/getDjHeader',
        method: 'post',
        data: {
          ids: ['1158898879362982305'],
          type: "son"
        }
      })
      
      commit('setData', res)
      return res
    }
  },
  mutations: {
    setData(state,data) {
      state.data = data
    }
  }
}