<template>
  <div class="w100 detailBanner">
    <!-- <div class="banner-body" :style="{'backgroundImage':'url('+ selectPicture +')'}"> -->
    <div :class="`banner-body ${selectPicture}`" >
      <div class="crumb">
        <crumb />
      </div>
    </div>
  </div>
</template>

<script>
import crumb from "../crumbs/index.vue"
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      
    };
  },
  components:{
    crumb
  },
  computed:{
    ...mapGetters("en_detail", ["crumbsList"]),
    selectPicture(){
      let currentPage = this.crumbsList[0]?.title
      let dataList = {
        'About ISTIC':'bg1',
        'What we do':'bg2',
        'Global network': 'bg3',
        'Contact': 'bg4',
        'Topics':'bg2',
      }
      return dataList[currentPage]
    }
  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
.detailBanner{
  height: auto;
  .banner-body{
    width: 100%;
    min-width: 1200px;
    height: auto;
    margin: 0 auto;
    -webkit-background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center 0;
    .crumb {
      width: 1200px;
      padding-top: 11%;
      margin: 0 auto;
    }

  }
}
.bg1{
  background: url("../../../../assets/banner_about.jpg") no-repeat;
}
.bg2{
  background: url("../../../../assets/banner_wedo.png") no-repeat;
}
.bg3{
  background: url("../../../../assets/banner_gloabal.png") no-repeat;
}
.bg4{
  background: url("../../../../assets/banner_contact.png") no-repeat;
}
</style>