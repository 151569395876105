<template>
  <div>
    <topTitle :title="title"></topTitle>
    <!-- <slot name="titleBox"></slot> -->
    <div class="slot_box" v-if="curColumnVO">{{curColumnVO.columnDescrip?curColumnVO.columnDescrip:''}}</div>
    <div class="cont_box">
      <div class="box_layer" :class="dataStyle.box_layer" v-for="(item,index) in dataList" :key="index">
        <div>
          <a :href="dataStyle.box_layer === 'box_layer2' ? 'javascript:viod(0)':baseUrl + item.linkUrl">
            <img :class="dataStyle.box_img" :src="item.basicThumbnails" alt=""/>
          </a>
        </div>
        <div :class="dataStyle.box_content">{{item.basicTitle}}</div>
        <div class="cont_box">{{item.basicDescription}}</div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import topTitle from '../../components/topTitle/index.vue'
  // import {map}
  export default {
    components: {
      topTitle
    },
    props: {
      title: {
        type: '',
        default: ()=>''
      },
      dataList:{
        type: Array,
        default:()=>[]
      },
      dataStyle: {
        type: Object,
        default:()=>{}
      },
      curColumnVO: {
        type: Object,
        default: ()=>{}
      },
      baseUrl: {
        type: String,
        default: ()=>''
      }
    },
  }
  </script>
  
  <style lang="less" scoped>
  
    
    .cont_box {
      width: 100%;
      margin-top: 10px;
      display: flex;

      // text-align: center;
      .box_layer1 {
        padding: 15px 20px;
      }

      .box_layer2 {
        padding: 20px 20px;
      }

      .box_layer {
        width: 30%;

        div:nth-child(1) {
          font-size: 16px;
          text-align: center;
          margin: 18px 0;

          .box_img1 {
            width: 70%;
            // height: 222px;
          }

          .box_img2 {
            width: 100%;
            height: 222px;
          }
        }

        div:nth-child(2) {
          width: 100%;
          font-size: 20px;
          border: none;
          font-weight: bold;
          padding-bottom: 20px;
          text-align: center;
          height: 73px;
        }
      }
    }

    .box_layer:hover {
      background: rgba(235, 235, 235);
    }

    .box_content {
      border-bottom: 1px solid #c1c1c1 !important;
    }

    .cont_box {
      font-size: 16px;
      padding: 16px 0;
    }

    .slot_box {
      text-indent: 35px;
      line-height: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      // text-align: center;
      text-align: justify;
          }
    
  </style>