<template>
  <div class="carouselBox">
    <input type="hidden" name="pageRouter" value="dangqun/detail/detailmore" />
    <div class="swiperDom">
      <div ref="swiperDom">
        <div class="swiper-wrapper fullBox">
          <div class="swiper-slide" v-for="item in articleVOList" :key="item.id">
            <div>
              <img class="" :src="item.basicThumbnails" alt="" />
            </div>
            <div class="des-pop"><span class="des-pop-a" href="">{{ item.basicTitle }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-btn swiper-button-prev-home"></div>
    <div class="swiper-btn swiper-button-next-home"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 50000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-prev-home",
          prevEl: ".swiper-button-next-home",
        },
      },
    };
  },
  computed:{
    ...mapGetters("dq_detail", ["articleVOList"])
  },
  mounted() {
    setTimeout(()=>{
      this.init();
    },500)
  },

  methods: {
    // ...mapActions("dq_carousel", ["initList"]),
    init() {
      let swiper =  window.Swiper;
      new swiper(this.$refs.swiperDom, this.swiperOption);
    },
  },
};
</script>

<style lang="less" scoped>
.carouselBox{
  top: 30px;
  left: 90px;
  overflow: hidden;
  position: relative;
  width: 800px !important;
  height: 560px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swiperDom {
  width: 100%;
  height: 450px;
  /deep/.swiper-container-initialized {
    border: 1px solid #D8D8D8;
  }
}
.swiper-slide {
  position: relative;
  width: 100%;
  height: 450px;
  img{
    max-height: 450px;
    max-width: 796px;
  }
  .des-pop {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px !important;
    background: #3B3B3B;
    bottom: 0;
    left: 0;
    overflow: hidden;
    // opacity: 0.5;
  }
  .des-pop-a{
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    width: 800px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .des-pop-a:hover{
    color: #fff !important;
  }
}
.swiper-pagination{
  // display: inline-block;
  display: flex;
  justify-content: space-between;
  width: 200px;
  position: relative !important;
  top: 30px;
  /deep/.swiper-pagination-bullet{
    width: 16px;
    height: 16px;
    background: #59baa8;
    opacity: 1;
    // margin-left: 15px;
  }
  /deep/.swiper-pagination-bullet-active{
    background:#e7433b;
  }
}
.swiper-btn {
    display: inline-block;
    position: absolute;
    z-index: 1;
    background: url('../../../../../../assets/sprite.png');
    bottom: 63px;
    // top: 30px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .swiper-button-next-home {
    left: 280px; 
  }
  .swiper-button-prev-home {
    // background: url('../../../../../../assets/sprite.png');
    right: 280px;
    // transform: rotate(angle);
    transform: rotate(180deg);
  }
</style>