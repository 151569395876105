<template>
<div>
  <input type="hidden" name="pageRouter" value="en/detail/topicsTwo"/>
  <!-- <topTitle :title="title"></topTitle> -->
  <listImg :baseUrl="baseUrl" :title="title" :dataList="encolumns" :dataStyle="dataStyle"></listImg>
</div>
</template>

<script>
import topTitle from '../../components/topTitle/index.vue'
import listImg from '../../components/listImg/index.vue'
import { mapGetters } from 'vuex'
// import {map}
export default {
  components: {
    topTitle,
    listImg
  },
  computed: {
    ...mapGetters('en_detail',['encolumns','baseUrl'])
  },
  mounted() {
    console.log(this.encolumns)
  },
  data() {
    return {
      title: 'Topics',
      dataStyle: {
        box_img:'box_img1',
        box_layer: 'box_layer1'
      }
    }
  },

}
</script>

<style lang="less" scoped>
// .cont_box {
//   width: 100%;
//   margin-top: 10px;
//   display: flex;
//   .box_layer {
//     padding: 50px 20px;
//     width: 30%;
//     div:nth-child(1){
//       font-size: 16px;
//       text-align: center;
//       margin: 18px 0;
//       img{
//         width: 70%;
//       }
//     }
//     div:nth-child(2){
//       width: 100%;
//       font-size:20px;
//       border: none;
//       font-weight: bold;
//       padding-bottom: 40px;
//       text-align: center;
//     }
//   }
// }
// .box_layer:hover {
//   background: rgba(235,235,235);
// }
</style>