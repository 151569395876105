<template>
  <div class="w100">
    <div class="news-list">
      <div class="news-title">
        <div 
          v-for="item in value" 
          :key="item.name" 
          @click="onClick(item)" 
          :class="`news-title-item ${cust === item.value && value.length > 1 ? 'news-title-item-active' : ' '}`" 
          :id="item.value" 
        > 
          {{item.name}}
        </div>
      </div>

      <div class="more-link">
        <a @click="onMore(cust)" :href="moreLink">更多 <img src="@/assets/arrow-dj-more-white.svg" alt=""></a>
      </div>
    </div>

    <div class="stab-content" :id="cust">
      <slot :name="cust"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    value:{
      type: Array,
      default: () => []
    },
    moreLink:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      cust: this.value[0]?.value ?? null
    };
  },
  mounted() {},
  methods: {
    onMore(data){
      this.$emit("custMore",data)
    },
    onClick(item) {
      this.cust = item.value
    }
  },
};
</script>

<style lang="less" scoped>
  .news-list {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px 0 20px; 
    background: url("~@/assets/jingshen.png")  no-repeat 100% 100%;
    .news-title {
      display: flex;
      .news-title-item {
        margin: 0 5px;
        width: 106px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
      .news-title-item-active {
        color: #B20B0C;
        border-bottom: 3px solid #B20B0C;
      }
    }
    .more-link {
      a {
        display: flex;
        font-size:14px;
        color: #fff;
        &:hover {
          color: #FFF !important;
        }
        img {
          margin-left: 5px;
        }
      }
    }
  }
  
</style>
