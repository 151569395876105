<template>
  <div class="linkBtn fullBox" @mousemove="showSel=true" @mouseleave="showSel=false">
    <span class="linkTitle">{{label}}</span>
    <span class="icon-arrow-bottom"></span>

    <div class="selBox" v-show="showSel">
      <a v-for="(item,index) in list" :key="index" :href="item.link">
        <p v-html="item.name"></p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    label:{
      type: String,
      default: ()=>("")
    },
    list: {
      type: Array,
      default: ()=>[]
    }
  },
  data() {
    return {
      showSel: false,
    };
  },
};
</script>

<style lang="less" scoped>
.linkBtn {
  text-align: center;
  color: #666;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}
.linkBtn:hover {
  .linkTitle {
    color: #fff;
  }
  background: #327cc0;
  .icon-arrow-bottom {
    width: 18px;
    height: 9px;
    display: inline-block;
    background: url("../../../../assets/arrow-bottom-w.png") no-repeat ;
    background-size: cover;
    -webkit-print-color-adjust: exact;
  }
}
.icon-arrow-bottom {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  width: 18px;
  height: 9px;
  background: url("../../../../assets/arrow-bottom.png") no-repeat;
  -webkit-print-color-adjust: exact;
  background-size: cover;
}

.selBox {
  background: #fff;
  max-height: 132px;
  overflow-y: auto;
  a {
    display: block;
    width: 100%;
    padding: 10px 0;
    min-height: 20px;
    line-height: 1.3em;
    text-align: center;
    font-size: 14px;
    border-bottom: dashed 1px #f3f3f3;
    color: #2e2e2e;
    .desBox {
      line-height: 1em;
    }
  }
  a:hover {
    background: #fafafa;
  }
}
</style>