import req from '../../req/index';
import utils from "../../utils/index";

export default {
  namespaced: true,
  state: {
    kejiData: {},
    ziyuanData: {},
    hangyeData: {},
    xuehuiData: {},
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async getData({
      commit
    }, data) {
      /* 
        data = {
          url,
          key
        }
      */
      let res = await req.ajax({
        url: `/static/generate/getArticles/${data.url}`,
        method: "get"
      })

      commit('setData', {
        key: data.key,
        data: {
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
        }
      })

      return res;
    },
  },
  mutations: {
    setData(state, data) {
      state[data.key] = data.data;
    },
  },
}