<template>
  <div class="fullBox oneStopBox">
    <h3>一站服务</h3>

    <div class="oneStopBox-list">
      <a v-for="item in list.list" :key="item.id" :href="item.extArticleUrl">
        <img :src="item.image"/>
        <span>{{item.name}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },

  computed:{
    ...mapState("oneStop", ["list"])
  },
  serverPrefetch(){
    return this.initList();
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.initList()
    }
  },

  methods: {
    ...mapActions("oneStop", ["initList"]),
  },
};
</script>

<style lang="less" scoped>
.oneStopBox{
  background: #f2f2f2;
}

h3{
  height: 37px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #1c69b3;
  line-height: 37px;
  border-bottom: 2px solid #dcdcdc;
}

.oneStopBox-list{
  width: 100%;
  a{
    display: block;
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    padding: 10px 5px;
    border-bottom: 1px solid #dcdcdc;
    img{
      width: 17px;
      height: auto;
      margin-right: 5px;
    }
    span{
      color: #666666;
      font-size: 14px;
      line-height: 14px;
      width: calc(100% - 22px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>