import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state: {
    learnData:{}
  },
  actions: {
    async getlearnData({commit}) {
      let res = await req.ajax({
        url: '/static/generate/getArticles/433/7/normal',
        method: 'get'
      })
      // console.log(res)
      commit('setlearnData',{
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      });
      return res
    }
  },
  mutations: {
    setlearnData(state,data){
      state.learnData = data
    }
  }
}