import req from '../../req/index';
import utils from '../../utils/index';

export default {
  namespaced: true,
  state: {
    /* 
    {
      columnId 栏目id
      articleId 详情页id
    }
     */
    query: {},
    /* 
      {
        private Integer contentType; 1 只显示内容 * 2 显示标题+内容 * 3 显示标题+时间+内容
        private Integer isPaging; 是否分页  0 否 1 是
        topColumnTitle:string 栏目标题
        topRemark:string 英文描述
        subColumnVOList:[ 左侧菜单数据
          {
            subCategoryListVOList 子菜单
          }
        ]
        crumbsList: 面包屑
        columnAdImg 上边banner背景图片
        articleVOList 内容
      }
    */
    data: {},
  },
  getters:{
    baseUrl(state){
      return state.data.baseUrl || ''
    },
    topId(state){
      return state.data.topId || ""
    },
    topColumnTitle(state){
      return state.data.topColumnTitle || ""
    },
    topRemark(state){
      return state.data.topRemark || ""
    },
    subColumnVOList(state){
      let list = state.data.subColumnVOList || []
      return list
    },
    crumbsList(state){
      return state.data.crumbsList || []
    },
    columnAdImg(state){
      return state.data.columnAdImg || "/resource/404.jpeg"
    },
    articleVOList(state){
      return state.data.articleVOList || []
    },
    detailData(state){
      return (state.data.articleVOList || [])[0] || {}
    },
    pageInfo(state){
      return state.data.pageInfo
    },
    dqList(state) {
      let dqList = utils.initHomeList(state.data.articleVOList||[])
      return dqList
    }
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initData({ commit, state }) {
      let res = await req.ajax({
        url: "/static/regenerate/generateColumnAndArticle",
        method: "post",
        data: {
          ...state.query||{},
          source: "dj100"
        }
      })
      
      commit('setData', {
        ...res,
      })
      return res;
    },
  },
  mutations: {
    setQuery(state, data){
      state.query = data || {};
    },
    setData(state, data) {
      state.data = data;
    },
  },
}