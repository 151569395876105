<template>
  <div class="w100 flexCenter">
    <input type="hidden" name="pageRouter" value="dangshi/"/>
    <div class="dsMainBox">
      <div class="dsHomeContentBox box-top mb40">
        <div class="dsHomeContentItem item-l">
          <Carousel />
        </div>

        <div class="dsHomeContentItem item-r">
          <SpiritWork />
        </div>
      </div>

      <div class="dsHomeContentBox mb40">
        <div class="dsHomeContentItem item-l">
          <DangweiWork />
        </div>

        <div class="dsHomeContentItem item-r">
          <Dynamic />
        </div>
      </div>

      <div class="dsHomeContentBox mb40">
        <div class="dsHomeContentItem item-l">
          <Learn />
        </div>

        <div class="dsHomeContentItem item-r">
          <DataCenter />
        </div>
      </div>

      <Links class="mb40"/>
    </div>
  </div>
</template>

<script>
import Carousel from "./components/carousel/index.vue" /* 顶部轮播 */
import Links from "./components/links/index.vue"/* 底部超链 */
import SpiritWork from "./components/spiritWork/index.vue" /* 中央精神&&科技部工作 */
import DangweiWork from "./components/dangweiWork/index.vue" /* 所党委工作 */
import Dynamic from "./components/dynamic/index.vue" /* 基层党组织动态 */
import Learn from "./components/learn/index.vue" /* 青年理论学习 */
import DataCenter from "./components/dataCenter/index.vue" /* 资料中心 */

export default {
  data() {
    return {};
  },
  components: {
    Links,
    Carousel,
    SpiritWork,
    DangweiWork,
    Dynamic,
    Learn,
    DataCenter,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less">
@import url("../../styles/home.less");
</style>

<style lang="less" scoped>
.dsMainBox{
  background: #fff;
}
.dsHomeContentBox{
  width: calc(100% - 30px);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dsHomeContentItem{
    width: 49.5%;
    height: 223.5px;
  }
}
.box-top{
  .item-l{
    width: 500px;
    height: 290px;
  }
  .item-r{
    width: 457px;
    height: 290px;
  }
}
</style>