<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/strategic" />
    <detailcom :detailData="detailData">
      <div slot="text" class="strategic_box">
        <!-- <div>{{data.columns}}</div> -->
        <div class="strategic_img" :class="data.columns.length <= 6 ? 'strategic_img1' : 'strategic_img2'" v-for="(item , index) in data.columns" :key="index">
          <a :href="baseUrl + item.linkUrl" target="_blank">
             <img :src="item.categorySmallImg" alt=""/>
             <div>{{item.categoryTitle}}</div>
          </a>
        </div>
      </div>
    </detailcom>
  </div>
</template>

<script>
import detailcom from '../../components/detailcom/index.vue'
import { mapGetters ,mapMutations, mapState} from 'vuex';
export default {
  components: {
    detailcom
  },
  mounted() {
    // this.setSoure('strategic')
  },
  computed: {
    ...mapGetters('detail',['detailData','baseUrl']),
    ...mapState('detail',['data'])
  },
  methods: {
    ...mapMutations('detail',['setSoure'])
  }
}
</script>

<style lang="less" scoped>
.strategic_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  .strategic_img {
    // width: 32%;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    line-height: 25px;
    color: #337ab7 !important;
    flex-direction: column;
    font-size: 14px;
    a {
      color: #337ab7 !important;
    }
    div {
      width: 150px;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
  .strategic_img1 {
    width: 32%;
  }
  .strategic_img2 {
    width: 23%;
  }
}
</style>