<template>
  <div>
    <div class="bd-top">
      <div class="pic">
        <img :src="firstList.image" class="image">
      </div>
      <div class="info">
        <a :href="firstList.link" class="name">{{firstList.name}}</a>
        <p class="breakLine">{{firstList.content}}</p>
      </div>  
    </div>
    <div class="bd-bottom">
      <list-1 :lists="lastLists" :value="value"></list-1>
    </div>
  </div>
</template>

<script>
import List1 from "./list1.vue";
  export default {
    props:{
      lists:{
        type: Array,
        default:()=>[]
      },
      value:{
        type: Object,
        default:()=>{}
      }
    },
    components:{
      List1
    },
    computed:{
      firstList(){
        return this.lists[0] || {}
      },
      lastLists(){ 
        const lastLists = this.lists.slice(1)
        return lastLists
      }
    }
  }
</script>

<style lang="less" scoped>
.bd-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .pic {
    width: 210px;
    height: 140px;
    .image {
      width: 100%;
      height: 140px;
    }
  }
  .info {
    width: calc(100% - 230px);
    height: 140px;
    .name {
      display: inline-block;
      color: #333333;
      width: 100%;
      overflow: hidden;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 18px;
    }
    .breakLine {
      display: -webkit-box;
      font-size: 14px;
      line-height: 25px;
      height: 78px;
      font-weight: 400;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>