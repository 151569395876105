<template>
  <div class="noPageBox">
    <!-- <h3>对不起，您访问的页面不存在</h3> -->
    <img src="../../assets/404.png" alt="">
    <h3 class="noPageBoxOne">无法找到页面</h3>
    <div class="noPageBoxTwo">你使用的URL可能拼写有误，该页可能已经移动，或者可能只是临时脱机。</div>
    <div class="noPageBoxThree">
      <a href=""><p class="noPageBoxP noPageBoxPone">刷新该页</p></a>
      <p>or</p>
      <a :href="list.baseUrl"><p class="noPageBoxP noPageBoxPtwo">返回首页</p></a>
    </div>
  </div>
</template>


<script>
import { mapActions,mapState } from 'vuex';
export default {
  name: 'CmsNodeTemplateIndex',
  data() {
    return {

    };
  },
  computed: {
    ...mapState('footer',['list'])
  },
  serverPrefetch() {
    return this.initList()
  },
  mounted() {
    this.initList()
  },
  methods: {
    ...mapActions('footer',['initList'])
  },
};
</script>
<!-- <style>
body {
  background: #E4E7EA !important;
  padding: 5% 0!important;
}
</style> -->
<style lang="less" scoped>
.noPageBox {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  background: #E4E7EA;
  padding-top: 5.5%;


  // h3{
  //   font-size: 24px;
  //   text-align: center;
  //   margin: 200px 0;
  // }
  .noPageBoxOne {
    line-height: 40px;
    font-size: 40px;
    color: #333;
    padding: 40px 0;
  }

  .noPageBoxTwo {
    margin: 0px auto 20px auto;
    line-height: 35px;
    font-size: 20px;
    color: #999;
  }

  .noPageBoxThree {
    display: flex;
    justify-content: center;

    p:nth-child(2) {
      margin: 0 29px;
    }
  }

  .noPageBoxP {
    padding: 0 20px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
    line-height: 30px;
    color: #fff;
    border-radius: 3px;
  }

  .noPageBoxPone {
    background-color: #BDC3C7;
  }

  .noPageBoxPtwo {
    background-color: #3498DB;
  }
}
</style>