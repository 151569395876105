<template>
  <div class="stabBox">
    <STab v-model="list" @custMore="thisMore" :moreLink="moreLink">
      <template slot="icon">
        <span class="icon"></span>
      </template>
      <template slot="keji">
        <list-1 v-if="!isShowTwo" :lists="scienceData.list" :value="list[0]"></list-1>
        <list-3 :lists="scienceDataMore.list" v-else :value="list[0]"></list-3>
      </template>
    </STab>
  </div>
</template>

<script>
import STab from "../../../../components/STab/index.vue";
import List1 from "../../../../components/STab/list1.vue";
import List3 from "../../../../components/STab/list3.vue";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      list: [
        {
          name: "科技热点",
          value: "keji",
          paddingSize: '12px'
        },
      ],
      moreLink: "",
      isShowTwo: true,
    };
  },
  components: {
    STab,
    List1,
    List3,
  },
  serverPrefetch() {
    return Promise.all([this.getScienceData(), this.getScienceDataMore()]);
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.getScienceData();
      this.getScienceDataMore();
    }
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculate);
  },
  computed: {
    ...mapState("science", ["scienceData", "scienceDataMore"]),
  },
  methods: {
    ...mapActions("science", ["getScienceData", "getScienceDataMore"]),
    thisMore(data) {
      if (data === "keji") {
        this.moreLink = this.scienceData.moreLink;
      }
    },
    init() {
      this.calculate();

      window.addEventListener("resize", this.calculate);
    },
    calculate() {
      if (document.body.clientWidth <= 1660) {
        this.isShowTwo = false;
      } else {
        this.isShowTwo = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.stabBox {
  width: 100%;
  height: 100%;
  background: #fff;
}
.icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url("../../../../assets/kjrd.svg") no-repeat;
  background-size: cover;
}
</style>