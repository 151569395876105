<template>
  <div class="fullBox bgcard">
    <card :cardData="cardData" :moreLink="scienceWorkData2.moreLink"/>
    <listpublic :dataList="scienceWorkData2.list" :backImg="backImg"/>
  </div>
</template>

<script>
import Card from "../../../../components/card2/index.vue"
import { mapActions, mapState,mapMutations } from 'vuex';
import listpublic from '../../../../components/listpublic'
export default {
  data() {
    return {
      // value:{
      //   name:'基层党组织动态',
      //   dynamicClass:'list-item-2',
      //   value:"dynamic"
      // }
      cardData: {
        title:'所党委工作',
        value: 'dynamic'
      },
      backImg:{ backImg:'listData_bakimg1'},
    };
  },
  components:{
    Card,
    listpublic
  },
  serverPrefetch(){
    this.initId()
    return this.getScienceWorkData2()
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.initId()
      this.getScienceWorkData2()
    }
  },
  computed:{
    ...mapState("da_spiritWork",["centralSpiritData","scienceWorkData2"])
  },
  methods: {
    ...mapActions("da_spiritWork",["getScienceWorkData2"]),
    ...mapMutations("da_spiritWork",['setId','setPage']),
    initId(){
      this.setId('1158898879362982405')
      this.setPage('6')
    }
  },
};
</script>

<style lang="less" scoped>
</style>