<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/postgraduatenews" />
    <research :articleVOList="articleVOList" :content="content"/>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import research from '../../components/research/index.vue'
export default {
  components: {
    research
  },
  data() {
    return {
      content: {
        styleclass: 'research_content'
      }
    }
  },
  // serverPrefetch() {
  //   return this.setSoure('postgraduatenews')
  // },
  // created() {
  //   this.setSoure('postgraduatenews')
  // },
  computed: {
    ...mapGetters('detail',['articleVOList']),
  },
  // methods: {
  //   ...mapMutations('detail',['setSoure'])
  // }

}
</script>

<style>

</style>