<template>
  <div v-if="crumbsList && crumbsList[1]">
    <input type="hidden" name="pageRouter" value="en/detail/laboratory"/>
    <top-title :title="crumbsList[1].title"></top-title>
    <div class="content">
      <div class="content-body">
        <div class="content-list-global">
            <div class="list-img-global">
              <img src="../../../../assets/2.png" alt="" usemap="#imgmap"/>
              <map name="imgmap" id="imgmap">
                <area shape="circle" coords="577,737,66" @click="sendVal(lists[3])"/>
                <area shape="circle" coords="94,214,66" @click="sendVal(lists[5])"/>
                <area shape="circle" coords="568,74,66" @click="sendVal(lists[4])"/>
                <area shape="circle" coords="1012,204,66" @click="sendVal(lists[2])"/>
                <area shape="circle" coords="231,626,66" @click="sendVal(lists[0])"/>
                <area shape="circle" coords="951,635,66" @click="sendVal(lists[1])"/>
              </map>
            </div>
            <div v-for="item of lists" :key="item.id" class="list-item-global" :style="item.location" @click="sendVal(item)">
                <div class="list-title-global">{{item.name}}</div>
                <div class="list-desp-global" style="font-size:14px;">{{item.introduction}}</div>
            </div>
        </div>
        <laboratory-detail :value="value" @closeDetail="close" v-if="isShow"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LaboratoryDetail from "./component/laoratoryDetail.vue"
import TopTitle from "../../components/topTitle/index.vue"
  export default {
    components:{
      TopTitle,
      LaboratoryDetail
    },
    computed:{
      ...mapGetters("en_detail",["crumbsList"])
    },
    data() {
      return {
        lists:[
          {
            id:'textnubmer1',
            // src:require('../../../../assets/sy2.png'),
            name:'ISTIC-ELSEVIER',
            txt:`In September 2012, ISTIC and Elsevier jointly established the “ISTIC-ELSEVIER Journal Evaluation Research
                Center”.<br/>Through the joint efforts of both parties, the Joint Center actively explores the model of full-text data mining of journals, discusses the evaluation methods
                of journals, promotes the construction of innovative countries in China, and makes due contributions to the academic research in the field of scientific publishing
                evaluation. The laboratory sets up a research fund to attract researchers from well-known domestic universities to participate in the research, and invite famous overseas
                scholars to give speeches and exchange at ISTIC, under the global influence of Elsevier.`,
            location:'top: -391px;left: 180px;',
            introduction:'In September 2012, ISTIC and Elsevier jointly established the “ISTIC-ELSEVIER Journal Evaluation Research Center”.'
          },
          {
            id:'textnubmer2',
            // src:require('../../../../assets/sy4.jpg'),
            name:'ISTIC-MI',
            txt:`In October 2010, ISTIC and the Millennium Institute of the United States (MI) established the “ISTIC-MI Joint
                Center”.<br/>The Joint Center focuses on economics, energy, environment, water resources, population, and employment, to conduct sustainability research based on system
                dynamics models to enhance ISTIC’s capabilities for system dynamics, T21 models, and related activities, to find and complete projects on a global scale and help MI promote
                and complete the T21 model in neighboring Asian countries to provide T21 or system dynamics training courses for Chinese or international clients.`,
            location:'top: -385px;left: 640px;',
            introduction:'In October 2010, ISTIC and the Millennium Institute of the United States (MI) established the “ISTIC-MI Joint Center”.'
          },
          {
            id:'textnubmer3',
            // src:require('../../../../assets/sy5.png'),
            name:'ISTIC-T&F',
            txt:`In October 2018, ISTIC and the Taylor & Francis Group established the “ISTIC - Taylor & Francis Group Joint Lab of
                Academic Frontier Observation”.<br/>The Joint Lab will provide a wealth of data resources, information analysis tools and open research funds to provide academic
                development platforms and exchange opportunities for domestic technology managers and researchers. The laboratory will help the two sides to strengthen cooperation research
                between institutions, achieve resource sharing and complementary advantages, disseminate and utilize scientific and technological information resources more efficiently and
                widely.`,
            location:'top: -809px;left:450px;',
            introduction:'In October 2018, ISTIC and the Taylor & Francis Group established the “ISTIC - Taylor & Francis Group Joint Lab of Academic Frontier Observation”.'
          },
          {
            id:'textnubmer4',
            // src:require('../../../../assets/sy3.png'),
            name:'ISTIC-EBSCO',
            txt:`In June 2016, ISTIC and EBSCO of the United States jointly established the “ISTIC-EBSCO Joint Laboratory of
                Literature Big Data Discovery Services”.<br/>The Joint Lab aims to promote literature big data discovery services and promote academic exchanges. The laboratory sets up an
                open fund to collect subjects for social researchers, and to attract researchers of the field to conduct research by funding research directions and topics of great
                research significance. In the future, the lab will continue to conduct comprehensive research on discovery services, Altmetrics, and user log mining, and actively promote
                the development of resource discovery and knowledge services in the new information environment.`,
            location:'top: -20px;left: -255px;width: 357px;',
            introduction:'In June 2016, ISTIC and EBSCO of the United States jointly established the “ISTIC-EBSCO Joint Laboratory of Literature Big Data Discovery Services”.'
          },
          {
            id:'textnubmer5',
            // src:require('../../../../assets/sy1.png'),
            name:'ISTIC-CLARIVATE',
            txt:`In November 2008, ISTIC and Onex Corporation (formerly Thomson Reuters Intellectual Property and Technology
                Division) established the “ISTIC-CLARIVATE ANALYTICS Joint Laboratory of Scientometrics”.<br/>The Joint Lab aims to strengthen academic exchanges, conduct basic theories,
                techniques and applied research related to scientometrics, and explore the development direction of the discipline. At the same time, it provides a platform for academic
                exchanges and collaborative research for researchers in the field of scientometrics in China, including abundant literature resources, information analysis tools and the
                open funds of the Joint Lab. It has effectively promoted the development of scientometrics in China and has become a high-end platform for exchanges and learning among
                researchers in the field of scientometrics at home and abroad.`,
            location:'top: -1084px;left:485px;width:317px;',
            introduction:'In November 2008, ISTIC and Onex Corporation (formerly Thomson Reuters Intellectual Property and Technology Division) established the “ISTIC-CLARIVATE ANALYTICS Joint Laboratory of Scientometrics”.'
          },
          {
            id:'textnubmer6',
            // src:require('../../../../assets/sy6.jpg'),
            name:'ISTIC-SN',
            txt:`In July 2021, ISTIC and Springer Nature established the “ISTIC-Springer Nature Joint Laboratory for Open
                Science”.<br/>The Joint Lab aims to conduct researches on open science based on the resources provided by both parties. And within the framework of the ISTIC-SN Lab and
                based on both parties relevant resources, the ISTIC-SN Lab endeavors to provide academic development platforms and exchange opportunities for science and technology
                administrators, researchers and developers, the public, and scientific communication professionals, improving academic influences of both parties.`,
            location:'top: -942px;left:-250px;',
            introduction:'In July 2021, ISTIC and Springer Nature established the “ISTIC-Springer Nature Joint Laboratory for Open Science”.'
          },
        ],
        isShow:false,
        value:{},
        index: ''
      }
    },
    methods:{
      sendVal(val){
        this.value = val
        this.isShow = true
        // console.log(val);
      },
      close(val) {
        this.isShow = val
      }
    }
  }
</script>

<style lang="less" scoped>
.list-img-global {
	text-align: center;
	margin-top: 160px;
}
.list-item-global {
	width: 250px;
	position: relative;
	cursor: pointer;
	display: inline-block;
  .list-title-global {
    font-size: 22px;
    color: #005ca1;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .list-desp-global {
	  color: #6e6b6b;
  }
}
#imgmap {
  area {
    cursor: pointer;
  }
}
</style>