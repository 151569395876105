import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    list: {},
    background:{}
  },
  actions: {
    // 获取英文网站背景图
    async backgroundPicture({ commit }) {
     
      let res = await req.ajax({
        url: "/static/generate/getArticles/566/2/normal",
        method: "get"
      })
      commit('setBackground', res)
      
      return res;
    },
    async initList({ commit }) {
     
      let res = await req.ajax({
        url: "/static/generate/getArticles/569/3/slide",
        method: "get"
      })
      commit('setList', res)
      
      return res;
    },
  },
  mutations: {
    setBackground(state, data) {
      state.background = data;
    },
    setList(state,data){
      state.list = data
    }
  },
}