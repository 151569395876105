<template>
  <div class="fullBox">
    <Card :value="value" :moreLink="academicLink.moreLink">
      <template slot="icon">
        <span class="icon"></span>
      </template>

      <div>
        <list-3 :lists="academicData.list" :value="value"></list-3>
      </div>
    </Card>
  </div>
</template>

<script>
import List3 from "../../../../components/STab/list3.vue"
import Card from "../../../../components/STab/card.vue"; 
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      value:{
        name: '科情学术',
        enName: 'Information disclosure',
        left: '-26px'
      }
    };
  },
  components:{
    Card,
    List3
  },
  computed:{
    ...mapState("academic",['academicData','academicLink'])
  },
  serverPrefetch(){
    return Promise.all([this.getAcademicData(),this.getAcademicLink()])
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.getAcademicData()
      this.getAcademicLink()
    }
  },

  methods: {
    ...mapActions('academic',['getAcademicData','getAcademicLink']),
  },
};
</script>

<style lang="less" scoped>
.icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  background: url("../../../../assets/box-xsdt.svg") no-repeat;
  background-size: cover;
}
</style>