<template>
  <div class="w100 crumbs_box flexCenter">
    <div class="dq_crumbs">
      <i class="ico-local"></i>
      <span class="local">&nbsp;当前位置：</span>
      <span v-for="(item,index) in crumbsList" :key="index" class="crumbs-item"> 
        <a :href="item.link" v-if="index !== crumbsList.length - 1" class="crumbs-item-title">{{item.title}}</a>
        <span v-else class="crumbs-item-title">{{item.title}}</span>
        <img src="../../../../assets/local-right.svg" alt="" v-if="index !== crumbsList.length - 1">
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("da_detail",["crumbsList"])
    },
  }
</script>

<style lang="less" scoped>
.dq_crumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1180px;
  color:#666666;
  .ico-local {
    width: 12px;
    height: 16px;
    background: url("~@/assets/local.svg") no-repeat center;
  }
  .local {
    font-size: 14px;
  }
  .crumbs-item {
    .crumbs-item-title {
      font-size: 14px;
      color: #666666;
      &:hover {
        color: #666 !important;
      }
    }
    img {
      margin: 0 5px;
    }
  }
  .crumbs-item:nth-last-child(-n+2) {
    .crumbs-item-title {
      color: #830201 !important;
      &:hover {
        color: #830201 !important;
      }
    }
  }
}
</style>