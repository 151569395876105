import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/meeting20/layout/index.vue'
import Home from '../views/meeting20/views/home/index.vue'
import Detail from '../views/meeting20/views/detail/index.vue'
import CentralSpirit from '../views/meeting20/views/detail/views/centralSpirit/index.vue'
import Editor from '../views/meeting20/views/detail/views/editor/index.vue'
Vue.use(VueRouter)

export let comMapDA = {
  /* 官网 */
  "meeting20": {
    "layout": Layout,
    "/": Home,
    "detail":Detail,
    "centralSpirit":CentralSpirit,
    "editor":Editor
  }
}

const routes = [
  {
    path: '/meeting20',
    name: "meeting20",
    component: Layout,
    children: [{
      path: '/',
      /* 首页 */
      name: 'Home',
      component: Home
    },{
      path:'detail',
      name: 'detail',
      component:Detail,
      children:[
        {
          path: 'centralSpirit',
          name: 'centralSpirit',
          component: CentralSpirit
        },{
          path:'editor',
          name: 'editor',
          component: Editor
        }
      ]
    }]
  },
]

export default routes;
