<template>
    <div class="paperBox">
        <div v-for="(item,index) in articleVOList" :key="index" class="paperContent" :class="papersection.enrollment?'borderdottednone':'borderdotted'">
            <div class="paperContentLeft" :class="!papersection.sandt?'paperContentLeftWidth':'paperContentLeftWidthtwo'">
                <span class="paperContentLeft_span" style="color:#327CC0;margin-left: 10px;" v-if="papersection.sandt">{{item.basicTitle}}</span>
                <a :href="item.articleLinkURL" v-else>{{ item.basicTitle }}</a>
            </div>
            <div class="color666" :class="!papersection.sandt? 'paperContentRight':'paperContentRightTwo'" v-if="!papersection.enrollment">
                <span :class="!papersection.sandt?'paperContentSpan':''" >
                    <span>{{papersection.publicatio}}:</span>
                    <!-- <span>{{item.modelData.department.fieldData}}</span> -->
                    <span v-if="!papersection.sandt">{{item.modelData?item.modelData.publicatio.fieldData:''}}</span>
                    <span v-else>
                        <a :href="item.modelData?url(item.modelData.deptLink.fieldData,baseUrl):''">{{item.modelData?item.modelData.department.fieldData:''}}</a>
                    </span>
                    <div v-if="!papersection.sandt" class="paperContentSpan_hover">{{ item.modelData?item.modelData.publicatio.fieldData:'' }}</div>
                </span>
                <span class="papersection_tiembox" :class="!papersection.sandt?'paperContentSpan paperContentSpanwidth':'papersection_tiembox'">
                    <span :class="papersection.sandt ? 'papersection_tiem':''">{{papersection.pub_year}}:</span>
                    <span v-if="!papersection.sandt">{{item.modelData?item.modelData.pub_year.fieldData:''}}</span>
                    <span v-else class="papersection_tiemtwo">{{item.modelData?item.modelData.fini_time.fieldData:''}}</span>
                </span>
                <!-- <span :class="!papersection.sandt?'paperContentSpan':''">
                    <span>{{papersection.pub_year}}:</span>
                    <span v-if="!papersection.sandt">{{item.modelData?item.modelData.pub_year.fieldData:''}}</span>
                    <span v-else>{{item.modelData?item.modelData.fini_time.fieldData:''}}</span>
                </span> -->
            </div>
            <div v-else class="iconimgbasi">
                <div style="margin:3px;">
                    <a :href="item.articleLinkURL" v-if="papersection.img"><img style="width:12px;height: 15px;" src="../../../../assets/gif_download.gif" alt=""/></a>
                </div>
                <span class="color666">{{'[' + time(item.contentShowTime?item.contentShowTime:item.basicDateTime) + ']'}}</span>
            </div>
        </div>
        <div class="page">
          <Page :pageInfo="pageInfo" v-if="pageInfo"/>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs"
import Page from '../../../../components/Page/index.vue'
export default {
    name: 'scientificresearch',
    components: {
      Page
    },
    props: {
        papersection: {
            type: Object,
            default: () => {}
        },
        articleVOList: {
            type: Array,
            default: () => []
        },
        pageInfo: {
          type: Object,
          default: () => {}
        },
        baseUrl: {
          type: String,
          default: ()=>''
        }
    },
    data() {
        return {}
    },
    methods: {
        time(val){
            return dayjs(val).format('YYYY-MM-DD')
        },
        url(val,baseUrl) {
          if(val) {
            if(val.indexOf('http'||'https') !== -1){
              return val
            }else{
              return baseUrl + val
            }
          }else {
            return
          }
          // if(val.indexOf('http')){
          //   return val
          // }
          // return baseUrl + val
        }
    }
}
</script>

<style lang="less" scoped>

.paperContentLeftWidth {
    width: 530px;
}
.paperContentLeftWidthtwo {
    width: 535px;
}
.borderdotted {
    border-bottom: dotted 1px #8A8A8A;
    padding: 12px 0;
}
.borderdottednone {
    padding: 7px 0;
}
.color666 {
    color: #666;
}
.paperBox {
    padding: 0 20px;
    .paperContent {
        height: 20px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        .paperContentLeft {
          border-left: 2px solid rgba(50,124,192,0.9);
          .paperContentLeft_span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
            a {
                margin-left: 5px;
                display: block;
                color: #327CC0 !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            a:hover {
                text-decoration:underline;
                color: #327CC0;
            }
        }
        .paperContentRight {
            width: 390px;
            // color: #666;
            display: flex;
            justify-content: space-between;
            .paperContentSpan {
              // display: block;
							// position: relative;
							width: 275px;
							display: inline-block;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							padding-left: 10px;
							span:nth-child(1){
									margin-right: 5px;
							}
            }
            .paperContentSpanwidth {
              width: 100px !important;
            }
            .paperContentSpan_hover {
              position: absolute;
              // width: 40px;
              // height: 20px;
              display: block;
              border: 1px solid black;
              padding: 2px 10px;
              font-size: 12px;
              right: 160px;
              z-index: 999;
              background: #fff;
              display: none;
            }
            .paperContentSpan:hover .paperContentSpan_hover {
							display: block;
            }
        }
        .paperContentRightTwo {
            width: 390px;
            // color: #666;
            display: flex;
            justify-content: space-between;
        }
    }
}
a {
    margin-left: 5px;
    color: #327CC0 !important;
    &:hover{
        text-decoration: underline;
        color: #327CC0 !important;
    }
}
.iconimgbasi {
    // width: 120px;
    display: flex;
}
.papersection_tiembox {
  display: flex;
  .papersection_tiem {
    display: block;
    width: 95px;
  }
  .papersection_tiemtwo {
    display: block;
    width: 82px;
    margin-left: 10px;
  }
}
.page {
  margin: 20px 0;
}
@media print{
  .iconimgbasi{
    width: 140px;
  }
}
</style>