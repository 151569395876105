<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="en/detail/editorTitleTime"/>
    <div class="title">{{detailData.basicTitle}}</div>
    <div class="time">{{time(detailData.contentShowTime?detailData.contentShowTime:detailData.basicDateTime)}}</div>
    <div class="content" v-html="detailData.articleContent"></div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("en_detail",["detailData"])
    },
    methods:{
      time(val){
        if(val){
          return dayjs(val).format("YYYY-MM-DD")
        }
        return ''
      }
    }
  }
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  color: #272727;
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑,Microsoft YaHei;
  margin-bottom: 12px;
  margin-top: 20px;
  line-height: 30px;
}
.time {
  text-align: center;
  font-size: 14px;
  color: #525252;
  font-family: 微软雅黑,Microsoft YaHei;
  letter-spacing: 2px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  padding: 0 6px;
}
/deep/ .content{
  text-indent: 35px;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  p {
    margin: 16px 0;
  }
}
</style>