<template>
  <div class="w100">
     <card-1 :value='cardData' @custMore="receiveCust" :moreLink="moreLink">
      <template slot="centralSpirit">
        <list-public :dataList="centralSpiritData.list" :backImg="backImg" />
      </template>
      <template slot="scienceWork">
        <list-public :dataList="scienceWorkData.list" :backImg="backImg" />
      </template>
     </card-1>
    
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import card1 from '../../../../components/card1/index.vue'
import listPublic from '../../../../components/listpublic/index.vue'
export default {
  data() {
    return {
      backImg:{backImg: 'listData_bakimg2'},
      cust:'',
      moreLink:'',
      cardData: [
        {name: '中央精神',value: 'centralSpirit'},
        {name:'科技部工作',value:'scienceWork'}
      ], 
   };
  },
  components: {
    card1,
    listPublic
  },
  serverPrefetch(){
    return Promise.all([this.getCentralSpiritData(),this.getScienceWorkData()])
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.getCentralSpiritData()
      this.getScienceWorkData()
    }
    this.receiveCust()
  },
  computed:{
    ...mapState("dq_MOST",["centralSpiritData","scienceWorkData"])
  },
  methods: {
    ...mapActions("dq_MOST",["getCentralSpiritData","getScienceWorkData"]),
    receiveCust(val) {
      if(val === "centralSpirit") {
        this.moreLink = this.centralSpiritData.moreLink
      }else{
        this.moreLink = this.scienceWorkData.moreLink
      }
    }
  },
};
</script>

<style lang="less" scoped>
</style>