<template>
  <div class="w100 flexCenter">
    <div class="dsMainBox">
      <div class="w100 ds-detail-top">
          <Crumbs />
      </div>

      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs/incex.vue"
import { mapActions } from 'vuex';
export default {
  data() {
    return {};
  },
  components:{
    Crumbs
  },
  serverPrefetch() {
    return this.initData();
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.initData();
    }
  },

  methods: {
    ...mapActions("ds_detail", ["initData"]),
  },
};
</script>

<style lang="less" scoped>
.dsMainBox{
  background: #fff;
  min-height: 400px;
  padding: 10px;
  width: calc(1000px - 20px) !important;
}
.ds-detail-top{
  height: 20px;
  padding-bottom: 14px;
  border-bottom: 2px solid rgba(0,0,0,0.1000);
}
</style>