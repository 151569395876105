<template>
  <div class="w100 homeMainBox">
    <header class="w100" id='dq_layout_header'>
      <Header> </Header>
      <Menu></Menu>
      <!-- <Banner/> -->
    </header>

    <section class="w100">
      <router-view></router-view>
    </section>

    <footer class="w100">
      <Links />
      <Footer />
    </footer>
  </div>
</template>

<script>
import Header from "./components/header/index.vue"
import Menu from "./components/menu/index.vue"
import Footer from "./components/footer/index.vue"
import Links from "./components/links/index.vue"

export default {
  data() {
    return {};
  },
  components:{
    Header,
    Menu,
    Footer,
    Links
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.homeMainBox{
  min-width: 1200px !important;
}
</style>