<template>
  <div>
    <input type="hidden" name="pageRouter" value="dangqun/detail/learneducation"/>
    <listPublic :dataList="dqList" :backImg="backImg" :pageInfo="pageInfo"/>
  </div>
</template>

<script>
import { mapGetters,mapState } from 'vuex';
import listPublic from '../../../../components/listpublic/index.vue'
export default {
  components: {
    listPublic
  },
  data() {
    return {
      // dataList:[
      //   { name: 'aaaa',time: '2020-01-01'},
      //   { name: 'aaaa',time: '2020-01-01'},
      //   { name: 'aaaa',time: '2020-01-01'},
      //   { name: 'aaaa',time: '2020-01-01'},
      // ]
      backImg: {
        width_a:'dq_a',
        backImg: 'padding_box'
      }
      
    }
  },
  computed: {
    // ...mapState("dq_detail", ['dq_list']),
    ...mapGetters('dq_detail',['dqList','dqList','pageInfo'])
  },
  mounted() {}

}
</script>

<style>

</style>