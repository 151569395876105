<template>
  <div :class="`card ${value.dynamicClass}`" >
    <div class="title">
      <h2>{{value.name}}</h2>
      <a :href="dataShow.moreLink">更多</a>
    </div>
    <public-list :dataList="dataShow.list" :dynamicClass="value.dynamicClass"/>
  </div>
</template>

<script>
import PublicList from "../publicList/index.vue"
  export default {
    props:{
      value:{
        type: Object,
        default: ()=>{}
      },
      dataShow:{
        type: Object,
        default:()=>{}
      }
    },
    components:{
      PublicList
    }
  }
</script>

<style lang="less" scoped>
.card {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-indent: 18px;
    background: url("../../assets/100icone.png") no-repeat left center ;
    background-position-x: 1px;
    a {
      font-size: 12px;
      margin-right: 20px;
      color: #6B6B6B;
      font-weight: bold;
      text-decoration: revert;
    }
  }
}

.list-item-1 {
  .title {
    height: 40px;
  }
}
</style>