<template>
  <div>
    新闻列表
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>