import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    scienceData:{},
    scienceDataMore:{}
  },
  actions:{
    async getScienceData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/538/7/normal',
        method: "get"
      })
      commit("setScienceData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      })
      
      return res
    },
    async getScienceDataMore({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/538/14/normal',
        method: "get"
      })
      commit("setScienceDataMore",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      })
      
      return res
    },
  },  
  mutations:{
    setScienceData(state,data) {
      state.scienceData = data
    },
    setScienceDataMore(state,data) {
      state.scienceDataMore = data
    } 
  }
}
