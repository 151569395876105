<template>
    <div class="intellectualpr_box">
        <input type="hidden" name="pageRouter" value="detail/intellectualpr" />
        <div v-for="(item,index) in articleVOList" :key="index">
          <div class="intellectualpr_content" style="display:flex;align-items: center;" v-if="item.articleSource">
            <div :class="item.articleSource == '专利'? 'intellectualpr_patentBlue':'intellectualpr_patentGreen'" class="intellectualpr_patent">
                {{item.articleSource}}
            </div>
            <div class="intellectualpr_">
                <div class="intellectualpr_title">{{item.basicTitle}}</div>
                <div class="intellectualpr_titletwo" v-if="item.articleSource == '专利'">
                    <div class="styleWidth overhideen">
                        <div style="margin:24px 0">
                            <span style="margin-right:10px;">{{item.modelData?item.modelData.intellType.fieldTipsName:''}}:</span>
                            <span>{{item.modelData ? item.modelData.intellType.fieldData:''}}</span>
                        </div>
                        <div>
                            <span>设计/发明部门：</span>
                            <span>
                                <a :href="urlpath(baseUrl,item.modelData ? item.modelData.deptLink.fieldData : '')" target="_blank">{{item.modelData ? item.modelData.deptment.fieldData:''}}</a>
                            </span>
                        </div>
                    </div>
                    <div class="styleWidth overhideen">
                        <div style="margin:24px 0">
                            <span>专利号：</span>
                            <span>{{item.modelData ? item.modelData.intellNo.fieldData: ''}}</span>
                        </div>
                        <div>
                            <span>授权日期：</span>
                            <span>{{item.modelData ? item.modelData.authDate.fieldData: ''}}</span>
                        </div>
                    </div>
                    <div class="styleWidthtwo overhideen">
                        <div style="margin:24px 0">
                            <span>专利权人：</span>
                            <span>{{item.modelData ? item.modelData.patentee.fieldData: ''}}</span>
                        </div>
                    </div>
                </div>
                <div class="intellectualpr_titletwo" v-else>
                    <div class="styleWidthbot">
                        <div style="margin:24px 0">
                            <span>所有作者：</span>
                            <span>{{item.modelData ? item.modelData.patentee.fieldData:''}}</span>
                        </div>
                        <div>
                            <span>所属部门：</span>
                            <span>
                                <a :href="urlpath(baseUrl,item.modelData?item.modelData.deptLink.fieldData : '')" target="_blank">{{item.modelData ? item.modelData.deptment.fieldData:''}}</a>
                            </span>
                        </div>
                    </div>
                    <div class="styleWidthbot">
                        <div style="margin:24px 0">
                            <span>证书号：</span>
                            <span>{{item.modelData ? item.modelData.intellNo.fieldData : ''}}</span>
                        </div>
                        <div>
                            <span>申请日期：</span>
                            <span>{{item.modelData ? item.modelData.authDate.fieldData : ''}}</span>
                        </div>
                    </div>
                </div>
            </div>
          </div>    
        </div>
	    <Page :pageInfo="pageInfo"/>
    </div>
</template>

<script>
import Page from "../../../../components/Page/index.vue"
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('detail',['articleVOList','baseUrl','pageInfo'])
    },
    components:{
        Page
    },
    methods: {
      urlpath(val,url){
        if(url.indexOf('http'||'https') !== -1){
          return url
        }else{
          return val + url
        }
      }
    }
}
</script>

<style lang="less" scoped>
.styleWidth {
    // width: 200px;
    a {
        color: #333;
    }
}
// .styleWidthtwo {
//     width: 132px;
// }
.styleWidthbot {
    // width: 205px;
    a {
        color: #333;
    }
}
.intellectualpr_box {
    padding: 0px 20px 0px 50px;
    .intellectualpr_content {
        height: 188px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px #EFEFEF;
        .intellectualpr_patentBlue{
            background: url('../../../../assets/zscq-blue.png');
        }
        .intellectualpr_patentGreen{
            background: url('../../../../assets/zscq-green.png');
        }
        .intellectualpr_patent {
            width: 99px;
            height: 90px;
            background-size: 100% 100%;
            text-align: center;
            line-height: 90px;
            color: #fff;
        }
        .intellectualpr_ {
            padding: 0px 0px 0px 70px;
            // width: calc(100% - 90px);
            width: calc(100% - 90px);
            .intellectualpr_title{
                color: #327CC0;
                font-size: 18px;
                font-weight: 600;
            }
            .intellectualpr_titletwo {
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: #333;
                // div:nth-child(2){
                //     margin: 24px 0;
                // }
            }
        }
    }
}
.page {
  margin-top: 30px;
}
</style>