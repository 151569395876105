<template>
  <div class="fullBox">
    <Card
      :value="value"
      :moreLink="thinkLink.moreLink"
    >
      <template slot="icon">
        <span class="icon"></span>
      </template>
      <list :lists="thinkData.list" :value="value"></list>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Card from "../../../../components/STab/card.vue";
import list from "../../../../components/STab/list1.vue";
export default {
  data() {
    return {
      value:{
        name: '智库视野',
        enName: 'Technology think tan',
        left: '-24px'
      }
    };
  },
  components: {
    Card,
    list,
  },
  computed: {
    ...mapState("thinkTank", ["thinkData","thinkLink"]),
  },
  serverPrefetch() {
    return Promise.all([this.getThinkData(),this.getThinkLink()]);
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.getThinkData();
      this.getThinkLink()
    }
  },
  methods: {
    ...mapActions("thinkTank", ["getThinkData",'getThinkLink']),
  },
};
</script>

<style lang="less" scoped>
.icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  background: url("../../../../assets/box-zksy.svg") no-repeat;
  background-size: cover;
}
</style>