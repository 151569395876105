<template>
  <div class="w100">
    <card-2 :cardData="cardData" :moreLink="dynamicData.moreLink"/>
    <list-public :backImg="backImg" :dataList="dynamicData.list"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import card2 from "../../../../components/card2/index.vue"
import listPublic from "../../../../components/listpublic/index.vue"
export default {
  data() {
    return {
      cardData:{
        title:'基层党组织动态',
        value:'organizationsDynamic'
      },
      backImg:{backImg: 'listData_bakimg1'},
    };
  },
  components:{
    card2,
    listPublic
  },
  computed:{
    ...mapState("dq_dynamic",["dynamicData"])
  },
  serverPrefetch() {
    return this.getDynamicData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.getDynamicData()
    }
  },

  methods: {
    ...mapActions("dq_dynamic",["getDynamicData"])
  },
};
</script>

<style lang="less" scoped>
</style>