<template>
<div ref="metalt"  v-if="value.showDetail">
<div class="metalt"  @click="btn"></div>
  <div class="pup-detail" ref="pupDetail">
    <!-- 现任领导详情 -->
    <div class="leader-list-detail" v-if="value.who == 'ImgLeaderList'">
      <div class="detail-left">
        <img :src="listData.basicThumbnails">
      </div>
      <div class="detail-right">
        <div class="name">{{listData.modelData.name.fieldData ? listData.modelData.name.fieldData: ''}}</div>
        <div class="txt">{{listData.basicDescription}}</div>
      </div>
      <div class="detail-close" @click="closeDetail"></div>
    </div>

    <!-- 研究团队详情 -->
    <div class="research-list-detail" v-else>
      <div class="detail-left">
        <img :src="listData.basicThumbnails">
      </div>
      <div class="detail-right">
        <div class="name">{{listData.modelData.name.fieldData}}</div>
        <p style="margin-bottom: 10px">
          <span class="job">职称：{{listData.modelData.position.fieldData}}</span>
          <span class="depart">所在部门：{{listData.modelData.department.fieldData}}</span>
        </p>
        <p class="area">研究方向：{{listData.modelData.research.fieldData}}</p>
        <div class="txt">
          <p>研究简介：</p>
          <p v-html="listData.articleContent"></p>
        </div>
        <p class="email">邮箱：{{listData.modelData.email.fieldData}}</p>
      </div>
      <div class="detail-close" @click="closeDetail"></div>
    </div>
  </div>
</div>
  
</template>

<script>
  export default {
    props:{
      value:{
        type: Object,
        default: ()=>{}
      },
      listData:{
        type: Object,
        default: ()=>{}
      }
    },
    data() {
      return {
        top:0,
        left: 0
      }
    },
    methods: {
      closeDetail() {
        this.$emit('input',{ showDetail:false, who: this.value.who })
      },
      btn() {
        this.value.showDetail = false
      }
    },
  }
</script>

<style lang="less" scoped>
.metalt {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,.3);
  z-index: 2;
}
.pup-detail {
  position: fixed;
  left: 47%;
  margin-top: -250px;
  margin-left: -448px;
  // top: 0;
  // left: 0;
  border-radius: 5px;
  // word-break: break-all;
  background-color: #FFF;
  z-index: 33;
  .leader-list-detail {
    width: 896px;
    border: solid 1px #f4f4f4;
    padding: 100px 43px 0 34px;
    display: flex;
    justify-content: space-between;
    height: 355px;
    border-radius: 5px;
    // min-height: 180px;
    .detail-left {
      img {
        width: 139px;
      }
    }
    .detail-close {
      width: 28px;
      height: 28px;
      background-image: url('../../../../assets/close.png');
      background-size: cover;
      -webkit-print-color-adjust: exact;
      position: absolute;
      top: -14px;
      right: -14px;
      cursor: pointer;
    }
    .detail-right {
      width: 686px;
      .name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #303030;
      }
      .txt {
        font-size: 14px;
        word-break: break-all;
        color: #303030;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }
  .research-list-detail {
    width: 1000px;
    border: solid 1px #f4f4f4;
    display: flex;
    padding: 60px 40px 60px;
    text-align: start;
    justify-content: space-between;
    border-radius: 5px;
    // overflow: hidden;
    overflow-y: auto;
    .detail-left {
      img {
        max-width: 200px;
        height: 189px;
      }
    }
    .detail-right {
      width: 838px;
      text-align: left;
      .name {
        font-size: 18px;
        font-weight: 700;
        // margin: 15px 0;
        margin-bottom: 20px;
        color: #303030;
      }
      .job {
        margin-right: 60px;
      }
      .txt,.email,.area,.depart,.job {
        width: 100%;
        font-size: 14px;
        word-break: break-all;
        color: #797979;
        font-size: 14px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    .detail-close {
      width: 28px;
      height: 28px;
      background-image: url('../../../../assets/close.png');
      background-size: cover;
      -webkit-print-color-adjust: exact;
      position: absolute;
      top: -14px;
      right: -14px;
      cursor: pointer;
    }
  }
}
</style>