<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/cooperation" />
    <cooperation-list :listData="articleVOList"/>

    <Page :pageInfo="pageInfo" v-if="pageInfo"></Page>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Page from "../../../../components/Page"  
import CooperationList from "./component/cooperationList.vue"
  export default {
    computed:{
      ...mapGetters("detail",["articleVOList","pageInfo"])
    },
    components:{
      CooperationList,
      Page
    }
  }
</script>

<style lang="less" scoped>

</style>