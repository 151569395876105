import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import {createStore} from "./store/index"
import "swiper/dist/css/swiper.css"
import "./styles/global.less"
import utils from "./utils/index"

Vue.config.productionTip = false

export function createApp(context = {}) {
  const store = createStore();  
  let query = {};
  // console.log("@@@@@@@@store", store.state.detail)
  // If used in Nuxt.js/SSR, you should keep it only in browser build environment
  if (typeof window !== 'object') {
    query = context.query;
    
    /* ssr */
    try {
      const VueAwesomeSwiperSSR = require('vue-awesome-swiper/ssr')
      Vue.use(VueAwesomeSwiperSSR)
    } catch (error) {
      // console.error(error)
    }

  } else {
    /* window */
    const VueAwesomeSwiper = require('vue-awesome-swiper')
    window.Swiper = VueAwesomeSwiper.Swiper;
    
    query = utils.getQery(window.location.href);
  }

  store.commit("detail/setQuery", query)
  store.commit("dq_detail/setQuery", query)
  store.commit("ds_detail/setQuery", query)
  store.commit("en_detail/setQuery", query)
  store.commit("da_detail/setQuery", query)
  
  const router = createRouter();

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })
  return {
    app,
    router,
    store
  }
}