<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="meeting20/detail/editor"/>
    <div class="editor_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
      <!-- <div class="title_time">{{getTime(detailData.basicDateTime)}}</div> -->
    </div>
    <div v-html="detailData.articleContent" class="cont-txt"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("da_detail", ['detailData'])
  },
  mounted() {},

  methods: {
    getTime(val) {
      return dayjs(val).format('YYYY-MM-DD')
    }
  },
};
</script>

<style lang="less" scoped>
.editor_title {
  margin: 20px 0 0 0;
  text-align: center;
  .title_title {
    font-size: 18px;
    font-weight: bold;
  }
  .title_time {
    font-size: 14px;
    color: #525252;
    letter-spacing: 2px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    padding: 0 6px;
  }
}
.cont-txt {
  // padding: 16px 0;
  // /deep/ p {
  //   line-height: 2em;
  //   font-size: 14px;
  //   color: #525252;
  //   text-indent: 32px!important;
  //   letter-spacing: 2px;
  // }
  /deep/img {
    display: block;
    margin: 0 auto;
  }
  /deep/ table {
    // text-indent: 32px!important;
    font-size: 14px !important;
    line-height: 32px;
    border-collapse: inherit !important;
    span {
      font-size: 14px !important;
      color: #525252 !important;
    }
  }
  /deep/ .firstRow {
            line-height: 32px;
            color: #525252 !important;
            letter-spacing: 2px !important;
            span {
              color: #525252 !important;
            }
          }
}
</style>