import req from "../../req/index";
import utils  from "../../utils/index";

export default {
  namespaced: true,
  state:{
    workData:{},
  },
  actions:{
    async getWorkData({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/553/7/normal',
        method: "get"
      });
      commit("setWorkData",{
          moreLink: res.moreLink,
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    },
  },  
  mutations:{
    setWorkData(state,data) {
      state.workData = data;
    },
  }
}
