<template>
  <div class="header">
    <!-- <div class="homeMainBox_img" v-if="urlimg =='/'">
      <a target="_blank" href="http://www.gov.cn/zhuanti/zggcddescqgdbdh/index.htm">
        <img src="../../../assets/20221015110320.jpg" alt=""/>
      </a>
    </div> -->
    <div class="header-top">
      <div class="header-top-banner">
        <div class="header-top-bg fullBox"></div>

        <div class="header-top-action-box">
          <div class="header-top-action">
            <div></div>
            <div class="header-top-action-btns">
              <a :href="list.homeUrl">Home</a>
              <b class="split"></b>
              <a :href="list.enHomeUrl">English</a>
              <div class="searchBox">
                <b class="seachIcon" @mousemove="showSearch = true"></b>

                <form
                  v-show="showSearch"
                  @mouseleave="showSearch = false"
                  ref="searchDataForm"
                  :action="action"
                  method="post"
                >

                <div class="searchInputBox">
                  <input name="basic_title" placeholder="Search..." v-model="basic_title" />
                  
                  <a @click="search">go</a>
                </div>

                <input type="hidden" id="statFlag" name="statFlag" value="true" />
                <!-- <input type="hidden" id="stime" name="stime" :value="time" /> -->
                <!-- <input type="hidden" id="sign" name="sign" value="" /> -->
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header-content">
        <div class="bgBox">
          <a :href="list.homeUrl">
            <b @click="" ></b>
          </a>
          
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import req from "../../../req/index";

export default {
  data() {
    return {
      showSearch: false,
      basic_title: "",
      // action: process.env.VUE_APP_BASE_API + "/cms/1/search.do",
      // action:"https://zxsweb.sundot.cn/cms/1/search.do",
      // time: new Date().getTime()
    };
  },
  serverPrefetch(){
    return this.initList()
  },
  computed: {
    ...mapState("footer", ["list"]),
    action(){
      let url = this.list.appUrl + "/cms/1/search.do";

      return url;
    },
    urlimg(){
      return this.$route.path
    }
  },

  methods: {
    ...mapActions("footer",["initList"]),
    async search() {
      if (!this.basic_title) return;

      let form = this.$refs.searchDataForm;

      form.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  z-index: 1;
  .homeMainBox_img{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
    img{
      width: 100%;
    }
  }
  .header-top {
    width: 100%;
    height: 160px;
    background: url("../../../assets/header-bg.png") no-repeat 88% center;
    -webkit-print-color-adjust: exact;
    background-size: auto 100%;

    .header-top-banner {
      position: relative;
      height: 41px;
      .header-top-bg2{
        background: #89bffe;
        opacity: 0.4;
      }
      .header-top-bg2{
        background: #FC1D1E;
      }
      .header-top-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        background: #89bffe;
        opacity: 0.4;
      }
      .header-top-action-box {
        position: relative;
        height: 41px;
        display: flex;
        justify-content: center;
      }
      .header-top-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        .header-top-action-btns {
          display: flex;
          align-items: center;
          a {
            font-size: 16px;
            color: #333333;
          }

          .split {
            width: 2px;
            height: 13px;
            background: #333333;
            margin: 0 12px;
          }

          .searchBox {
            margin-left: 14px;
            margin-top: 5px;
            position: relative;

            .searchInputBox {
              display: flex;
              padding: 10px;
              width: 180px;
              height: 30px;
              line-height: 30px;
              background: #d3e5fe;
              border-radius: 5px;
              position: absolute;
              bottom: -55px;
              left: -180px;
              input {
                outline: none;
                background: #fff;
                border-radius: 5px 0 0 5px;
                border: transparent;
                width: 85%;
                font-size: 14px;
                height: 20px;
                line-height: 20px;
                padding: 5px 10px;
              }
              input:focus {
                outline: none;
              }
              a {
                background: #327cc0;
                border-radius: 0 5px 5px 0;
                width: 30px;
                height: 30px;
                color: #fff;
                font-size: 14px;
                text-align: center;
                line-height: 27px;
              }
              a:hover {
                color: #fff !important;
              }
            }
            .seachIcon {
              display: inline-block;
              width: 16px;
              height: 16px;
              background: url("../../../assets/search.svg") no-repeat;
              -webkit-print-color-adjust: exact;
              background-size: cover;
              cursor: pointer;
            }
          }
        }
      }
    }

    .header-content {
      width: 100%;
      height: 119px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bgBox {
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
          display: flex;
        }
      }
      b {
        width: 499px;
        height: 55px;
        // background: url("../../../assets/home-icon.png") no-repeat;
        background: url("../../../assets/hd_logo.png") no-repeat;
        -webkit-print-color-adjust: exact;
        background-size: 100% 100%;
        // margin-left: 109px;
      }
    }
  }
}
</style>