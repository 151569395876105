<template>
  <div class="com_box">
    <div>
      <div class="com_basicTitle">{{detailData.basicTitle}}</div>
      <!-- <div>时间</div> -->
      <slot name="time"></slot>
    </div>
    <div v-html="detailData.articleContent" class="com_html"></div>
      <slot name="text"></slot>
  </div>
</template>

<script>
export default {
 props: {
  detailData: {
    type: Object,
    default: () => {}
  }
 }
}
</script>

<style lang="less" scoped>
.com_box {
  padding: 0 20px;
}
.com_basicTitle {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  line-height: 30px;
}
.com_html {
  padding: 16px;
  font-size: 14px;
  color: #666666;
  line-height: 32px;
  /deep/ p {
    letter-spacing: 2px;
    text-indent: 32px!important;
    margin-bottom: 10px;
  }
}
</style>