<template>
  <div id="app" class="w100">
    <router-view/>
  </div>
</template>
<script>
// import req from "./req"
// import {mapState, mapActions} from "vuex"

export default {
  // created(){
  //   if (typeof window === 'object') {
  //     this.cmsStatus();
  //   }
  // },
  computed:{
    // ...mapState("footer", ["list"]),
  },
  // serverPrefetch(){
  //   return this.initList()
  // },
  methods:{
    // ...mapActions("footer",["initList"]),
    /* 权限校验 */
    // async cmsStatus(){
    //   let res = await req.ajax({
    //     url: (this.list.appUrl || '') + "/cms/status",
    //     method: "post",
    //     data: {
    //       requestUrl: window.location.href
    //     }
    //   })

    //   // const {blackList, safePage} = res;

    //   // if(blackList === "false" && safePage === "true"){
    //   //   this.$router.push({path: "/404"})
    //   // }
    // }
  }  
}
</script>
<style>
</style>
