<template>
  <div class="w100">
     <input type="hidden" name="pageRouter" value="detail/enrollment"/>
    <scientificresearch class="mb30" :articleVOList="articleVOList" :papersection="papersection"/>
    <div class="w100 flexCenter mb50">
      <Page v-if="pageInfo" :pageInfo="pageInfo"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import scientificresearch from '../../components/scientificresearch/index.vue'
import Page from "../../../../components/Page"
export default {
    data() {
        return {
             papersection: {
                enrollment: true,
            }
        }
    },
    components: {
        scientificresearch,
        Page
    },
    computed: {
        ...mapGetters('detail',['articleVOList','pageInfo']),
    },
}
</script>

<style>

</style>