<template>
  <div class="crumbs">
    <div class="logo">
      <a :href="baseUrl + '529/558/index.html'"></a>
    </div>
    <div class="list">
      <div class="nav">
        {{firstCrumb.title}}&emsp; <span v-if="crumb">-</span><span v-else>+</span>
      </div>
      <div @mouseenter="isShow = false" class="nav-list" v-if="crumb">
        <div v-if="isShow"> 
        <a v-if="lastCrumb[0]&&lastCrumb[0].link" :href="lastCrumb[0].link">{{lastCrumb[0].title}} &emsp; +</a>
        </div>
        <ul @mouseleave="isShow = true" v-else-if="!isShow && lastCrumb" class="nav-ul">
          <li class="nav-list-item"  v-for="(item,index) in lastCrumb" :key="index">
            <a :href="item.link">{{item.title}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
  data() {
    return {
    //  list:[],
     isShow: true
    }
  },

  computed:{
    ...mapGetters("en_detail",["crumbsList","baseUrl"]),
    firstCrumb(){
      return this.crumbsList[0] || {}
    },
    lastCrumb(){
      let arr = _.cloneDeep(this.crumbsList)
      arr.shift()
      return arr
    },
    crumb(){
      return this.crumbsList.length > 1? true : false 
    }
   },
  }
</script>

<style lang="less" scoped>
.crumbs {
  width: calc(100% - 2px );
  height: 43px;
  position: relative;
  border: 1px solid #0d7cd1;
  background-color: white;
  display: flex;
  align-items: center;
  .logo {
    width: 40px;
    height: 45px;
    cursor: pointer;
    background: url("../../../../assets/en_home.jpg") center ;
    a {
      display: inline-block;
      width: 100%;
      height: 40px;
    }
  }
  .list {
    display: flex;
    align-items: center;
    .nav {
      margin-left: 20px;
    }
    .nav-list {
      margin-left: 20px;
      a {
        color: #000;
      }
      .nav-ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        border-top: none;
        background: #FFF;
        .nav-list-item {
          width: 100%;
          height: 44px;
          padding: 0 10px;
          border-left: 1px solid #C7C7C7;
          border-right: 1px solid #C7C7C7;
          border-bottom: 1px solid #C7C7C7;
          background: #FFF;
          a {
            display: block;
            line-height: 44px;
          }
          &:hover {
            background: #D4D4D4;
            a {
              color: #000 !important; 
            }
          }
        }
      }
    }
  }

}
</style>