import req from "../../req/index"
import utils  from "../../utils/index"

export default {
  namespaced: true,
  state:{
    dataCenter:{},
  },
  actions:{
    async getDataCenter({ commit }){
      let res = await req.ajax({
        url:'/static/generate/getArticles/480/6/normal',
        method: "get"
      });
      commit("setDataCenter",{
          moreLink: res.moreLink || "",
          list: utils.initHomeList(res.articleVOS)
      });
      return res;
    }
  },  
  mutations:{
    setDataCenter(state,data) {
      state.dataCenter = data;
    }
  }
}
