import axios from 'axios';
let log = console;

class ReqClass {
  /* 缓存 */
  cacheReq;
  cacheRequest;

  constructor(baseUrl) {
    // 默认超时设置
    axios.defaults.timeout = 1000 * 60;

    // 相对路径设置
    axios.defaults.baseURL = baseUrl;

    //http response 拦截器
    axios.interceptors.response.use(
      (res) => {
        if (res) {
          return res;
        }
      },
      err => {
        return Promise.reject(err);
      },
    );

    //http request 拦截器
    axios.interceptors.request.use(
      (config) => {
        // 设置参数格式
        if (!config.headers['Content-Type']) {
          config.headers = {
            'Content-Type': 'application/json',
          };
        }
        // 添加token到headers
        // config.headers.token = token || '';
        config.url = config.url;
        // 鉴权参数设置
        if (config.method === 'get') {
        } else {
          config.data = config.data || {};
          //一些参数处理
        }
        return config;
      },
      err => {
        return Promise.reject(err);
      },
    );
  }

  /* 
    {
      url: "",
      method: "",
      data || params,
      headers: {}
    }
  */
  async cache(config) {
    let key = JSON.stringify(config);
    if (this.cacheReq[key] != undefined) {
      console.info(
        `命中缓存: "${config.url}" ->  \n \nres: "${JSON.stringify(
          this.cacheReq[key],
        )}" \n \n ---------------`,
      );
      return Promise.resolve(this.cacheReq[key]);
    }

    /** 缓存request，同时发起同一请求时，仅请求一次 */
    let ajaxObservable = null;
    // 读缓存
    if (this.cacheRequest[key]) {
      ajaxObservable = this.cacheRequest[key];
    } else {
      // 设缓存
      ajaxObservable = this.ajax(config);
      this.cacheRequest[key] = ajaxObservable;
    }

    try {
      const d = await ajaxObservable;
      this.cacheReq[key] = d;
      return d;
    } catch (error) {
      return Promise.reject(error);
    }

  }

  /* 
    {
      url: "",
      method: "",
      data || params,
      headers: {}
    }
  */
  async ajax(config) {
    try {
      if(process.env.NODE_ENV !== "development"){
        // log.log("ajax->", process.env, config)
      }
      
      let res = await axios(config);
      const { data } = res;
      const { status, message, code } = data;

      if (status === 200 || code === 200) {
        // if(process.env.NODE_ENV !== "development"){
        //   log.log("ajax response->", data.data)
        // }
        return data.data;
      } else {
        log.error(message)
        return Promise.reject(message)
      }

    } catch (error) {
      log.error("ajax error=>", error);
      return Promise.reject(error);
    }

  }
}
console.log("当前api地址: ", process.env)
let req = new ReqClass(process.env.VUE_APP_BASE_API);

export default req;