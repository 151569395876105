<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="detail/editor" />
    <div class="cont-txt"  v-html="detailData.articleContent">
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('detail', ['detailData']),
  },
  mounted() { },

  methods: {},
};
</script>

<style lang="less" scoped>
.cont-txt {
  padding: 0 20px !important;
  /deep/ p{
    margin-bottom: 10px !important;
  }
  /deep/ span {
    letter-spacing: 0px !important;
    color: #666 !important;
  }
}
/deep/ img {
  width: 100%;
}
</style>