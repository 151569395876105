import req from '../../req/index';
import utils from "../../utils/index";

export default {
  namespaced: true,
  state: {
    list: {},
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initList({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getArticles/389/10/normal",
        method: "get"
      })

      commit('setList', {
        moreLink: res.moreLink,
        list: utils.initHomeList(res.articleVOS)
      })

      return res;
    },
  },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
  },
}