<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/perspective"/>
    <perspective :detailData="detailData">
      <div slot="modelData" class="modelData_box">
        <li>
          <span>作&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;者：</span>
          <span>{{detailData.articleAuthor}}</span>
        </li>
        <li>
            <span>{{detailData.modelData ? detailData.modelData.Outputorganization.fieldTipsName:''}}：</span>
            <span>{{detailData.modelData ? detailData.modelData.Outputorganization.fieldData:''}}</span>
          </li>
          <li>
            <span>{{ detailData.modelData ? detailData.modelData.Submittingagency.fieldTipsName:''}}：</span>
            <span>{{detailData.modelData ? detailData.modelData.Submittingagency.fieldData:''}}</span>
          </li>
          <li>
            <span>{{detailData.modelData ? detailData.modelData.outputdate.fieldTipsName:''}}：</span>
            <span>{{detailData.modelData ? time(detailData.modelData.outputdate.fieldData):''}}</span>
          </li>
          <li>
            <span>{{detailData.modelData ? detailData.modelData.Releasedate.fieldTipsName:''}}：</span>
            <span>{{detailData.modelData ? time(detailData.modelData.Releasedate.fieldData):''}}</span>
          </li>
          <li>
            <span>{{detailData.modelData ? detailData.modelData.abstract.fieldTipsName : ''}}：</span>
            <div v-html="detailData.modelData ? detailData.modelData.abstract.fieldData : ''" class="title_abstract"></div>
          </li>
      </div>
      <div slot="articleContent" class="title_btn">
        <span style="margin-left:10px;">{{detailData.modelData ? detailData.modelData.link.fieldTipsName:''}}：</span>
        <a :href="detailData.modelData?detailData.modelData.link.fieldData:''">{{detailData.modelData?detailData.modelData.link.fieldData:''}}</a>
      </div>
    </perspective>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import perspective from '../../components/perspectivedetail/index.vue'
import { mapGetters } from 'vuex';
export default {
  components: {
    perspective
  },
  computed: {
    ...mapGetters('detail',['detailData'])
  },
  methods: {
    time(val) {
      return dayjs(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="less" scoped>
  .modelData_box {
  li {
    span:nth-child(2) {
      color: #327cc0 !important;
    }
  }
}
.title_btn {
  font-size: 14px;
  color: #333;
  // margin: 60px 0 50px  0;
}
// .title_btn {
//   margin: 60px 0 20px  0;
  a {
    color: #2e2e2e;
    &:hover {
      color: #327cc0;
    }
  }
// }
</style>