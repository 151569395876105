<template>
  <div class="textnone1">
    <div class="bg-black"></div>
    <div class="list-detail-global">
      <a @click="close">
        <div class="js-cancle"></div>
      </a>
      <div class="pic" :class="listsrc">
        <!-- <img :src="listsrc"/> -->
      </div>
      <div class="info">
        <div class="name">{{value.name}}</div>
        <div class="txt" style="font-size:14px;text-align: justify;" v-html="value.txt"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type: Object,
        default: () => {}
      }
    },
    computed: {
      listsrc() {
        let id = this.value.id
        let listsrc = {
          'textnubmer1': 'bg1',
          'textnubmer2': 'bg2',
          'textnubmer3': 'bg3',
          'textnubmer4': 'bg4',
          'textnubmer5': 'bg5',
          'textnubmer6': 'bg6',
        }
        return listsrc[id]
      }
    },
    data() {
      return {
        val:false
      }
    },
    methods:{
      close(){
        this.$emit("closeDetail",this.val)
      }
    }
  }
</script>

<style lang="less" scoped>
.list-detail-global {
	background: #FFF;
	border: solid 5px #E1E1E1;
	position: absolute;
	left: 100px;
	right: 100px;
	top: 780px;
	min-height: 180px;
	padding: 17px 19px;
	border-radius: 5px;
	display: block;
	margin: 0 auto;
	width: 1100px;
  .js-cancle {
    width:28px;
    height: 28px;
    background:url("../../../../../../../assets/close.png") no-repeat center;
    background-size: cover;
    display: block;
    position: absolute;
    right:-18px;
    top:-18px;
    cursor: pointer;
  }
  .pic {
    display: inline-block;
    width: 250px;
    min-height: 180px;
    background-repeat: no-repeat;
    background-position: center 0;
    -webkit-background-size: 100% auto;
    img{
      width:100%;
    }
  }
  .info {
    display: inline-block;
    width: 820px;
    vertical-align: top;
    .name{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #005ca1;
    }
    .txt{
      font-size: 16px;
      line-height: 25px;
    }
  }
}
.bg-black {
	width: 100%;
	height: 100%;
  display: block;
	background-color: rgba(0, 0, 0, .5);
	position: fixed;
	top: 0;
	left: 0;
	min-width: 1296px;
}
.bg1 {
  background: url('../../../../../assets/sy2.png');
}
.bg2 {
  background: url('../../../../../assets/sy4.jpg');
}
.bg3 {
  background: url('../../../../../assets/sy5.png');
}
.bg4 {
  background: url('../../../../../assets/sy3.png');
}
.bg5 {
  background: url('../../../../../assets/sy1.png');
}
.bg6 {
  background: url('../../../../../assets/sy6.jpg');
}
</style>