<template>
  <div class="leader">
    <input type="hidden" name="pageRouter" value="detail/leaderList" />
    <ul class="first-leader">
      <li v-for="(item, index) in leader" class="firsr-leader-item" :key="`laeder-${index}}`" @click="isShowDetail(item)">
        <div class="pic" >
          <img :src="item.basicThumbnails" alt="">
        </div>
        <p class="name">{{item.modelData ? item.modelData.name.fieldData : ''}}</p>
        <p class="job">{{item.modelData ? item.modelData.position.fieldData : ''}}</p>
      </li>
    </ul>
    <ul class="last-leader-list">
      <li class="leader-list-item" v-for="(item,index) in lastLeader" :key="index" @click="isShowDetail(item)">
        <div class="pic">
          <img :src="item.basicThumbnails || '/resource/404.jpeg'">
        </div>
        <p class="name">{{item.modelData?item.modelData.name.fieldData:''}}</p>
        <p class="job">{{item.modelData?item.modelData.position.fieldData:''}}</p>
      </li>
    </ul>
    <human-detail v-model="showWhoDetail" :listData="humanDetailData" ref="pupDetail"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import humanDetail from '../../components/humanDetail/humanDetail.vue'
  export default {
    components:{
      humanDetail
    },
    data() {
      return {
        showWhoDetail:{
          showDetail: false,
          who:'ImgLeaderList'
        },
        humanDetailData:{},
        index: undefined
      }
    },
    mounted() {
    },
    computed:{
      ...mapGetters('detail',['articleVOList']),
      ...mapGetters("detail",['detailData']),
      leader(){
        return this.articleVOList.slice(0, 2)
      },
      lastLeader(){
        return this.articleVOList.slice(2)
      }
    },
    methods:{
      isShowDetail(item) {
        this.showWhoDetail.showDetail = true

        this.humanDetailData = item;
        this.fix()
      },
      fix(){
        this.$nextTick(()=>{
          if(typeof window === "object"){
            const detail = this.$refs.pupDetail.$refs.pupDetail
            let currentHeight = this.$refs.pupDetail.$refs.pupDetail.offsetHeight
            let currentWindowHight = (document.documentElement.offsetHeight || document.body.offsetHeight) 
            let screenHeight = window.screen.height
            detail.style.top = (screenHeight - currentHeight - 150)/2+ 250 +"px"
            // console.log(currentHeight,currentWindowHight,screenHeight);  
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.leader {
  position: relative;
  .first-leader{
    width: 100%;
    text-align: center;
    margin: 15px 0;

    .firsr-leader-item{
      display: inline-block;
      img{
        object-fit: cover;
      }
    }

    .firsr-leader-item:first-child{
      margin-right: 230px;
    }
  }
  .last-leader-list {
    margin: 0 100px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    .leader-list-item {
      text-align: center;
      margin: 15px 0;
    }
  }
  .pic {
    img {
      width: 108px;
      height: 147px;
      cursor: pointer;
    }
  }
  .name{
    width: 108px;
    font-size: 18px;
    color: #272727;
    margin: 0 auto;
    line-height: 1.8;
    cursor: pointer;
  }
  .job {
    width: 108px;
    font-size: 14px;
    color: #8F8F8F;
    margin: 0 auto;
    line-height: 1.8;
    cursor: pointer;
  }
}
</style>