<template>
  <div class="big-event-detail">
    <input type="hidden" name="pageRouter" value="detail/bigEventsDetail" />
    <ul class="big-event-detail-list">
      <dl class="big-event-detail-list-item" v-for="(item,index) in articleVOList" :key="index">
          <dt class="time">{{item.basicTitle}}</dt>
          <dt class="description">{{item.basicDescription}}</dt>
      </dl>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("detail",["articleVOList"])
    },

  }
</script>

<style lang="less" scoped>
.big-event-detail-list{
  position: relative;
  margin: 8px 0 35px;
  padding: 5px 0;
  &::before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 17px;
      left: 0;
      width: 12px;
      background: #CAE6FF;
      display: block;
    }
  .big-event-detail-list-item {
    position: relative;
    overflow: hidden;
    padding: 2px 0 19px 60px;
    color: #000000;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0px;
      width: 44px;
      height: 36px;
      background: url(../../../../assets/ico-03.png) no-repeat center;
    }
    .time {
      display: inline-block;
      width: 100px;
    }
    .description {
      display: inline-block;
      width: 630px;
    }
  }
  .big-event-detail-list-item:last-child::after {
    content: "";
    position: absolute;
    bottom: 7px;
    width: 0;
    left: 0px;
    bottom: 0;
    // top: 4px;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid #cae6ff;
  }
}
</style>