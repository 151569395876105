<template>
  <div class="fullBox carouselBox">
    <div ref="swiperDom">
      <div class="swiper-wrapper fullBox">
        <!-- <div class="swiper-slide" v-for="item in scienceWorkDataBanner.list" :key="item.id"> -->
        <div class="swiper-slide">
          <img class="" src="../../../../assets/lun1.jpg" alt="" />
          <div class="des-pop">
          </div>
          <a class="des-pop-a" href="http://www.news.cn/politics/leaders/2022-10/18/c_1129069546.htm">党的二十大主席团举行第二次会议 习近平主持会议</a>
        </div>
        <div class="swiper-slide">
          <img class="" src="../../../../assets/lun2.jpg" alt="" />
          <div class="des-pop">
          </div>
          <a class="des-pop-a" href="http://www.news.cn/politics/cpc20/2022-10/16/c_1129067261.htm">中国共产党第二十次全国代表大会在京开幕</a>
        </div>
        <div class="swiper-slide">
          <img class="" src="../../../../assets/lun3.jpg" alt="" />
          <div class="des-pop">
          </div>
          <a class="des-pop-a" href="http://www.news.cn/politics/leaders/2022-10/15/c_1129065993.htm">党的二十大主席团举行第一次会议 习近平出席会议并作重要讲话</a>
        </div>
        <!-- <div class="swiper-slide" v-for="item in scienceWorkDataBanner.list" :key="item.id">
          <img class="" :src="item.image" alt="" />
          <div class="des-pop">
          </div>
          <a class="des-pop-a" :href="item.link">{{item.name}}</a>
        </div> -->
      </div>

      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        slidesPerView: "auto",
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 0,
      },
    };
  },
  computed:{
    ...mapState("da_spiritWork", ["scienceWorkDataBanner"])
  },

  serverPrefetch() {
    return this.getScienceWorkDataBanner();
  },
  async mounted() {
    if (process.env.NODE_ENV === "development") {
      await this.getScienceWorkDataBanner();
    }
    this.init();
  },

  methods: {
    ...mapActions("da_spiritWork", ["getScienceWorkDataBanner"]),
    init() {
      let swiper = window.Swiper;
      new swiper(this.$refs.swiperDom, this.swiperOption);
    },
  },
};
</script>

<style lang="less" scoped>
.carouselBox{
  overflow: hidden;
  position: relative;
}
.swiper-slide {
  position: relative;
  width: 600px;
  height: 395px;
  img{
    // max-width: 100%;
    // display: block;
    width: 100%;
    height: 100%;
  }
  .des-pop {
    position: absolute;
    width: 100%;
    height: 40px;
    line-height: 40px !important;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.5;
  }
  .des-pop-a{
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    width: 368px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    opacity: 1 !important;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .des-pop-a:hover{
    color: #fff !important;
    text-decoration: revert;
  }
}
.swiper-pagination{
  left: auto !important;
  display: inline-block;
  width: auto !important;
  
  right: 30px !important;
  bottom: 10px !important;
  /deep/.swiper-pagination-bullet{
    background: #fff;
    opacity: 1;
  }
  /deep/.swiper-pagination-bullet-active{
    background: red;
  }
}
</style>