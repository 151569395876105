<template>
  <div class="links w100">
    <div class="link-item">
      <LinkBtn :list="kejiData.list" label="科技部系统" />
    </div>

    <div class="link-item">
      <LinkBtn :list="ziyuanData.list" label="资源服务系统" />
    </div>

    <div class="link-item">
      <LinkBtn :list="hangyeData.list" label="行业情报所" />
    </div>

    <div class="link-item">
      <LinkBtn :list="xuehuiData.list" label="学会协会" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LinkBtn from "./components/linkBtn/index.vue";

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState("links", [
      "kejiData",
      "ziyuanData",
      "hangyeData",
      "xuehuiData",
    ]),
  },
  components: {
    LinkBtn,
  },
  serverPrefetch() {
    return Promise.all([
      this.getData({
        url: "304/-1/normal",
        key: "kejiData",
      }),
      this.getData({
        url: "305/-1/normal",
        key: "ziyuanData",
      }),
      this.getData({
        url: "306/-1/normal",
        key: "hangyeData",
      }),
      this.getData({
        url: "308/-1/normal",
        key: "xuehuiData",
      }),
    ]);
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.getData({
        url: "304/-1/normal",
        key: "kejiData",
      });
      this.getData({
        url: "305/-1/normal",
        key: "ziyuanData",
      });
      this.getData({
        url: "306/-1/normal",
        key: "hangyeData",
      });
      this.getData({
        url: "308/-1/normal",
        key: "xuehuiData",
      });
    }
  },

  methods: {
    ...mapActions("links", ["getData"]),
  },
};
</script>

<style lang="less" scoped>
.links {
  width: 100%;
  height: 48px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 48px;
}

.link-item {
  height: 100%;
  width: 24.3%;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 10%);
}
</style>