<template>
  <div>
    <ul class="research-team">
      <li class="research-team-item" v-for="(item,index) in listData" :key="index" @click="isShowDetail(index)" >
        <div class="pic">
          <img :src="item.basicThumbnails || '/resource/404.jpeg'" alt="">
        </div>
        <p class="name">{{item.modelData ? item.modelData.name.fieldData:''}}</p>
      </li>
      <human-detail v-model="showWhoDetail" :listData="humanDetailData" ref="pupDetail"/>
    </ul> 

    <page :pageInfo="pageInfo" v-if="pageInfo"/>  

  </div>
</template>

<script>
import humanDetail from '../../../components/humanDetail/humanDetail.vue'
import Page from "../../../../../components/Page/index.vue"
  export default {
    props:{
      listData:{
        type: Array,
        default: ()=>[]
      },
      pageInfo:{
        type: Object,
        default: ()=>{}
      }
    },
    components:{
      humanDetail,
      Page
    },
    data() {
      return {
        showWhoDetail: {
          showDetail: false,
          who: 'researchTeam'
        },
        humanDetailData:{},
      }
    },
    methods: {
      isShowDetail(index) {
        this.showWhoDetail.showDetail = true
        this.humanDetailData = this.listData[index]
        this.fix()
      },
      fix(){
        this.$nextTick(()=>{
          if(typeof window === "object"){
            const detail = this.$refs.pupDetail.$refs.pupDetail
            let currentHeight = this.$refs.pupDetail.$refs.pupDetail.offsetHeight
            let currentWindowHight = (document.documentElement.offsetHeight || document.body.offsetHeight) 
            let screenHeight = window.screen.height
            detail.style.top = (screenHeight - currentHeight - 150)/2+ 250 +"px"
            // console.log(currentHeight,currentWindowHight,screenHeight);  
          }
        })
      }
    },
  }
</script>

<style lang="less" scoped>
.research-team {
  min-height: 652px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  text-align: center;
  // margin: 2px 0 38px;
  cursor: pointer;
  position: relative;
  .research-team-item {
    width: 20%;
    margin-bottom: 40px;
    height: 187px;
    .pic {
      img {
        max-width: 187px;
        height: 145px;
      }
    }
    .name {
      font-size: 14px;
      color: #272727;
    }
  }
}
</style>