<template>
  <div>
    <ul class="profile-list">
      <li v-for="item in listData" :key="item.name" class="profile-list-item">
        <a :href="item.articleLinkURL">
          <div class="t1">
            <p>{{item.basicTitle}}</p>
          </div>
          <div class="t2">{{item.basicDescription}}</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props:{
      listData:{
        type: Array,
        default: ()=> []
      }
    }
  }
</script>

<style lang="less" scoped>
.profile-list {
  .profile-list-item {
    width: 100%;
    height: 134px;
    margin-bottom: 26px;
    overflow: hidden;
    border: solid 1px #f7f7f7;
    border-radius: 4px;
    box-shadow: 0 0 7px #ddf2ff;
    position: relative;
    a {
      outline: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 100%;
      .t1 {
        width: 100px;
        height: 114px;
        text-align: center;
        position: absolute;
        border-radius: 50%;
        left: -24px;
        background: #CCE6FF;
        padding-left: 14px;
        top: 50%;
        margin-top: -57px;
        color: #0163C4;
        font-weight: 700;
        p {
          font-size: 14px;
          display: table-cell;
          width: 100px;
          height: 114px;
          vertical-align: middle;
          padding: 0 30px;
          line-height: 24px;
        }
      }
      .t2 {
        width: 814px;
        font-size: 14px;
        color: #555;
        max-height: 90px;
        padding-left: 107px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:hover {
      box-shadow: 0 0 24px #ddf2ff;
      a {
        color: #555 !important;
        .t1 {
          color: #FFF;
          background: #0163C4;
        }
      }
    }
  }
}
</style>