<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="detail/editortitle" />
    <div class="editor_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
    </div>
    <div v-html="detailData.articleContent" class="cont-txt"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("detail", ['detailData'])
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
// .w100 {
//   padding: 0 20px;
//   width: calc(100% - 40px);
// }
.editor_title {
  margin: 20px 0 12px 0;
  text-align: center;
  .title_title {
    font-size: 18px;
    font-weight: bold;
  }
}
.cont-txt {
  // padding: 16px 0;
  p {
    margin-bottom: 10px;
  }
  /deep/ table {
    // text-indent: 32px!important;
    font-size: 14px !important;
    line-height: 32px;
    border-collapse: inherit !important;
    p {
      text-indent: 0px!important;
    }
    span {
      font-size: 14px !important;
      color: #525252 !important;
    }
  }
  /deep/ .firstRow {
            line-height: 32px;
            color: #525252 !important;
            letter-spacing: 2px !important;
            span {
              color: #525252 !important;
            }
          }
}
</style>