<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="detail/editortitletime" />
    <div class="editor_title">
      <div class="title_title">{{ detailData.basicTitle }}</div>
      <div class="editor_time">{{ tiem(detailData.contentShowTime?detailData.contentShowTime:detailData.basicDateTime) }}</div>
    </div>
    <div v-html="detailData.articleContent" class="cont-txt p16-20"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("detail", ['detailData'])
  },
  mounted() { },

  methods: {
    tiem(val) {
      if(val){
        return dayjs(val).format('YYYY-MM-DD')
      }
      return
    }
  },
};
</script>

<style lang="less" scoped>
// .w100 {
//   width: calc(100% - 40px);
//   padding: 0 20px;
// }
.editor_title {
  margin: 20px 0 0 0;
  text-align: center;

  .title_title {
    font-size: 18px;
    font-weight: bold;
  }

  .editor_time {
    // text-align: center;
    font-size: 14px;
    color: #525252;
    height: 20px;
    line-height: 20px;
    margin: 16px 0 2px 0;
  }
}
</style>