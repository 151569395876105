<template>
  <div class="fullBox">
   <!-- <card :cardData="cardData"/> -->
   <div class="fullBox-title">
      <div class="fullBox-zyjs">
        {{cardData.title}}
      </div>
      <div class="fullBox-gd">
        <a :href="centralSpiritData.moreLink">更多</a>
        <!-- <a>更多</a> -->
        <img src="../../../../assets/right_t.png" alt="">
      </div>
   </div>
   <div class="fullBox-box" v-for="(item,index) in centralSpiritData.list" :key="index">
    <div style="display:flex">
      <div class="fullBox-boxIcon">
        <img src="../../../../assets/100icone.png" alt=""/>
      </div>
      <div class="fullBox-boxContent">
        <a :href="item.link">{{item.name}}</a>
      </div>
    </div>
    <div class="fullBox-boxTime">{{item.ds_time}}</div>
   </div>
  </div>
</template>

<script>
import { mapActions, mapState,mapMutations } from 'vuex';
import card from "../../../../components/card2/index.vue"
export default {
  data() {
    return {
      // value:[
      //   {
      //     name:'中央精神',
      //     dynamicClass: 'list-item-1',
      //     value:"centralSpirit"
      //   },
      //   {
      //     name:'科技部工作',
      //     dynamicClass: 'list-item-1',
      //     value:"scienceWork"
      //   }
      // ],
      cardData:{
        title:'中央精神',
        value:'centralSpirit'
      },
      // cardData2:{
      //   title:'科技部工作',
      //   value:"scienceWork"
      // }
    };
  },
  components:{
    card
  },
  serverPrefetch(){
    this.initId()
    return this.getCentralSpiritData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.initId()
      this.getCentralSpiritData()
      // this.getScienceWorkData()
    }
  },
  computed:{
    ...mapState("da_spiritWork",["centralSpiritData","scienceWorkData"])
  },
  methods: {
    ...mapActions("da_spiritWork",["getCentralSpiritData"]),
    ...mapMutations('da_spiritWork',['setId','setPage']),
    initId(){
      this.setId('1158898879362982365')
      this.setPage('6')
    }
  },
};
</script>

<style lang="less" scoped>
.fullBox-box {
  display: flex;
  padding: 0 20px 0 23px;
  margin-top: 20px;
  justify-content: space-between;
  .fullBox-boxIcon{
    margin-right: 6px;
  }
  .fullBox-boxContent {
    width: 381px;
    overflow: hidden;
    text-overflow:ellipsis;
    a {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      &:hover{
        color: #999999 !important;
      }
    }
  }
}
.fullBox {
  background: url('../../../../assets/btom.png') no-repeat;
  background-size: 100%;
}
.fullBox-title{
  line-height: 45px;
  // margin-left: 50px;
  font-size: 16px;
  font-weight: 500;
  width: 540px;
  justify-content: space-between;
  color: #B20B0C;
  display: flex;
  // padding: 0 20px;
  padding-left: 30px;
  .fullBox-zyjs{
    margin-left: 20px;
  }
  .fullBox-gd{
    a{
      font-size: 14px;
      color: #B20B0C;
      &:hover{
        color: #B20B0C !important;
      }
    }
  }
  // .fullBox-gd {
  //   margin-right: 446px;
  // }
  // div:nth-child(2){
  //   margin-right: 476px;
  // }
}
.line {
  width: 100%;
  height: 1px;
  // border-bottom:1px dashed #C2C2C2;
  // margin: 15px 0 10px 0;
}
.fullBox-boxTime {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
// .centralSpiritCard {
//   height: 140px;
// }
// .centralSpiritCard2 {
//   margin-top: 10px;
// }
</style>