<template>
  <div class="w100">
    <!-- <input type="hidden" name="pageRouter" value="dangshi/detail/centralspirit"/> -->
    <input type="hidden" name="pageRouter" value="meeting20/detail/centralSpirit"/>
    <div class="list_box">
      <publicList :dataList="dqList"/>
      <div class="w100 flexCenter mb50 ds_page">
        <Page v-if="pageInfo" :pageInfo="pageInfo"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Page from '../../../../components/Page/index.vue'
import publicList from '../../../../components/publicList/index.vue'
export default {
  components: {
    publicList,
    Page
  },
  data() {
    return {
      // dataList: [
      //   {
      //     name: '哈哈哈',
      //     time: '2021-01-01'
      //   }
      // ],
    }
  },
  computed: {
    ...mapGetters('da_detail',['dqList','pageInfo'])
  }
}
</script>

<style lang="less" scoped>
.list_box {
  padding: 10px 20px 0;
  min-height: 400px;
  /deep/ .list-item {
    padding-top: 20px;
    a{
      color: #333;
      &:hover {
        color: red !important;
      }
    }
    span {
      margin-right:  0px !important;
      color: #999;
    }
  }
}
.ds_page {
  margin: 20px 0;
}
</style>