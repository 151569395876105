<template>
  <div class="fullBox">
    <card :dataShow="dynamicData" :value="value"/>
  </div>
</template>

<script>
import Card from "../../../../components/card/index.vue"
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      value:{
        name:'基层党组织动态',
        dynamicClass:'list-item-2',
        value:"dynamic"
      }
    };
  },
  components:{
    Card
  },
  serverPrefetch(){
    return this.getDynamicData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development"){
      this.getDynamicData()
    }
  },
  computed:{
    ...mapState("ds_dynamic",["dynamicData"])
  },
  methods: {
    ...mapActions("ds_dynamic",["getDynamicData"])
  },
};
</script>

<style lang="less" scoped>
</style>