<template>
  <div class="w100">
    <input type="hidden" name="pageRouter" value="/">

    <Banner />

    <div class="home w100">
      <div class="home-content mainBox">
        <Search></Search>

        <div class="home-content-list mb20">
          <div class="home-content-list-item home-content-list-l">
            <News></News>
          </div>

          <div class="home-content-list-item home-content-list-r">
            <Academic />
          </div>
        </div>

        <div class="home-content-list1 mb20">
          <div class="home-content-list-item home-content-list-l">
            <Science></Science>
          </div>

          <div class="home-content-list-item home-content-list-r">
            <ThinkTank />
          </div>
        </div>

        <div class="home-content-list2 mb20">
          <div class="home-content-list-item home-content-list-l">
            <Document></Document>
          </div>

          <div class="home-content-list-item home-content-list-r">
            <Periodical></Periodical>
          </div>
        </div>

        <Links/>

      </div>

      <!-- <div class="pop popl" :style="{ top: pop_top + 'px' }">
        <a :href="list.baseUrl + '/472/474/index.html'" target="_blank">
          <img :src="dangImg" />
        </a>
      </div> -->

      <div class="pop popr" :style="{ top: pop_top + 'px' }">
        <OneStop />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "./components/search/index.vue"; /* 搜索 */
import News from "./components/news/index.vue"; /* 新闻动态 */
import Academic from "./components/academic/index.vue"; /* 科情学术 */
import Science from "./components/science/index.vue"; /* 科技热点 */
import ThinkTank from "./components/thinkTank/index.vue"; /* 智库视野 */
import Document from "./components/document/index.vue"; /* 著作论文 */
import Periodical from "./components/periodical/index.vue"; /* 期刊出版 */
import OneStop from "./components/oneStop/index.vue"; /* 一站式服务 */
import dangImg from "../../assets/0326.jpeg"; /* 深入开展图片 */
import Banner from "./components/banner";
import Links from "../../components/links/index.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      pop_top: 25,
      dangImg,
    };
  },
  computed: {
    ...mapState('footer',['list'])
  },
  components: {
    Search,
    News,
    Academic,
    Science,
    ThinkTank,
    Document,
    Periodical,
    Links,
    OneStop,
    Banner,
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
    // (()=>{
    //   let bodyStyle = document.body.style;
    //   bodyStyle['-webkit-filter'] = `grayscale(100%)`
    // })()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.calculate);
    window.removeEventListener("resize", this.calculate);
  },
  serverPrefetch(){
    return this.initList()
  },
  methods: {
    ...mapActions("footer",["initList"]),
    init() {
      window.addEventListener("scroll", this.calculate);
      window.addEventListener("resize", this.calculate);

      setTimeout(()=>{
        this.calculate();
      }, 500)
    },
    calculate() {
      this.$nextTick(() => {
        let headerDom = document.getElementById("home_header");
        let banner = document.getElementById("banner");
        let headerH = headerDom.offsetHeight + banner.offsetHeight;

        let homeST = window.pageYOffset;
        let num = 24;

        let h = homeST - headerH;
        h = h > 0 ? h : 0;

        this.pop_top = h + num;
      });
    },
  },
};

</script>

<style lang="less">
@import url("../../styles/home.less");
</style>

<style lang="less" scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .home-content {
    // width: 984px;
    min-height: 500px;
  }
}
.home-content-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 392px;

  .home-content-list-l {
    width: calc(100% - 410px);
    height: 100%;
  }
  .home-content-list-r {
    width: 390px;
    height: 100%;
  }
}
.home-content-list1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 345px;

  .home-content-list-l {
    width: calc(100% - 410px);
    height: 100%;
  }
  .home-content-list-r {
    width: 390px;
    height: 100%;
  }
}
.home-content-list2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 340px;
  .home-content-list-l {
    // width: calc(100% - 440px);
    width: 580px;
    height: 100%;
  }

  .home-content-list-r {
    // width: 420px;
    width: calc(100% - 600px);
    height: 100%;
  }
}
.home-content-list-item {
  box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.03);
  // overflow: hidden;
}

.pop {
  width: 212px;
  min-height: 275px;
  position: absolute;
  // opacity: 0.9;
  z-index: 2;
}

// .pop:hover {
//   opacity: 1;
// }

.popl {
  left: 10px;
  img {
    width: 100%;
    height: auto;
  }
}

.popr {
  right: 10px;
  width: 202px;
}
</style>
