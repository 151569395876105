<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/disciplines"/>
    <disciplines-list :dataList="articleVOList"/> 
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import disciplinesList from './component/disciplinesList.vue'
  export default {
    components:{
      disciplinesList
    },
    data(){
      return {
      }
    },
    computed:{
      ...mapGetters("detail",["articleVOList"])
    }
  }
</script>

<style lang="less" scoped>

</style>