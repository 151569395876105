<template>
  <div class="w100 menuList">
    <div class="menuList-title w100 mb30">
      <h3>{{topColumnTitle}}</h3>
      <p class="fontEn1 mb20">{{topRemark}}</p>

      <form  
      ref="searchDetailDataForm"
      :action="action"
      method="post">
        <div class="menuSearchBox flexYCenterXSB w100">
          <input type="text" placeholder="请输入检索内容" name="basic_title" v-model="basic_title" />
          <a class="btnSerch" @click="search"></a>
        </div>
        <input type="hidden" id="statFlag" name="statFlag" value="true" />
      </form>
    </div>

    <b class="divider2" v-if="subColumnVOList && subColumnVOList.length>0"></b>
    <div class="menuBox w100">
      <div v-for="item in subColumnVOList" :key="item.id" :class="`menuBoxItem w100 ${isSel(item) ?'selP':''}`">
        <a class="flexYCenterXSB menuBoxItemA" :href="item.subCategoryListVOList[0] && item.subCategoryListVOList[0].linkUrl || item.linkUrl">
          <span>{{item.categoryTitle}}</span>

          <b class="icon-r"></b>
        </a>

        <div class="divider2 divider2P"></div>
        
        <div class="menuBoxItemCBox" v-if="item.subCategoryListVOList.length>0 && (isSel(item))">
          <a v-for="(itemc,index) in item.subCategoryListVOList" :href="itemc.linkUrl" :key="itemc.url" :class="`flexYCenterXSB menuBoxItemCA ${isSelC(itemc, index) ?'menuBoxItemCASel':''}`" >
            <span>{{itemc.categoryTitle}}</span>
            <b></b>
          </a>

        </div>

        <div class="divider2" v-if="item.subCategoryListVOList.length>0 && (isSel(item))"></div>
        
      </div>
    </div>
    

    
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      basic_title: "",
      // action: process.env.VUE_APP_BASE_API + "/cms/1/search.do",
    };
  },
  computed: {
    ...mapGetters("detail", ["topColumnTitle", "topRemark", "subColumnVOList","crumbsList"]),
    ...mapState("detail", ["query"]),
    ...mapState("footer", ["list"]),
    action(){
      let url = this.list.appUrl + "/cms/1/search.do";

      return url;
    },
    custColumn() {
      let obj = null;

      if (this.subColumnVOList.length === 0) return {};

      this.subColumnVOList.map((itemP) => {
        if (itemP.id === this.query.columnId) {
          obj = itemP;
        }

        if (itemP.subCategoryListVOList.length > 0 && !obj) {
          itemP.subCategoryListVOList.map((itemC) => {
            if (itemC.id === this.query.columnId || itemC.id === this.query.originalId) {
              obj = itemC;
            }
          });
        }
      });

      return obj || {};
    },
    // isSelChild(){
    //   let isSel = false;
    //   this.subColumnVOList.map((itemP) => {
    //     if (itemP.subCategoryListVOList.length > 0) {
    //       itemP.subCategoryListVOList.map((itemC) => {
    //         if (itemC.id === this.query.columnId || itemC.id === this.query.originalId) {
    //           isSel = true
    //         }
    //       });
    //     }
    //   });
    //   return isSel
    // },

    custCrumb(){
      let crumbs = this.crumbsList? this.crumbsList[2] : []

      return crumbs?.title??""
    }
  },
  mounted() {},

  methods: {
    isSel(item) {
      // console.log("@@@@@@", this.custColumn)
      return (
        item.id === this.query.columnId ||
        item.id === this.custColumn.categoryId ||
        item.id === this.query.originalId || 
        item.categoryTitle === this.custCrumb 
      );
    },
    isSelC(itemc, index){
      let isSel = false;
      // if(this.isSelChild){
        if(itemc.id===this.query.columnId || itemc.id === this.query.originalId){
          isSel = true;
        }
      // }else if(index===0){
      //   isSel = true
      // }

      return isSel      
    },
    async search() {
      if (!this.basic_title) return;

      let form = this.$refs.searchDetailDataForm;

      form.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.menuList {
  padding-top: 15px;
  .menuList-title {
    h3 {
      font-size: 20px;
      color: #327cc0;
      display: block;
      letter-spacing: 0px;
      border-left: 4px solid #327cc0;
      padding-left: 10px;
      margin-bottom: 5px;
      line-height: 18px;
    }
    p {
      padding-left: 14px;
    }

    .menuSearchBox {
      input {
        outline: none;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-right: none;
        padding: 5px 10px;
        width: calc(100% - 58px);
        font-size: 14px;
        height: 18px;
      }
      .btnSerch {
        width: 48px;
        height: 36px;
        background: url("../../../../assets/icon-search.svg") no-repeat;
        -webkit-print-color-adjust: exact;
        background-size: cover;
        position: relative;
        right: 3px;
      }
    }
  }
}

.menuBox {
  .menuBoxItem {
    .menuBoxItemA {
      padding: 15px 4px;
      width: calc(100% - 8px);
      font-size: 14px;
      color: #666666 !important;
      min-height: 18px;
      line-height: 18px;
      font-weight: bold;
      word-break: break-all;
      b{
        width: 18px;
      }
      span{
        width: calc(100% - 28px);
      }
    }
  }

  .selP {
    .menuBoxItemA {
      font-size: 16px;
    }
    .icon-r {
      width: 18px;
      height: 18px;
      background: url("../../../../assets/icon-r.svg") no-repeat;
      -webkit-print-color-adjust: exact;
      background-size: 100% 100%;
    }

    .divider2P {
      height: 4px;
      background: #327cc0;
      -webkit-print-color-adjust: exact;
    }
  }

  .menuBoxItem:hover {
    .menuBoxItemA span {
      color: #327cc0;
    }
  }
}

.menuBoxItemCBox {
  padding: 10px 5px;
  width: calc(100% - 10px);
  .menuBoxItemCA {
    line-height: 1em;
    color: #666;
    font-size: 14px;
    padding: 10px 0;
    b {
      width: 9px;
      height: 2px;
      background: #d7d7d7;
      -webkit-print-color-adjust: exact;
    }
  }
  .menuBoxItemCA:hover {
    color: #327cc0;
    b {
      background: #006ebd;
      -webkit-print-color-adjust: exact;
    }
  }
  .menuBoxItemCASel {
    color: #006ebd;
    b {
      background: #006ebd;
      -webkit-print-color-adjust: exact;
    }
  }
}
</style>