import req from "../../req/index"
import utils from "../../utils"

export default {
  namespaced: true,
  state:{
    infoListOne:{},
    infoListTwo:{},
    infoListThree:{}
  },
  actions:{
    async getInfoListOne({commit}){
      let res = await req.ajax({
        url:'/static/generate/getArticles/278/1/normal',
        method: "get"
      })
      commit("setInfoListOne",{
        list: utils.initHomeList(res.articleVOS),
        moreLink: res.moreLink
      })
      return res
    },
    async getInfoListTwo({commit}){
      let res = await req.ajax({
        url:'/static/generate/getArticles/279/1/normal',
        method: "get"
      })
      commit("setInfoListTwo",{
        list: utils.initHomeList(res.articleVOS),
        moreLink: res.moreLink
      })
      return res
    },
    async getInfoListThree({commit}){
      let res = await req.ajax({
        url:'/static/generate/getArticles/280/1/normal',
        method: "get"
      })
      commit("setInfoListThree",{
        list: utils.initHomeList(res.articleVOS),
        moreLink: res.moreLink
      })
      return res
    }
  },
  mutations:{
    setInfoListOne(state,data){
      state.infoListOne = data;
    },
    setInfoListTwo(state,data){
      state.infoListTwo = data;
    },
    setInfoListThree(state,data){
      state.infoListThree = data;
    }
  }
}