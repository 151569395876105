<template>
  <div class="w100 menu-box flexCenter">
    <div class="dsMainBox">
        <div v-for="(item,index) in data.columnList || []" :key="index" @click="btn(index)" :class="isSel(item,index) ? 'active' : '' ">
          <a :href="item.linkUrl">{{item.title}}</a>               
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  data() {
    return {
      curindex: null
    };
  },
  computed: {
    ...mapState('ds_menu' , ['data']),
    ...mapGetters("ds_detail", ["crumbsList"]),
    isDsHome(){
      if (typeof window === 'object') {
        let url = window.location.href 
        
        if(url.indexOf("/472/474") !== -1 || this.$route.path === "/dangshi"){
          return true
        }else{
          return false
        }
      }
    }
  },
  mounted() {
    this.initData()
  },
  serverPrefetch() {
    return this.initData()
  },
  mounted() {
    if(process.env.NODE_ENV == "development"){
      this.initData()
    }
  },
  methods: {
    ...mapActions('ds_menu',['initData']),
    btn(index) {
      this.curindex = index
    },
    isSel(item,index){
      let sel = false;

      if(item.title === (this.subColumnVOList&&this.subColumnVOList[0].categoryTitle)){
        sel = true;
      }else if(this.isDsHome && index === 0){
        sel = true
      }
      return sel;
    }
  },
};
</script>

<style lang="less" scoped>
.menu-box{
  height: 50px;
  
  .dsMainBox{
    background: rgb(178, 11, 12);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2px;
    div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: #fff;
        &:hover{
          color: #fff !important;
        }
      }
    }
    div:hover {
      background-color: #820200;
    }
    .active {
      background-color: #820200;
    }
  }
}
</style>