import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/dangqun/layout/index.vue'
import Home from '../views/dangqun/views/home/index.vue'
import Detail from "../views/dangqun/views/detail/index.vue"
import Editor from "../views/dangqun/views/detail/views/editor/index.vue"
import LearnEducation from '../views/dangqun/views/detail/views/learnEducation/index.vue'
import detailMore from '../views/dangqun/views/detail/views/detailmore/index.vue'
// import centralSpirit from '../views/dangqun/views/detail/views/centralSpirit/index.vue'
import EditorTime from '../views/dangqun/views/detail/views/editorTime/index.vue'

Vue.use(VueRouter)

export let comMapDQ = {
  /* 官网 */
  "dangqun": {
    "layout": Layout,
    "/": Home,
    "detail": Detail,
    "editor": Editor,
    "editortime": EditorTime,
    "learneducation": LearnEducation,
    "detailmore": detailMore
  }
}

const routes = [
  {
    path: '/dangqun',
    name: "dangqun",
    component: Layout,
    children: [{
      path: '/',
      /* 首页 */
      name: 'Home',
      component: Home
    },{
      path: 'detail',
      /* 详情|首页 */
      name: 'detail',
      component: Detail,
      children: [
        {
          path: 'editor',
          /*富文本*/
          name: 'editor',
          component: Editor,
        },
        {
          path: 'learneducation', //学习教育
          name: 'learneducation',
          component: LearnEducation
        },
        {
          path: 'editortime',//富文本时间
          name: 'editortime',
          component: EditorTime
        },
        {
          path: 'detailmore', //更多详情
          name: 'detailmore',
          component: detailMore
        }
        // {
        //   path: 'centralspirit', //中央精神
        //   name: 'centralspirit',
        //   component: centralSpirit
        // }
      ]
    }]
  },
]

export default routes;
