<template>
  <div class="research">
    <input type="hidden" name="pageRouter" value="detail/researchTeam"/>
    <research-team-list :listData="articleVOList" :pageInfo="pageInfo"/>
  </div>
</template>

<script>
import ResearchTeamList from "./component/researchTeamList.vue"
import { mapGetters } from 'vuex'
  export default {
    components:{
      ResearchTeamList
    },
    computed:{
      ...mapGetters("detail",["articleVOList","pageInfo"])
    }
  }
</script>

<style lang="less" scoped>
.research {
  width: calc(100% - 40px);
  margin: 0 auto;
}
</style>