<template>
  <div class="boxPage">
    <div class="total">共{{ pageInfo.totalNum }}条</div>
    <div class="total">
      {{ pageInfo.curPageNo }}/{{ pageInfo.totalPageNum }}
    </div>
    <div class="pagesNumber">
      <div
        :class="pageInfo.curPageNo === 1 ? 'homePageopt': ''"
        @click="btn('homePage')"
      >
        首页
      </div>
      <div
        :class="pageInfo.curPageNo === 1 ? 'homePageopt': ''"
        @click="btn('previousPage')"
      >
        上一页
      </div>
      <div
        @click="btn('nextPage')"
        :class="pageInfo.curPageNo === pageInfo.totalPageNum ? 'homePageopt': ''"
      >
        下一页
      </div>
      <div
        @click="btn('lastPage')"
        :class="pageInfo.curPageNo === pageInfo.totalPageNum ? 'homePageopt': ''"
      >
        未页
      </div>
    </div>
    <div class="pageJump">
      <span>转到</span>
      <input
        @keyup.enter="handleAddBook"
        class="pageInput"
        type="number"
        v-model="skipNum"
      />
      <span>页</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: {
      type: Object,
      default: () => ({
        curPageNo: 0,
        page: "",
        totalNum: 0,
        totalPageNum: 0,
        // curPageNo: 1,
        // page: "https://zxsweb.sundot.cn/html/1//165/167/list26.html",
        // totalNum: 20,
        // totalPageNum: 26,
      }),
    },
    // pagesize,
  },
  data() {
    return {
      skipNum: null,
      title: [
        { name: "首页", key: "homePage", style: "homePageopt" },
        { name: "上一页", key: "previousPage", style: "homePageopt" },
        { name: "下一页", key: "nextPage" },
        { name: "未页", key: "lastPage" },
      ],
    };
  },
  methods: {
    btn(key) {
      if(this.pageInfo.totalPageNum <= 1){
        return
      }
      let skipNum = this.pageInfo.curPageNo;

      switch (key) {
        case "homePage":
          skipNum = "index";
          break;
        case "previousPage":
          skipNum--;
          break;
        case "nextPage":
          skipNum++;
          break;
        case "lastPage":
          skipNum = this.pageInfo.totalPageNum;
          break;
      }
      console.log(skipNum)
      this.go(skipNum)
    },
    handleAddBook() {
      if(this.pageInfo.totalPageNum <= 1){
        return
      }
      this.go(this.skipNum);
    },
    go(skipNum) {
      let url = this.pageInfo.pageUrl;

      if (skipNum <= 1) {
        skipNum = "index";
      }
      if (skipNum > this.pageInfo.totalPageNum) {
        skipNum = this.pageInfo.totalPageNum;
      }

      if (skipNum !== "index") {
        url = url + "list" + skipNum + ".html";
      } else {
        url = url + skipNum + ".html";
      }

      if (this.pageInfo.page === url) return;

      window.location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
.boxPage {
  display: flex;
  justify-content: center;
  align-items: center;
  .total {
    color: #999;
    font-size: 14px;
    cursor: pointer;
    margin-right: 20px;
  }
  .pagesNumber {
    display: flex;
    // border-top-left-radius: 4px;
    font-size: 14px;
    color: #999;
    div {
      border: 1px solid #ddd;
      border-right: none;
      padding: 10px 15px;
      cursor: pointer;
    }
    div:hover {
      color: #327cc0;
      background: #eee;
    }
    div:last-child {
      border-right: 1px solid #ddd;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    div:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .pageJump {
    // color: #999;
    font-size: 14px;
    margin-left: 20px;
  }
  .pageInput {
    width: 33px;
    height: 33px;
    margin: 0 5px;
    border: #ccc 1px solid;
    -moz-appearance: textfield;
  }
  /* 谷歌 */
  .pageInput::-webkit-outer-spin-button,
  .pageInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  .homePageopt {
    opacity: 0.5;
  }
  .homePageopt:hover {
    color: #999 !important;
    background: #fff !important;
  }
  input {
    outline: none;
  }
}
</style>