import req from '../../req/index';
import utils from "../../utils/index";

const getOne = (columnId)=>{
  return req.ajax({
    url: `/static/generate/getArticles/${columnId}/1/firstTop`,
    method: "get"
  })
}

export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initList({ commit }) {

      let arrRes = await Promise.all([getOne("419"), getOne("476"), getOne("477"), getOne("478"), getOne("479"), getOne("480")])

      
      let list = [];

      arrRes.map((item)=>{
        list = [...list, ...utils.initHomeList(item.articleVOS)]
      })

      commit('setList', {
        list
      })
      
      return arrRes;
    },
  },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
  },
}