<template>
  <div>
    <div class="perspectivedetail_title">
      <div class="title_title">{{detailData.basicTitle}}</div>
      <div>
        <ul class="title_ul">
          <slot name="modelData"></slot>
           <!-- <li>
            <span>作者：</span>
            <span>{{detailData.articleAuthor}}</span>
          </li> -->

          <!-- <li v-if="detailData.modelData.Outputorganization">
            <span>{{detailData.modelData ? detailData.modelData.Outputorganization.fieldTipsName:''}}：</span>
            <span>{{detailData.modelData ? detailData.modelData.Outputorganization.fieldData:''}}</span>
          </li>
          <li v-else>aaa</li>
          <li v-if="detailData.modelData.Submittingagency">
            <span>{{detailData.modelData.Submittingagency.fieldTipsName}}：</span>
            <span>{{detailData.modelData.Submittingagency.fieldData}}</span>
          </li>
          <li v-else>111</li>
          <li>
            <span>{{detailData.modelData.outputdate.fieldTipsName}}：</span>
            <span>{{time(detailData.modelData.outputdate.fieldData)}}</span>
          </li>
          <li>
            <span>{{detailData.modelData.Releasedate.fieldTipsName}}：</span>
            <span>{{time(detailData.modelData.Releasedate.fieldData)}}</span>
          </li>
          <li>
            <span>{{detailData.modelData.abstract.fieldTipsName}}：</span>
            <div class="title_abstract">{{detailData.modelData.abstract.fieldData}}</div>
          </li> -->
          <li>
            <span>关键字：</span>
            <span style="color:#327cc0;">{{detailData.articleKeyword}}</span>
          </li>
          <div v-html="detailData.articleContent" class="title_articleContent"></div>
          <slot name="articleContent"></slot>
          <!-- <div class="title_btn">
            <span>{{detailData.modelData.link.fieldTipsName}}：</span>
            <a :href="detailData.modelData.link.fieldData">{{detailData.modelData.link.fieldData}}</a>
          </div> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props:{
    detailData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    time(val) {
      return dayjs(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="less" scoped>
.perspectivedetail_title {
  .title_title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .title_ul {
    // margin-bottom: 20px;
    padding-left: 20px;
    li {
       margin-bottom: 20px;
       font-size: 14px;
       span:nth-child(1){
        color: #333;
        font-weight: bold;
       }
    }
  }
}
.title_abstract {
  text-indent: 2em;
  margin-top: 10px;
  line-height: 28px;
  text-align: justify;
}
.title_articleContent {
  /deep/ p {
    margin-bottom: 15px;
  }
}
.title_btn {
  margin: 60px 0 10px  0;
  a {
    color: #2e2e2e;
    &:hover {
      color: #327cc0;
    }
  }
}
</style>