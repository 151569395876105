import dayjs from "dayjs"

class Utils {
  getQery(url) {
    // 编写代码实现
    // 1.查找?的下标
    var index = url.indexOf("?");
    // 2.截取?后面的字符串
    var str = url.slice(index + 1);
    // 3.根据&将字符串转成数组
    var arr = str.split("&");

    // 4. 声明对象用来存储信息
    var obj = {};
    for (var i = 0; i < arr.length; i++) {
      // arr[0] action=home
      var arr2 = arr[i].split('=');
      // arr2的第一个成员做属性, 第二个成员做值
      obj[arr2[0]] = arr2[1];
    }
    return obj;
  }

  initHomeList(list) {
    return list.map((item) => {
      return {
        ...item,
        id: item.articleID,
        name: item.basicTitle,
        link: item.articleLinkURL,
        image: item.basicThumbnails || "/resource/404.jpeg",
        content: item.basicDescription,
        time: dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("MM-DD"),
        dq_time: dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("YYYY/MM/DD"),
        ds_time: dayjs(item.contentShowTime? item.contentShowTime: item.basicDateTime).format("YYYY/MM/DD"),
        kjrd_time:dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("MM-DD"),
        xsdt_time:dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("YYYY-MM-DD"),
        year: dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("YYYY"),
        en_time: dayjs(item.contentShowTime? item.contentShowTime:item.basicDateTime).format("YYYY.MM.DD")
      }
    })
  }
  encolumnsList(list) {
    // console.log(list)
    return list.map((item) => {
      return {
        ...item,
        basicThumbnails: item.categorySmallImg,
        basicTitle: item.columnKeyword,
      }
    })
  }
}

export default new Utils();