<template>
  <div class="header-box w100 ">
    <img class="bg" :src="bg" alt="" />
    <div class="w100 flexCenter topBtn">
      <div class="dqMainBox">
        <div></div>
        <a :href="data.homeUrl">返回首页</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import bg from "../../../assets/headerBg.jpg";
import bg from "../../../assets/headerBgs.jpg";
export default {
  data() {
    return {
      bg,
    };
  },
  computed:{
    ...mapState('dq_menu' , ['data'])
  },
  mounted() {},
  serverPrefetch() {
    return this.initData()
  },
  methods: {
    ...mapActions('dq_menu',['initData']),
  },
};
</script>

<style lang="less" scoped>
.header-box{
  position: relative;
  img{
    display: block;
  }
}
.bg {
  width: 100%;
  height: auto;
}
.topBtn{
  position: absolute;
  top: 0;
  left: 0;
}
.dqMainBox {
  display: flex;
  justify-content: space-between;
  a {
    font-size: 16px;
    background: #b20b0c;
    color: #fff;
    text-align: center;
    border-radius: 0px 0px 12px 12px;
    width: 100px;
    height: 42px;
    line-height: 42px;
    padding: 0;
  }
  a:hover{
    color: #fff !important;
  }
}
</style>