<template>
  <div class="disciplines-list"> 
    <ul>
      <li class="disciplines-list-item" v-for="(item,index) in dataList" :key="index">
        <i class="nums">{{format(index+1) }}</i>
        <div class="tit">
          <b>{{item.basicTitle}}</b>
          <p>{{item.articleKeyword}}</p>
        </div>
        <div class="txt">
          <a :href="item.articleLinkURL">
            <img :src="item.basicThumbnails || '/resource/404.jpeg'" alt="">
            <p>{{item.basicDescription}}</p>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props:{
      dataList:{
        type: Array,
        default: ()=>[]
      }
    },
    methods:{
      format(val){
        if(val < 10){
          return '0'+val
        }else {
          return val
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.disciplines-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 975px;
    justify-content: flex-start;
    .disciplines-list-item {
      width: calc(25% - 46.25px);
      height: 325px;
      margin: 25px 23px 25px 23px;
      background: #FBFBFB;
      i {
        display: inline-block;
        text-align: center;
        line-height: 35px;
        font-size: 25px;
        width: 35px;
        height: 35px;
        margin: 23px 81.25px;
        color: rgb(182,182,182);
      }
      .tit {
        height: 50px;
        b {
          display: block;
          text-align: center;
          font-size: 16px;
          color: #3583e8;
          font-weight: 400;
        }
        p {
          text-align: center;
          font-size: 12px;
          color: #5a5a5a;
        }
      }
      .txt {
        padding: 0 20px;
        img {
          width: 155px;
          margin:  8px 0;
        }
        p {
          font-size: 12px;
          color: #5a5a5a;
        }
      }
      &:hover {
        background: #ECECEC;
      }
    }
  }
}

</style>