<template>
  <div class="w100 homeMainBox">
    <div class="w100">
      <header class="w100">
        <Menu></Menu>
      </header>

      <section class="w100">
        <router-view></router-view>
      </section>
    </div>

    <footer class="w100">
      <Footer />
    </footer>
  </div>
</template>

<script>
import Menu from "./components/menu/index.vue"
import Footer from "./components/footer/index.vue"

export default {
  data() {
    return {};
  },
  components:{
    Menu,
    Footer,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.homeMainBox{
  min-width: 1296px !important;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
</style>