<template>
  <div class="stabBox">
    <STab v-model="list" @custMore="thisCust" :moreLink="moreLink">
      <template slot="icon">
        <span class="icon"></span>
      </template>
      <template slot="doc">
        <ul class="zz-list">
          <li class="zz-list-li" v-for="(item,index) in bookData.list" :key="index">
            <div class="img-container">
              <img :src="item.basicThumbnails" >
            </div>
            <div class="content">
              <div class="message-title">{{item.basicTitle}}</div>
              <div class="message">
                <p class="message-detail">
                  <span>作者：{{item.articleAuthor}}</span>
                  <span>{{item.modelData.pub_org.fieldTipsName}}：{{item.modelData.pub_org.fieldData}}</span>
                </p>
                <p class="message-detail">
                  <span>{{item.modelData.department.fieldTipsName}}：<a :href="bookData.baseUrl + item.modelData.deptLink.fieldData">{{item.modelData.department.fieldData}}</a></span>
                  <span>出版日期：{{item.modelData.fini_time.fieldData}}</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <template slot="paper">
        <list-3 :lists="thePaperData.list" :value="list[1]"></list-3>
      </template>
    </STab>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import STab from "../../../../components/STab/index.vue";
import List3 from "../../../../components/STab/list3.vue"
export default {
  data() {
    return {
      list: [
        {
          name: "著作",
          value: "doc",
          paddingSize: "24px"
        },
        {
          name: "论文",
          value: "paper",
          paddingSize: "24px"
        },
      ],
      moreLink:''
    }
  },
  components: {
    STab,
    List3
  },
  computed:{
    ...mapState("document", ["bookData", "thePaperData"]),
  },
  mounted() {
    this.getBookData()
    this.getThePaperData()
  },
  serverPrefetch(){
    return Promise.all([
      this.getBookData(),
      this.getThePaperData()
    ])
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.getBookData()
      this.getThePaperData()
    }
  },

  methods: {
    ...mapActions("document", ["getBookData", "getThePaperData"]),
    thisCust(data){
      if(data === 'doc' ){
        this.moreLink = this.bookData.moreLink
      }else{
        this.moreLink = this.thePaperData.moreLink
      }
    }
  },
};
</script>

<style lang="less" scoped>
.stabBox {
  width: 100%;
  height: 100%;
  background: #fff;
}
.icon{
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url("../../../../assets/zhuzuo.svg") no-repeat;
  background-size: cover;
}
// 著作板块
.zz-list {
  li {
    display: flex;
    height: 114px;
    justify-content: space-between;
    margin-bottom: 21px;

    .img-container {
      width: 114px;
      height: 114px;
      img {
        display: block;
        height: 114px;
        margin: 0 auto;
      }
    }
    .content {
      width: calc(100% - 114px);
      padding: 0 0px ;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 15px;
      .message-title {
        // width: 100%;
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        color: #333;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
      }
      .message {
        // width: 419px;
        font-size: 14px;
        color: #666666;      
        margin-top: 30px;
        a {
          color: #666666;      
        }
 
        .message-detail {
          height: 20px;
          line-height: 20px;
          margin-top: 10px;
          span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          span:first-child {
            width: calc(50% - 40px);
            margin-right: 40px;
          }
          span:last-child {
            width: 50% ;
          }
        }
      }
    }

  }
}
</style>