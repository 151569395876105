<template>
  <div class="w100">
    <card-2 :cardData="cardData" :moreLink="youthData.moreLink"/>
    <list-public :dataList="youthData.list" :backImg="backImg"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import card2 from "../../../../components/card2/index.vue"
import listPublic from "../../../../components/listpublic/index.vue"
export default {
  data() {
    return {
      cardData:{
        title:'青年之家',
        value:'youth'
      },
      backImg:{ backImg:'listData_bakimg1'},
    };
  },
  components:{
    card2,
    listPublic
  },
  computed:{
    ...mapState("dq_youth",["youthData"])
  },
  serverPrefetch(){
    return this.getYouthData()
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.getYouthData()
    }
  },

  methods: {
    ...mapActions("dq_youth",["getYouthData"])
  },
};
</script>

<style lang="less" scoped>
</style>