import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    menuList: [],
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initMenu({ commit }) {
      let res = await req.ajax({
        url: "/static/generate/getCategoryTree",
        method: "get",
      })
      
      let dangQunRes = await req.ajax({
        url: "static/generate/getSpecialCategoryChild/403",
        method: "get",
      })
      if(dangQunRes){
        dangQunRes[0].categoryTitle = "党群园地";
        dangQunRes[0].linkUrl = dangQunRes[0].linkUrl + "402/403/419/index.html"
      }

      res = [...res, ...dangQunRes]
      commit('setMenuList', res)
      return res;
    },
  },
  mutations: {
    setMenuList(state, data) {
      state.menuList = data;
    },
  },
}