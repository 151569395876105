import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initList({ commit }) {

      let resBanner = await req.ajax({
        url: "/static/generate/getRotationNew",
        method: "post",
        data: ["2149891870557993846","1876511601529646957","1876511532810170575","1876511532810170582","1506840141409544957","1506840038330333989","1129112353448779862","1129112353448779868","1506840055510198825","1129112353448779874","1129112353448779880","1129112353448779886","1129112353448779892"]
      })
     
      // let res = await req.ajax({
      //   url: "/static/generate/getRotation/301",
      //   method: "get",
      // })
      commit('setList', resBanner)

      return resBanner;
    },
  },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
  },
}