<template>
  <div class="stabBox">
    <STab :value="list" @custMore="custMore" :moreLink="moreLink">
      <template slot="icon">
        <span class="icon"></span>
      </template>
      <template slot="news">
        <list-2 :lists="newsData.list" :value="list[0]"></list-2>
      </template>
      <!-- 通知公告 -->
      <template slot="inform">
        <ul class="bd-tzgg">
          <li
            class="tzgg-list"
            v-for="(item, index) in informData.list"
            :key="item.id || index"
          >
            <div class="bd_tz_l-time">
              <div class="time1">{{ item.time }}</div>
              <div class="time2">{{ item.year }}</div>
            </div>
            <div class="tzgg-list-item">
              <a
                :href="item.link"
                target="_self"
                >{{ item.name }}</a
              >
              <p v-if="item.content">{{ item.content }}</p>
            </div>
          </li>
        </ul>
      </template>
      <template slot="partyNews">
        <list-2 :lists="partyNewsData.list" :value="list[2]"></list-2>
      </template>
    </STab>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import STab from "../../../../components/STab/index.vue";
import List2 from "../../../../components/STab/list2.vue";

export default {
  data() {
    return {
      list: [
        {
          name: "新闻动态",
          value: "news",
          paddingSize: '15px'
        },
        {
          name: "通知公告",
          value: "inform",
          paddingSize: '15px'
        },
        {
          name: "科技部要闻",
          value: "partyNews",
          paddingSize: '15px',
          color:'#327cc0'
        },
      ],
      moreLink: ''
    };
  },
  computed: {
    ...mapState("news", ["newsData", "informData", "partyNewsData"]),
  },
  components: {
    STab,
    List2,
  },
  // Vue SSR 特殊为服务端渲染提供的一个生命周期钩子函数,返回promise
  serverPrefetch(){
    return Promise.all([this.getNewsData(), this.getInformData(), this.getPartyNewsData()])
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.getNewsData();
      this.getInformData();
      this.getPartyNewsData();
    }
  },

  methods: {
    ...mapActions("news", ["getNewsData", "getInformData", "getPartyNewsData"]),
    custMore(data) {
      if(data === 'news'){
        this.moreLink = this.newsData.moreLink
      }else if(data === 'inform'){
        this.moreLink = this.informData.moreLink
      }else{
        this.moreLink = this.partyNewsData.moreLink
      }
    },
  },
};
</script>

<style lang="less" scoped>
.stabBox {
  width: 100%;
  height: 100%;
  background: #fff;
}
.icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url("../../../../assets/iconLaba.svg") no-repeat;
  background-size: cover;
}

.tzgg-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .bd_tz_l-time {
    width: calc(15%);
    height: 66px;
    color: #327cc0;
    background: rgba(0, 125, 255, 0.05);
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .time1 {
      font-size: 18px;
      line-height: 25px;
    }
    .time2 {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .tzgg-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(80%);
    height: 52px;
    a {
      display: inline-block;
      line-height: 21px;
      color: #333;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    p {
      display: inline-block;
      line-height: 21px;
      font-size: 14px;
      color: #666;
      margin-top: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>