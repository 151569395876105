<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/researchreport"/>
    <perspective :detailData="detailData">
      <div slot="modelData" class="modelData_box">
        <li>
          <span>作&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;者：</span>
          <span>{{detailData.articleAuthor}}</span>
        </li>
        <li>
          <span>单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：</span>
          <!-- <span>{{detailData.modelData ? detailData.modelData.Company.fieldTipsName:''}}：</span> -->
          <span>{{detailData.modelData ? detailData.modelData.Company.fieldData:''}}</span>
        </li>
        <li>
          <span>{{detailData.modelData ? detailData.modelData.Outputdate.fieldTipsName:''}}：</span>
          <span>{{detailData.modelData ? time(detailData.modelData.Outputdate.fieldData):''}}</span>
        </li>
        <li>
          <span>{{detailData.modelData ? detailData.modelData.Releasedate.fieldTipsName:''}}：</span>
          <span>{{detailData.modelData ? time(detailData.modelData.Releasedate.fieldData):''}}</span>
        </li>
        <li>
          <span>{{detailData.modelData ? detailData.modelData.abstract.fieldTipsName:''}}：</span>
          <div v-html="detailData.modelData ? detailData.modelData.abstract.fieldData:''" class="title_abstract"></div>
        </li>
      </div>
    </perspective>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import perspective from '../../components/perspectivedetail'
import { mapGetters } from 'vuex';
export default {
  components: {
    perspective
  },
  computed: {
    ...mapGetters('detail',['detailData'])
  },
  methods: {
    time(val) {
      return dayjs(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="less">
// /deep/ .title_ul {

// }
.modelData_box {
  li {
    span:nth-child(2) {
      color: #327cc0 !important;
    }
  }
}
</style>