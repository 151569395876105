<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/sectionhotspot" />
    <detailCom :detailData="detailData">
      <div slot="time" class="detailtime">{{detailtime(detailData.contentShowTime?detailData.contentShowTime:detailData.basicDateTime)}}</div>
      <div slot="text" class="detailtext">
        <div>
          <span v-if="detailData.modelData">{{detailData.modelData ? detailData.modelData.link.fieldTipsName:''}}：</span>
          <div>{{detailData.modelData ? detailData.modelData.link.fieldData:''}}</div>
        </div>
        <div>
          <span>注：</span>
          <span>{{detailData.modelData ? detailData.modelData.notes.fieldData:''}}</span>
        </div>
        <div>
          <span>信息提供单位：</span>
          <span>{{detailData.modelData ? detailData.modelData.Informationprovider.fieldData:''}}</span>
        </div>
      </div>
    </detailCom>
  </div>
</template>

<script>
import detailCom from '../../components/detailcom/index.vue';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
export default {
  components: {
    detailCom
  },
  computed: {
    ...mapGetters('detail',['detailData'])
  },
  methods: {
    detailtime(val) {
      return dayjs(val).format("YYYY-MM-DD")
    }
  }
}
</script>

<style lang="less" scoped>
.detailtime {
  text-align: center;
  font-size: 14px;
  color: #525252;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  padding: 0 6px;
}
.detailtext {
  padding: 0px 20px 0px 50px;
  line-height: 40px;
  font-size: 14px;
  color: #525252;
}

/deep/p{
        span{
          line-height: 32px !important;
          font-size: 14px !important;
          font-family: Microsoft Yahei !important;
          color: #525252 !important;
          font-family: 微软雅黑, Microsoft Yahei !important;
          letter-spacing: 2px !important;
          position: relative !important;
        }
        sup {
          vertical-align: text-bottom !important;
          transform: scale(0.5);
          span {
            font-size: 12px !important;
          }
        }
        span {
          sub{
            vertical-align: -webkit-baseline-middle !important;
          }
        }
        table{
          // border: 1px solid #525252;
          td {
            border: 1px solid #525252;
          }

          p{
            text-indent: 0px!important;
            line-height: 0px !important;
            margin-bottom: 0px !important;
          }
        }
  }
</style>