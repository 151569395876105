<template>
  <div class="footer">
    <div class="w mainBox">
      <div class="foot-txt">
        <img :src="icoFooter || '/resource/404.jpeg'" alt="">
        <div class="foot-txt-p" v-html="list.footTxt"></div>
      </div>
      <div class="foot-img">
        <div class="fr foot-link">
          <a :href="oa.columnUrl || ''" class="fl-01" ref="flA"></a>
          <a :href="email.columnUrl || ''" class="fl-02" ref="flA" ></a>
          <a href="javascript:viod(0)" class="fl-03" ref="flA">
            <img class="qr-code" :src="list.imgList && list.imgList[2] && list.imgList[2].categorySmallImg || '/resource/404.jpeg'" alt="">
          </a>
        </div>
        <div class="ew-code">
          <img :src="list.imgList && list.imgList[2] && list.imgList[2].categorySmallImg || '/resource/404.jpeg'" alt=""/>
          <p>关注官方公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import icoFooter from "../../../assets/ico-footer.png"
export default {
  data() {
    return {
      icoFooter
    };
  },
  serverPrefetch(){
    return this.initList()
  },
  mounted() {
    if(process.env.NODE_ENV === "development") {
      this.initList()
    }
  },

  computed:{
    ...mapState("footer",['list']),
    ...mapGetters("footer", ["oa", "email"]),
    // icoFooter(){
    //   return `${this.list.staticUrl}/images/ico-footer.png`
    // },
    

  },

  methods: {
    ...mapActions("footer",["initList"])
  },
};
</script>

<style lang="less" scoped>
.footer{
  width: 100%;
  background: #D8D8D8;
  -webkit-print-color-adjust: exact;
  .w {
    // width: 1200px;
    height: 106px;
    margin: 0 auto;
    padding: 35px 0 25px 0;
    display: flex;
    justify-content: space-between;
    .foot-txt {
      width: 430px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 56px;
        height: 68px;
      }
      .foot-txt-p {
        width: 350px;
        font-size: 14px;
        line-height: 30px;
      }
    }
    .foot-img {
      width: 340px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .foot-link {
        a {
          display: inline-block;
          width: 56px;
          height: 56px;
          background-image: url('../../../assets/ico-link.png');
          -webkit-print-color-adjust: exact;
          margin-left: 17px;
        }
        .fl-01 {
          background-position: 0 -56px;
          &:hover {
            background-position: 0 0;
          }
        }
        .fl-02 {
          background-position: -73px -56px;
          &:hover {
            background-position: -72px 0px;
          }
        }
        
        .fl-03 {
          background-position: 56px 56px;
          &:hover {
            background-position: 57px 0px;
            .qr-code {
              display: block;
            }
          }
          .qr-code {
            display: none;
            width: 150px;
            height: 150px;
            position: relative;
            left: 56px;
            bottom:94px;
          }
        }
      }
      .ew-code {
        width: 84px;
        img {
          display: block;
          width: 68px;
          height: 68px;
          border-radius: 4px;
          margin: 0 auto 8px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    
  }
}
</style>