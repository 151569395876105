<template>
  <div class="w100">
    <div class="card_box">
      <div>{{ cardData.title }}</div>
      <div>
        <a :href="moreLink">
          <div>更多</div>
          <img src="../../assets/arrow-dj-more-red.svg" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      default: ()=> {}
    },
    moreLink:{
      type: String,
      default:''
    }
  }
}
</script>

<style lang="less" scoped>
.card_box {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FDE39A;
  padding:0 20px 0 30px; 
  background:url('../../assets/dt2.png') 100% 100%;
  div:nth-child(2) {
    a {
      display: flex;
      align-items: center;
      color: #B20B0C;
      font-size: 14px !important;
      img {
        width: 9px;
        height: 15px;
        margin-left: 5px;
      }
      &:hover {
         color: #B20B0C !important;
      }
    }
  }
}
</style>