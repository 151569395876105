<template>
  <div class="w100" v-if="crumbsList && crumbsList[1]"> 
    <input type="hidden" name="pageRouter" value="en/detail/news"/>
    <top-title :title="crumbsList[1].title"></top-title>
    <ul class="news">
      <li class="news-item" v-for="(item,index) in articleVOList" :key="index">
        <a :href="item.articleLinkURL" class="image">
          <img :src="item.basicThumbnails" alt="">
        </a>
        <div class="news-item-content">
          <div class="time">{{time(item.contentShowTime?item.contentShowTime:item.basicDateTime)}}</div>
          <a href="" class="title">{{item.basicTitle}}</a>
          <div class="line"></div>
          <div class="content">{{item.basicDescription}}</div>  
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import TopTitle from "../../components/topTitle/index.vue"
import dayjs from "dayjs"
import { mapGetters } from 'vuex'
  export default {
    computed:{
      ...mapGetters("en_detail",["articleVOList","crumbsList"]),
    },
    methods:{
      time(val){
        return dayjs(val).format("YYYY/MM/DD")
      }
    },
    components:{
      TopTitle
    }
  }
</script>

<style lang="less" scoped>
.news-item {
  height: 154px;
  margin-bottom: 50px;
  display: flex;
  .image {
    img {
      width: 245px;
      height: 154px;
    }
  }
  .news-item-content{
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 154px;
    width: 940px;
    margin-left: 10px;
    .time {
      color: #a19d9d;
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .title {
      color: #3838f8;
      font-size: 22px;
      margin-bottom: 15px;
      &:hover {
        color: #3838f8 !important;
      }
    }
    .line {
      width: 100%;
      height: 3px;
      margin-bottom: 15px;
      background: url("../../../../assets/line1.png");
    }
    .content {
      font-size: 16px;
      color: #5a5858;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}
</style>