<template>
  <div>
    <ul class="list1">
      <li v-for="(item, index) in lists" :key="index">
        <a class="title" :href="item.link"> {{item.name}} </a>
        <span v-if="value.name === '科技热点'" class="time">{{item.kjrd_time}}</span>
        <span v-else class="time">{{item.time}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      lists:{
        type:Array,
        default:() => []
      },
      value:{
        type: Object,
        default:()=>{}
      }
    },

  }
</script>

<style lang="less" scoped>
  .list1 {
    li {
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      margin-bottom: 20px;
      a {
        display: inline-block; 
        // width: 242px;
        width: calc(100% - 50px);
        color: #333;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::before {
          content: " ";
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #CB5D62;
          // border-radius: 50%;
          margin-right: 10px;
          margin-bottom: 2px;
        }
      }
      .time {
        color: #999999;
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
      }
    }
  }
</style>