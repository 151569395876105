import req from '../../req/index';

export default {
  namespaced: true,
  state: {
    list: {},
  },
  getters:{
    oa(state){
      let {columnVOS=[]} = state.list;
      let oa = columnVOS[0] || {};

      return oa;
    },
    email(state){
      let {columnVOS=[]} = state.list;
      let email = columnVOS[1] || {};
      return email;
    }
  },
  actions: {
    // 在服务端渲染期间务必让 action 返回一个 Promise
    async initList({ commit }) {
      let query = ['311','312','313'];
      let res = await req.ajax({
        url: "/static/generate/getAppInfo",
        method: "post",
        data: query
      })
      
      commit('setList', {
        ...res,
        enHomeUrl: res.enHomeUrl + "529/558/index.html",
        footTxt: res.appCopyright,
        staticUrl: res.appStaticUrl,
        imgList: res.columnVOS,
      })

      return res;
    },
  },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
  },
}