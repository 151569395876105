<template>
  <div>
    <input type="hidden" name="pageRouter" value="detail/openuponapplication" />
    <openUponApply :articleVOList="articleVOList" :papersection="papersection" :pageInfo="pageInfo"></openUponApply>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import openUponApply from '../../components/scientificresearch/index.vue'
export default {
    components: {
        openUponApply
    },
    data() {
        return {
            papersection: {
                enrollment: true,
                img: true,
            }
        }
    },
    computed: {
        ...mapGetters('detail',['articleVOList','pageInfo']),
    },
}
</script>

<style>

</style>